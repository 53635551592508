import { useCallback, useEffect, useRef } from 'react';
import { Layer, LayerProps, MapboxGeoJSONFeature, MapLayerMouseEvent, Source, useMap } from 'react-map-gl';

import { useProjectCells } from '../../../hooks/useProjectPolygon';
import { cellLayers, CELLS_FILL_LAYER_ID, CELLS_OUTLINE_LAYER_ID, PROJECT_CELLS_SOURCE_ID } from '../constants';
import { useControlsContext } from '../hooks/useControlsForm';
import { useInteractiveLayers } from '../hooks/useInteractiveLayers';
import { CellFeature, ControlsFormFieldValues } from '../types';

export const Cells = () => {
  const mapRef = useMap();

  const { data: projectCells } = useProjectCells();

  const controlsForm = useControlsContext();

  const { watch } = controlsForm;

  const previousCellRef = useRef<ControlsFormFieldValues['cell']>(null);

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function handleCellChange() {
      const map = mapRef.current;

      if (!map) {
        return undefined;
      }

      const { unsubscribe } = watch(({ cell }, { name }) => {
        if (name !== 'cell') {
          return null;
        }

        map.setFeatureState(
          { source: PROJECT_CELLS_SOURCE_ID, id: previousCellRef.current?.id ?? '' },
          { active: false },
        );

        if (cell) {
          map.setFeatureState({ source: PROJECT_CELLS_SOURCE_ID, id: cell.id }, { active: true });
          previousCellRef.current = cell as CellFeature;
        }

        return null;
      });

      return unsubscribe;
    },
    [watch, mapRef],
  );

  const interactiveLayers = useInteractiveLayers(controlsForm);

  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const map = event.target;

      const featureUnderMousePointer = map.queryRenderedFeatures(event.point, {
        layers: interactiveLayers,
      })?.[0] as (CellFeature & Omit<MapboxGeoJSONFeature, keyof CellFeature>) | undefined;

      if (!featureUnderMousePointer) {
        return null;
      }

      if (!cellLayers.includes(featureUnderMousePointer.layer.id)) {
        return null;
      }

      const featureId = featureUnderMousePointer.id;

      if (!featureId) {
        return null;
      }

      controlsForm.setValue('cell', featureUnderMousePointer);

      return null;
    },
    [controlsForm, interactiveLayers],
  );

  const hoveredPolygonIdRef = useRef<number | null>(null);

  const handleMapMouseMove = useCallback(
    (event: MapLayerMouseEvent) => {
      const map = event.target;

      const featureUnderMousePointer = map.queryRenderedFeatures(event.point, {
        layers: interactiveLayers,
      })?.[0];
      const featureId = featureUnderMousePointer?.id as number | undefined;

      if (!featureId) {
        map.getCanvas().style.cursor = 'initial';

        if (hoveredPolygonIdRef.current !== null) {
          map.setFeatureState(
            { source: PROJECT_CELLS_SOURCE_ID, id: hoveredPolygonIdRef.current as number },
            { hover: false },
          );
          hoveredPolygonIdRef.current = null;
        }
      } else {
        map.getCanvas().style.cursor = 'pointer';

        if (hoveredPolygonIdRef.current !== null) {
          map.setFeatureState(
            { source: PROJECT_CELLS_SOURCE_ID, id: hoveredPolygonIdRef.current as number },
            { hover: false },
          );
        }

        hoveredPolygonIdRef.current = featureId;
        map.setFeatureState(
          { source: PROJECT_CELLS_SOURCE_ID, id: hoveredPolygonIdRef.current as number },
          { hover: true },
        );
      }
    },
    [interactiveLayers],
  );

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function addMouseHandlers() {
      const map = mapRef.current;

      if (!map) {
        return undefined;
      }

      const layers = [CELLS_OUTLINE_LAYER_ID, CELLS_FILL_LAYER_ID];

      map.on('click', layers, handleMapClick);
      map.on('mousemove', handleMapMouseMove);

      return () => {
        map.off('click', layers, handleMapClick);
        map.off('mousemove', handleMapMouseMove);
      };
    },
    [mapRef, handleMapMouseMove, handleMapClick],
  );

  return (
    <Source id={PROJECT_CELLS_SOURCE_ID} type='geojson' data={projectCells}>
      <Layer {...cellsOutlineLayer} />
      <Layer {...cellsFillLayer} />
    </Source>
  );
};

const cellsOutlineLayer: LayerProps = {
  id: CELLS_OUTLINE_LAYER_ID,
  type: 'line',
  source: PROJECT_CELLS_SOURCE_ID,
  paint: {
    'line-width': 1,
    'line-opacity': 0.6,
    'line-color': 'rgb(255, 255, 255)',
  },
};

const cellsFillLayer: LayerProps = {
  id: CELLS_FILL_LAYER_ID,
  type: 'fill',
  interactive: true,
  source: PROJECT_CELLS_SOURCE_ID,
  paint: {
    'fill-color': '#F3EDD3',
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'active'], false],
      0.8,
      ['boolean', ['feature-state', 'hover'], false],
      0.6,
      0.0,
    ],
  },
};
