import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { BenchmarkType, PlotReportFactLabelNameEnum, R1FactType } from '@/api/rest/resources/types/fact';
import { CarbonStorageBGHistoricalChart } from '@/components/Charts';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { GraphFact } from '@/components/Charts/types';
import { kgToTonneFormatter } from '@/components/Charts/utils';
import { useNCCardContext } from '@/components/NCCard/NCCard';
import { useBenchmarksForProject } from '@/pages/shared/hooks/useBenchmarkForFact';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';

import { NoProjectData } from '../../../../components/NoProjectData';

export const CarbonCardGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const carbonBgHistoric = useProjectFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_soil_carbon_historic_total_graph
      : R1FactType.r1_soil_carbon_historic_per_ha_graph,
  );
  const carbonBgHistoricData = carbonBgHistoric?.value;

  const carbonBgLowBenchmark = useProjectFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    { [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.low },
  );
  const cbgLowBenchmarkData = (carbonBgLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const carbonBgModerateBenchmark = useProjectFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.moderate,
    },
  );
  const cbgModerateBenchmarkData = (carbonBgModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const carbonHighBenchmark = useProjectFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.high,
    },
  );
  const cbgHighBenchmarkData = (carbonHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const cbgBenchmarks = useBenchmarksForProject(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    kgToTonneFormatter,
  );

  /**
   * We only null check for cbgHistoricData since cbgHistoricData is the primary source data of the graph
   * reference lines are displayed only when cbgHistoricData is rendered
   */
  const noChartData = !carbonBgHistoricData?.length;
  if (noChartData) {
    return <NoProjectData />;
  }

  const chartData = [
    ...carbonBgHistoricData,
    ...cbgLowBenchmarkData,
    ...cbgModerateBenchmarkData,
    ...cbgHighBenchmarkData,
  ];

  return (
    <Stack center data-testid='carbon-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center px-1 py-6 lg:items-start lg:p-8 lg:pl-1'>
        <ChartHeading
          heading={t('shared.ncaDetail.details.carbon.labels.historicalGraphHeadline')}
          popover={{
            // TODO: MVP-3284 Confirm if the popover copy is correct
            title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
            body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
          }}
        />
        <CarbonStorageBGHistoricalChart
          analysisType={analysisType}
          data={chartData}
          benchmarks={cbgBenchmarks}
          showTooltip={true}
          className='relative'
          data-testid='carbon-storage-bg-historic-chart'
        />
      </Stack>
    </Stack>
  );
};
