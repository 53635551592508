import { RiLayout2Line, RiLineChartLine, RiMoneyEuroCircleLine, RiRoadMapLine } from 'component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { MenuItemProps } from '@/components/SignedInNavbar/Menu';
import { useMembershipType } from '@/hooks/useMembershipType';
import { paths } from '@/routing';

export const useMenuItems = () => {
  const { t } = useTranslation();
  const membershipType = useMembershipType();

  return useMemo(() => {
    if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
      return [
        {
          active: false,
          icon: <RiLayout2Line className='h-6 w-6' />,
          name: 'projects',
          'data-cy': 'projects-link',
          text: t('navbar.projects'),
          to: paths.landSteward.projects,
        },
        {
          active: false,
          icon: <RiMoneyEuroCircleLine className='h-6 w-6' />,
          name: 'investments',
          'data-cy': 'investments-link',
          text: t('navbar.investments'),
          to: paths.landSteward.investments,
        },
        {
          active: false,
          icon: <RiLineChartLine className='h-6 w-6' />,
          name: 'portfolio',
          'data-cy': 'portfolio-link',
          text: t('navbar.portfolio'),
          to: paths.landSteward.portfolio,
        },
      ] as MenuItemProps[];
    }

    return [
      {
        active: false,
        icon: <RiRoadMapLine className='h-6 w-6' />,
        name: 'projects',
        'data-cy': 'projects-link',
        text: t('navbar.projects'),
        to: paths.buyer.projects,
      },
      {
        active: false,
        icon: <RiMoneyEuroCircleLine className='h-6 w-6' />,
        name: 'investments',
        'data-cy': 'investments-link',
        text: t('navbar.investments'),
        to: paths.buyer.investments,
      },
      {
        active: false,
        icon: <RiLineChartLine className='h-6 w-6' />,
        name: 'portfolio',
        'data-cy': 'portfolio-link',
        text: t('navbar.portfolio'),
        to: paths.buyer.portfolio,
      },
    ] as MenuItemProps[];
  }, [membershipType, t]);
};
