/* eslint-disable import/no-extraneous-dependencies */
import type { Config } from 'tailwindcss';
import plugin from 'tailwindcss/plugin';
// @ts-ignore
import tailwindcssAnimate from 'tailwindcss-animate';

import { breakpoints } from './src/theme/breakpoints';
import { colorPalette } from './src/theme/colorPalette';
import { fontFamily } from './src/theme/fontFamily';
import { typographyPlugin } from './src/theme/typographyPlugin';

export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}', './component-library/**/*.{js,ts,jsx,tsx}'],
  theme: {
    colors: colorPalette,

    screens: {
      xs: `${breakpoints.xs}px`,
      sm: `${breakpoints.sm}px`,
      md: `${breakpoints.md}px`,
      lg: `${breakpoints.lg}px`,
      xl: `${breakpoints.xl}px`,
    },
    fontFamily,

    extend: {
      zIndex: {
        /**
         * Before adding items here, consider creating a stacking
         * context by doing one of the following to your wrapper element:
         * 1. Setting its position to `relative`
         * 2. Using display `flex` or `grid`
         * 3. Using the `isolate` tailwind utility
         *
         * This will allow you to use z-index in a local context without
         * interfering with top-level elements like the navbars and modals.
         *
         * Stacking context primer: https://web.dev/learn/css/z-index/#stacking-context
         */
        appbar: '2',
        dialog: '3',
        'inert-overlay': '4',
      },
      keyframes: {
        accordionOpen: {
          from: { height: '0px' },
          to: { height: 'var(--radix-accordion-content-height)' },
        },
        accordionClose: {
          from: { height: 'var(--radix-accordion-content-height)' },
          to: { height: '0px' },
        },
      },
      animation: {
        accordionOpen: 'accordionOpen 150ms cubic-bezier(0.87, 0, 0.13, 1)',
        accordionClose: 'accordionClose 150ms cubic-bezier(0.87, 0, 0.13, 1)',
      },
    },
  },
  plugins: [
    typographyPlugin,
    tailwindcssAnimate,
    plugin(({ addUtilities }) => {
      addUtilities({
        '.elevation-0': {
          boxShadow: 'none',
        },
        '.elevation-1': {
          boxShadow: '0px 1px 10px 0px #00000024, 0px 1px 2px -1px #00000033',
        },
        '.full-bleed-x': {
          width: '100%',
          gridColumn: '1 / -1 !important',
        },
        '.full-bleed-y': {
          height: '100%',
          gridRow: '1 / -1 !important',
        },
        '.center': {
          justifyContent: 'center',
          alignItems: 'center',
        },
        '.center-main': {
          justifyContent: 'center',
        },
        '.center-cross': {
          alignItems: 'center',
        },
      });
    }),
  ],
} as Config;
