import { CircularProgress } from '@mui/material';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import React from 'react';

export interface ButtonProps extends MuiButtonProps {
  /**
   * When true, shows a loading state
   * @default false
   */
  isLoading?: boolean;
}
const RawButton: React.ForwardRefRenderFunction<HTMLButtonElement | null, ButtonProps> = (
  { children, 'data-testid': dataTestId, disabled, startIcon, isLoading = false, ...props },
  ref,
) => (
  <MuiButton
    ref={ref}
    startIcon={isLoading ? <CircularProgress size={16} color='inherit' data-testid='loading-indicator' /> : startIcon}
    disabled={disabled || isLoading}
    data-testid={isLoading ? 'button-loading' : dataTestId}
    disableElevation
    {...props}
  >
    {children}
  </MuiButton>
);

export const Button = React.forwardRef(RawButton);
