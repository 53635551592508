import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Text } from 'deprecated-component-library';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { User } from '@/api/rest/resources/types/user';
import { printFullName } from '@/utils/formatting/user';

import { UserAvatar } from '../../UserAvatar';

type SignedInNavbarMenuAccountItemProps = {
  user: User;
  accountPagePath?: string;
  closeDrawer?: () => void;
};

const ListItemContent: React.FC<{ user: User }> = ({ user }) => {
  const fullName = printFullName(user);

  return (
    <>
      <ListItemIcon>
        <UserAvatar name={fullName} showWarningBadge={user.email_activated} />
      </ListItemIcon>
      <ListItemText>
        <Text variant='body1'>{fullName}</Text>
        <Text variant='body2' color='text.secondary'>
          {user.email}
        </Text>
      </ListItemText>
    </>
  );
};

export const SignedInNavbarMenuAccountItem: React.FC<SignedInNavbarMenuAccountItemProps> = ({
  accountPagePath,
  closeDrawer,
  user,
}) => {
  const { pathname } = useLocation();
  const isItemSelected = (to?: string) => {
    if (!to) return false;

    return pathname === to || pathname.startsWith(`${to}/`);
  };

  return (
    <>
      {accountPagePath ? (
        <ListItemButton
          component={Link}
          to={accountPagePath}
          onClick={closeDrawer}
          sx={{ height: 64 }}
          selected={isItemSelected(accountPagePath)}
        >
          <ListItemContent user={user} />
        </ListItemButton>
      ) : (
        <ListItem sx={{ height: 64 }}>
          <ListItemContent user={user} />
        </ListItem>
      )}
    </>
  );
};
