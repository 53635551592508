/* eslint-disable @typescript-eslint/no-explicit-any */
import mapboxGeocoding, { GeocodeFeature } from '@mapbox/mapbox-sdk/services/geocoding';
import { Autocomplete, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import {
  Button,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  RiMapPinLine,
  Stack,
} from 'component-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MAPBOX_TOKEN } from '@/config/constants';
import { useDebounce } from '@/hooks/useDebounce';

interface CurrentLocationFeature extends GeocodeFeature {
  isCurrentLocation: boolean;
  isUpdated: boolean;
}

export const createCurrentLocationFeature = (lng: number, lat: number, label: string): CurrentLocationFeature =>
  ({
    isCurrentLocation: true,
    isUpdated: label === `${lng}, ${lat}`,
    center: [lng, lat],
    place_name: label,
  } as CurrentLocationFeature);

type LocationSearchDialogProps = {
  open: boolean;
  onOpenChange?: (open: boolean) => void;
  onLocationSelect?: (feature: GeocodeFeature) => void;
};

export const LocationSearchDialog = ({ open, onOpenChange, onLocationSelect }: LocationSearchDialogProps) => {
  const { t } = useTranslation();

  const [inputText, setInputText] = useState('');
  const [inputValue, setInputValue] = useState<GeocodeFeature | null>(null);
  const [currentLocationFeature, setCurrentLocationFeature] = useState(
    createCurrentLocationFeature(0, 0, t('landSteward.plot.geocode.input.currentLocation')),
  );

  /**
   * Debouncing the input so that we do not bombard
   * the location search API on every keystroke.
   * Skip calling the API when we fetch current location
   */
  const debouncedInputText = useDebounce(
    inputValue && (inputValue as CurrentLocationFeature).isCurrentLocation ? '' : inputText,
    500,
  );

  const searchLocationQuery = useQuery({
    queryKey: ['location-search', debouncedInputText],
    queryFn: () => searchLocation(debouncedInputText),
  });
  const options = searchLocationQuery.data?.body.features ?? [];

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentLocationFeature(
          createCurrentLocationFeature(
            position.coords.longitude,
            position.coords.latitude,
            `${position.coords.longitude}, ${position.coords.latitude}`,
          ),
        );
      },
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      (error) => {
        // we are not going to log this, because there is nothing we can do about it
        // TODO: however, we want to give some feedback to the user (see https://landler.atlassian.net/browse/MVP-581)
        // TODO: when working on MVP-581 MVP-2523, please keep in mind that the GeolocationPositionError has 3 different error codes
      },
      { enableHighAccuracy: true },
    );
  };

  useEffect(() => {
    if (currentLocationFeature.isUpdated) {
      setInputValue(currentLocationFeature);
      setInputText(currentLocationFeature.place_name || '');
    }
  }, [currentLocationFeature]);

  const handleOnInputChange = (_: any, newInputValue: any) => {
    /** do not do anything/call location search api if user selects current location */
    if (newInputValue === currentLocationFeature.place_name) {
      return;
    }
    setInputValue(null);
    setInputText(newInputValue);
  };

  const handleOnChange = (_: any, selection: string | GeocodeFeature | null) => {
    if (typeof selection === 'string') {
      /** custom input string from user that is not a geocode, does not enable the submit button */
      setInputValue(null);
      setInputText(selection);
    } else if (selection && (selection as CurrentLocationFeature).isCurrentLocation) {
      /** get current location if user selects "Your current location" */
      getCurrentPosition();
    } else {
      setInputValue(selection as GeocodeFeature);
      setInputText(selection?.place_name || '');
    }
  };

  const renderOption = (optionProps: React.HTMLAttributes<HTMLLIElement>, option: GeocodeFeature) => {
    if ((option as CurrentLocationFeature).isCurrentLocation) {
      return (
        <li {...optionProps}>
          <Stack direction='row' className='items-center gap-2'>
            <RiMapPinLine />
            {t('landSteward.plot.geocode.input.currentLocation')}
          </Stack>
        </li>
      );
    }
    return <li {...optionProps}>{option?.place_name}</li>;
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(isOpen) => {
        onOpenChange?.(isOpen);
        setInputValue(null);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('landSteward.plot.geocode.dialog.title')}</DialogTitle>
          <DialogDescription>{t('landSteward.plot.geocode.dialog.subtitle')}</DialogDescription>
        </DialogHeader>
        <DialogBody>
          {/* TODO: MVP-2523 switch out with combobox or another autocomplete */}
          <Autocomplete
            autoComplete
            // NOTE: The padding prevents that the MUI label is cut off
            className='pt-1.5'
            disableClearable
            disablePortal={true}
            options={options}
            filterOptions={(x) => [...x, currentLocationFeature]}
            selectOnFocus
            freeSolo
            value={inputText}
            onInputChange={handleOnInputChange}
            onChange={handleOnChange}
            getOptionLabel={(option) => (option as GeocodeFeature).place_name ?? option}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('landSteward.plot.geocode.input.label')}
                placeholder={t('landSteward.plot.geocode.input.placeholder')}
              />
            )}
            renderOption={renderOption}
          />
        </DialogBody>
        <DialogFooter className='mt-12'>
          <DialogClose asChild>
            <Button variant='text'>{t('global.ui.buttons.cancel')}</Button>
          </DialogClose>
          <DialogClose asChild>
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            <Button disabled={!inputValue} onClick={() => onLocationSelect?.(inputValue!)}>
              {t('landSteward.plot.geocode.dialog.buttons.continue')}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const geocodingClient = mapboxGeocoding({ accessToken: MAPBOX_TOKEN });

const searchLocation = (query: string) =>
  query.length > 0 ? geocodingClient.forwardGeocode({ query }).send() : Promise.resolve(null);
