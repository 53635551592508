// TODO: (MVP-1132): these types should be generated automatically based on the API's Swagger documentation

import { PlotReportFactElement, PlotReportFactLabelFilter } from './fact';
import { NCAReportBalance } from './ncaBalance';
import { Organization, OrganizationTypeEnum } from './organization';
import { CropsEnum, PlotType } from './plot';
import { KycEnum } from './user';

export enum AssessmentStatus {
  pending = 'pending',
  running = 'running',
  success = 'success',
  failed = 'failed',
}

export type Landtype = {
  type: PlotType;
  area: number;
};

export enum ProjectStatus {
  initial = 'initial',
  in_preparation = 'in_preparation',
  ready_to_analyse = 'ready_to_analyse',
  scheduled_for_analysis = 'scheduled_for_analysis',
  aggregating = 'aggregating',
  analysed = 'analysed',
  /**
   * @deprecated
   */
  locked = 'locked',
  /**
   * @deprecated
   */
  activated = 'activated',
  /**
   * @deprecated
   */
  invested = 'invested',
}

export enum ProjectType {
  agriculture = 'agriculture',
  conservation = 'conservation',
}

export type ContractMini = {
  start_date: string;
  end_date: string;
};

export type ProjectCreate = {
  id: string;
  name: string;
  legal_number?: string;
  location_description?: string;
  landtypes_allowed?: PlotType[];
  locked: boolean;
  organization_id: string;
};

export type Point = {
  lat: number;
  lon: number;
};

export type ProjectDetail = {
  id: string;
  type: ProjectType;
  name: string;
  legal_number?: string; // TODO: MVP-1731: is this supposed to be optional (in ProjectBuyerDetail its not optional)
  location_description?: string; // TODO: MVP-1731: is this supposed to be optional (in ProjectBuyerDetail its not optional)
  is_locked: boolean;
  status: ProjectStatus;
  organization: Organization;
  organization_id: string;
  buyer?: {
    id: string;
    name: string;
    type: OrganizationTypeEnum;
  };
  area: number;
  /**
   * @deprecated Use landtypes_allowed instead.
   */
  landtypes: Landtype[];
  landtypes_allowed: PlotType[];
  crops: CropsEnum[];
  centroid?: Point;
  last_analyzed_at: string | null;
  locked_since: string | null;
  /**
   * @deprecated Use status instead.
   */
  calculation_status: AssessmentStatus;
  contracts?: ContractMini[];
  kyc: KycEnum;
};

// TODO: MVP-1731: shouldnt this also include the locked prop?
export type ProjectBuyerDetail = {
  id: string;
  type: ProjectType;
  name: string;
  legal_number: string;
  location_description: string;
  is_locked: boolean;
  status: ProjectStatus;
  organization: Organization;
  organization_id: string;
  buyer_id: string;
  area: number;
  /**
   * @deprecated Use landtypes_allowed instead.
   */
  landtypes: Landtype[];
  landtypes_allowed: PlotType[];
  centroid: Point;
  /**
   * @deprecated Use status instead.
   */
  calculation_status: AssessmentStatus;
  crops: CropsEnum[];
  organization_email: string;
  last_analyzed_at: string | null;
  locked_since: string | null;
  invited_at: string | null;
  contracts?: ContractMini[];
};

export type Project = ProjectDetail | ProjectBuyerDetail;

export type ProjectReport = {
  project_last_fact_measured_at: string | null;
  facts: PlotReportFactElement[];
};

/**
 * GET /api/v1/project
 */
export const GET_PROJECT_PATH = 'project';
export type GetProjectRequestPathVariables = undefined;
export type GetProjectRequestQueryParams =
  | {
      organization?: string;
      demo?: boolean;
      demo_if_none_analysed_or_later?: boolean;
    }
  | undefined;
export type GetProjectSuccessDto = {
  count: number;
  next: string | null;
  previous: string | null;
  results: ProjectDetail[];
};
export type GetProjectErrorDto = Error;

/**
 * GET /api/v1/project/{id}
 */
export const GET_PROJECT_BY_ID_PATH = 'project/{id}';
export type GetProjectByIdRequestPathVariables = {
  id: string;
};
export type GetProjectByIdRequestQueryParams = undefined;
export type GetProjectByIdSuccessDto = ProjectDetail;
export type GetProjectByIdErrorDto = Error;

/**
 * POST /api/v1/project
 */
export const POST_PROJECT_PATH = 'project';
export type PostProjectRequestPathVariables = undefined;
export type PostProjectRequestQueryParams = undefined;
export type PostProjectRequestBodyData = {
  name: string;
  legal_number?: string;
  location_description?: string;
  organization_id: string;
  landtypes_allowed?: PlotType[];
};
export type PostProjectSuccessDto = ProjectCreate;
export type PostProjectErrorDto = Error;

/**
 * PATCH /api/v1/project/{id}
 */
export const PATCH_PROJECT_BY_ID_PATH = 'project/{id}';
export type PatchProjectByIdRequestPathVariables = {
  id: string;
};
export type PatchProjectByIdRequestQueryParams = undefined;
export type PatchProjectByIdRequestBody = {
  name?: string;
  location_description?: string;
  landtypes_allowed: PlotType[];
};
export type PatchProjectByIdSuccessDto = ProjectDetail;
export type PatchProjectByIdErrorDto = Error;

/**
 * POST /api/v1/project/{id}/analyse/
 */
export const POST_ANALYSE_PROJECT_PATH = 'project/{id}/analyse';
export type PostAnalyseProjectRequestPathVariables = { id: string };
export type PostAnalyseProjectRequestQueryParams = undefined;
export type PostAnalyseProjectRequestBodyData = undefined;
export type PostAnalyseProjectSuccessDto = undefined;
export type PostAnalyseProjectErrorDto = Error;

/**
 * GET /api/v1/projects-reports/projects_summary_report/
 */
export const GET_PROJECT_AGGREGATE_PATH = 'projects-reports/projects_summary_report';
export type GetProjectAggregateRequestPathVariables = undefined;
export type GetProjectAggregateRequestQueryParams =
  | {
      demo?: boolean;
      demo_if_none_analysed_or_later?: boolean;
    }
  | undefined;
export type GetProjectAggregateSuccessDto = {
  project_last_fact_measured_at: string;
  facts: PlotReportFactElement[];
};
export type GetProjectAggregateErrorDto = Error;

/**
 * GET /api/v1/project/{id}/dashboard
 */
export const GET_PROJECT_BY_ID_DASHBOARD_PATH = 'project/{id}/dashboard';
export type GetProjectByIdDashboardRequestPathVariables = { id: string };
export type GetProjectByIdDashboardRequestQueryParams = PlotReportFactLabelFilter | undefined;
export type GetProjectByIdDashboardSuccessDto = ProjectReport;
export type GetProjectByIdDashboardErrorDto = Error;

/**
 * GET /api/v1/dashboard/nca/reports/{project_id}/
 */
export const GET_PROJECT_NCA_REPORT_BY_ID_PATH = 'dashboard/nca/reports/{id}/';
export type GetProjectNcaReportByIdRequestPathVariables = { id: string };
export type GetProjectNcaReportByIdRequestQueryParams = undefined;
export type GetProjectNcaReportByIdSuccessDto = {
  balances: NCAReportBalance[];
};
export type GetProjectNcaReportByIdErrorDto = Error;

/**
 * GET /api/v1/buyer/project/
 */
export const GET_BUYER_PROJECTS_PATH = 'buyer/project';
export type GetBuyerProjectsRequestPathVariables = undefined;
export type GetBuyerProjectsRequestQueryParams = {
  buyer?: string;
  demo_if_none_analysed_or_later?: boolean;
};
export type GetBuyerProjectsSuccessDto = {
  count: number;
  next: null;
  previous: null;
  results: ProjectBuyerDetail[];
};
export type GetBuyerProjectsErrorDto = Error;

/**
 * GET /api/v1/buyer/project/{id}
 */
export const GET_BUYER_PROJECT_BY_ID_PATH = 'buyer/project/{id}';
export type GetBuyerProjectByIdRequestPathVariables = { id: string };
export type GetBuyerProjectByIdRequestQueryParams = undefined;
export type GetBuyerProjectByIdSuccessDto = ProjectBuyerDetail;
export type GetBuyerProjectByIdErrorDto = Error;

/**
 * GET /api/v1/buyer/project/aggregate
 * @deprecated same as GET_PROJECT_AGGREGATE_PATH
 */
export const GET_BUYER_PROJECT_AGGREGATE_PATH = 'projects-reports/projects_summary_report';
export type GetBuyerProjectAggregateRequestPathVariables = undefined;
export type GetBuyerProjectAggregateRequestQueryParams = { id?: string; demo?: boolean };
export type GetBuyerProjectAggregateSuccessDto = {
  facts: PlotReportFactElement[];
};
export type GetBuyerProjectAggregateErrorDto = Error;

/**
 * GET /api/v1/buyer/project/{id}/dashboard/
 */
export const GET_BUYER_PROJECT_BY_ID_DASHBOARD_PATH = 'buyer/project/{id}/dashboard/';
export type GetBuyerProjectByIdDashboardRequestPathVariables = { id: string };
export type GetBuyerProjectByIdDashboardRequestQueryParams = PlotReportFactLabelFilter | undefined;
export type GetBuyerProjectByIdDashboardSuccessDto = ProjectReport;
export type GetBuyerProjectByIdDashboardErrorDto = Error;
