import { cn, Pill, PillProps } from 'component-library';
import { useTranslation } from 'react-i18next';

import { BenchmarkType } from '@/api/rest/resources/types/fact';
import { getStyleForBenchmark } from '@/utils/getStyleForBenchmark';

export type BenchmarkPillProps = PillProps & {
  benchmarkType: BenchmarkType;
};

export const BenchmarkPill = ({ benchmarkType, className, ...delegated }: BenchmarkPillProps) => {
  const { t } = useTranslation();
  const style = getStyleForBenchmark(benchmarkType);

  return (
    <Pill
      className={cn('typography-overline w-fit rounded px-[9px] py-2', className)}
      style={{
        color: style?.text,
        backgroundColor: style?.primary,
      }}
      {...delegated}
    >
      {t(`global.benchmark.${benchmarkType}`)}
    </Pill>
  );
};
