import { ReactComponent as Logo } from '@assets/images/logo.svg';
import { cn } from 'component-library/utils';
import React, { SVGProps } from 'react';

type LandlerLogoProps = SVGProps<SVGSVGElement> & {
  color?: 'primary' | 'white';
};

export const LandlerLogo: React.FC<LandlerLogoProps> = ({ color = 'primary', className, ...delegated }) => {
  const styles = cn(
    {
      'text-primary-100': color === 'primary',
      'text-white-100': color === 'white',
    },
    className,
  );
  return <Logo className={styles} data-testid='landler-logo' {...delegated} />;
};
