import { AxiosRequestConfig } from 'axios';

import { tokenRefresher } from '@/lib/auth/TokenRefresher';

import { restApi } from '../../client';
import { replacePathVariables } from './utils/replacePathVariables';

export const getRequest = async <
  TResponse,
  PathVariables = unknown,
  QueryParams = unknown,
  Options = AxiosRequestConfig,
>(
  path: string,
  pathVariables?: PathVariables,
  queryParams?: QueryParams,
  signal?: AbortSignal,
  options?: Options,
): Promise<TResponse> => {
  const pathWithVariables = replacePathVariables(path, pathVariables);

  await tokenRefresher.checkToken();

  return new Promise((resolve, reject) => {
    restApi
      .get(pathWithVariables, {
        params: queryParams,
        signal,
        ...options,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
