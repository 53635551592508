import jamieImgSrc from '@assets/images/team-jamie.jpg';
import mariaImgSrc from '@assets/images/team-maria.jpg';
import sophieImgSrc from '@assets/images/team-sophie.jpg';
import { AvatarProps, ButtonLink, cn, RiCalendarEventLine } from 'component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarGroup } from '@/components';
import { LANDSTEWARD_CALENDLY_LINK } from '@/config/constants';
import { useScreenSize } from '@/hooks/useScreenSize';

import { Card } from '../card/Card';

export const ScheduleMeeting: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...delegated }) => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';

  const avatars: AvatarProps[] = [
    {
      image: sophieImgSrc,
      name: 'Sophie van Berchem',
    },
    {
      image: mariaImgSrc,
      name: 'Maria Koppatz',
    },
    {
      image: jamieImgSrc,
      name: 'Jamie Batho',
    },
  ];

  const styles = cn('items-start', className);

  return (
    <Card
      label={t('landSteward.portfolio.scheduleMeeting.title')}
      data-testid='portfolio-schedule-meeting'
      data-cy='portfolio-meeting'
      className={styles}
      {...delegated}
    >
      <div className='flex max-w-xl flex-col gap-4 lg:flex-row'>
        <AvatarGroup items={avatars} data-cy='portfolio-meeting-avatars' />
        <span className='typography-body1 text-text-secondary' data-cy='portfolio-meeting-subtitle'>
          {t('landSteward.portfolio.scheduleMeeting.subtitle')}
        </span>
      </div>
      <ButtonLink
        to={LANDSTEWARD_CALENDLY_LINK}
        target='_blank'
        rel='noreferrer'
        data-cy='portfolio-meeting-link'
        leftAdornment={<RiCalendarEventLine className='h-5 w-5' />}
      >
        {isLargeScreen
          ? t('landSteward.portfolio.scheduleMeeting.buttonLabel')
          : t('landSteward.portfolio.scheduleMeeting.buttonLabelShort')}
      </ButtonLink>
    </Card>
  );
};
