import { ButtonLink, RiErrorWarningLine, Stack } from 'component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '@/lib/auth';
import { paths } from '@/routing';

import { ErrorActivateAccountPageState } from '../../hooks/useActivateAccount';
import { ActivateAccountPageContent } from '../page-content/PageContent';

interface ActivateAccountFailedProps {
  pageState: ErrorActivateAccountPageState;
}

export const ActivateAccountFailed: React.FC<ActivateAccountFailedProps> = ({ pageState }) => {
  const { t } = useTranslation();

  const { isSignedIn } = useAuth();

  const getLocalizedErrorMessage = () => {
    switch (pageState) {
      case ErrorActivateAccountPageState.INVALID:
      case ErrorActivateAccountPageState.NO_TOKEN_SUPPLIED:
        return t('activateAccount.errors.invalid');
      case ErrorActivateAccountPageState.EXPIRED:
        return t('activateAccount.errors.expired.info');
      case ErrorActivateAccountPageState.ALREADY_ACTIVATED:
        return t('activateAccount.errors.alreadyActivated');
      case ErrorActivateAccountPageState.UNKNOWN_ERROR:
      default:
        return t('activateAccount.errors.unknown');
    }
  };

  const errorMessage = getLocalizedErrorMessage();

  if (pageState === ErrorActivateAccountPageState.EXPIRED) {
    return <LinkExpired />;
  }

  return (
    <ActivateAccountPageContent headlineText={t('activateAccount.title')}>
      <Stack direction='row' spacing={1.5} className='items-center'>
        <RiErrorWarningLine size={22} className='text-error' />
        <span className='typography-body2 text-text-primary'>{errorMessage}</span>
      </Stack>
      <ButtonLink size='medium' className='mt-12 self-end' to={isSignedIn ? paths.landSteward.projects : paths.login}>
        {isSignedIn ? t('activateAccount.backToProjects') : t('activateAccount.backToLogin')}
      </ButtonLink>
    </ActivateAccountPageContent>
  );
};

export const LinkExpired = () => {
  const { t } = useTranslation();
  const { isSignedIn } = useAuth();

  return (
    <div className='w-full max-w-screen-sm'>
      <ActivateAccountPageContent headlineText=''>
        <Stack direction='col' spacing={8}>
          <h1 className='typography-display3 text-center text-info'>{t('activateAccount.errors.expired.title')}</h1>
          <span className='text-primary typography-body1 whitespace-pre-wrap'>
            {t('activateAccount.errors.expired.info')}
          </span>
        </Stack>
        <ButtonLink size='medium' className='mt-12 self-end' to={paths.landSteward.account}>
          {isSignedIn
            ? t('activateAccount.errors.expired.continueToSettings')
            : t('activateAccount.errors.expired.continueToLogin')}
        </ButtonLink>
      </ActivateAccountPageContent>
    </div>
  );
};
