import { Box, TextField, TextFieldProps } from '@mui/material';
import React, { ReactElement } from 'react';

export type TextInputProps = TextFieldProps & {
  StartAdornment?: ReactElement;
  EndAdornment?: ReactElement;
  InputProps?: TextFieldProps['InputProps'];
};

const RawTextInput: React.ForwardRefRenderFunction<HTMLDivElement | null, TextInputProps> = (
  { fullWidth = true, InputProps, StartAdornment, EndAdornment, style = {}, ...props },
  ref,
) => {
  return (
    <TextField
      ref={ref}
      fullWidth={fullWidth}
      {...props}
      InputProps={{
        ...InputProps,
        startAdornment: StartAdornment && <Box mr={2}>{StartAdornment}</Box>,
        endAdornment: EndAdornment && <Box pl={2}>{EndAdornment}</Box>,
      }}
      style={{
        ...style,
        opacity: props.disabled ? 0.5 : undefined,
      }}
    />
  );
};

export const TextInput = React.forwardRef(RawTextInput);
