import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  cn,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogProps,
  DialogTitle,
  Select,
  SelectTrigger,
  Spinner,
  Stack,
  TextInput,
  toast,
} from 'component-library';
import { FC, PropsWithChildren, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RestApiBadRequestServerError } from '@/api/rest/resources/errors/RestApiBadRequestError';
import { usePatchProjectById } from '@/api/rest/resources/project';
import { PlotType } from '@/api/rest/resources/types/plot';
import { PatchProjectByIdRequestBody } from '@/api/rest/resources/types/project';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PlotTypeIcon,
  SuspenseBoundary,
  useFormField,
} from '@/components';
import { useMembershipType } from '@/hooks/useMembershipType';
import { Logger } from '@/lib/logs/logger';

import { useProjectDetailById } from '../hooks/useProjectDetailById';

export type EditProjectProps = {
  projectId: string;
  open: boolean;
  onOpenChange: DialogProps['onOpenChange'];
};

export const EditProject = ({ projectId, open, onOpenChange }: EditProjectProps) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent onClick={(e) => e.stopPropagation()} data-cy='edit-project-dialog'>
        <DialogHeader>
          <DialogTitle data-cy='edit-project-title'>{t('shared.projects.editProject.title')}</DialogTitle>
          <DialogDescription data-cy='edit-project-description'>
            {t('shared.projects.editProject.subtitle')}
          </DialogDescription>
        </DialogHeader>
        <SuspenseBoundary
          fallback={
            <Stack centerMain>
              <Spinner size={10} />
            </Stack>
          }
        >
          <Form projectId={projectId} onSubmit={() => onOpenChange?.(false)} />
        </SuspenseBoundary>
      </DialogContent>
    </Dialog>
  );
};

type EditProjectFields = Pick<PatchProjectByIdRequestBody, 'name' | 'location_description'> & {
  landtypes_allowed: PlotType;
};

const Form = (props: { projectId: string; onSubmit: () => void }) => {
  const { t } = useTranslation();
  const membershipType = useMembershipType();

  const queryClient = useQueryClient();

  const projectDetail = useProjectDetailById(props.projectId).data;

  const landtype = projectDetail.landtypes_allowed[0] ?? PlotType.UNDEFINED;

  const form = useForm<EditProjectFields>({
    defaultValues: {
      name: projectDetail.name,
      location_description: projectDetail.location_description,
      landtypes_allowed: landtype,
    },
  });
  const isFormPristine = !form.formState.isDirty;
  const isRequiredLabel = t('global.ui.form.input.required');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const patchProjectMutation = usePatchProjectById();

  const onSubmit: SubmitHandler<EditProjectFields> = async (inputs) => {
    try {
      setIsSubmitting(true);
      form.clearErrors();

      await patchProjectMutation.mutateAsync({
        pathVariables: { id: props.projectId },
        bodyData: {
          name: inputs.name,
          location_description: inputs.location_description,
          /**
           * Landtypes cannot be edited. We forward the value from ProjectDetail as-is.
           */
          landtypes_allowed: projectDetail.landtypes_allowed,
        },
      });

      await queryClient.invalidateQueries({ queryKey: [membershipType, 'projects'] });
      await queryClient.invalidateQueries({ queryKey: [membershipType, 'project'] });

      props.onSubmit();
      form.reset();
    } catch (error) {
      if (error instanceof Error) {
        Logger.error(error);
      }

      if (error instanceof RestApiBadRequestServerError && error.formErrors.length > 0) {
        error.formErrors.forEach((formError) => {
          form.setError(formError.field as keyof EditProjectFields, {
            type: 'custom',
            message: formError.messages?.[0],
          });
        });
      } else {
        toast({
          title: t('global.ui.toast.errorToastFallbackTitle'),
          description: t('global.ui.toast.errorToastFallbackDescription'),
          type: 'error',
        });
      }
    }

    setIsSubmitting(false);
  };

  return (
    <FormProvider {...form}>
      <DialogBody>
        <form
          id='edit-project-form'
          onSubmit={form.handleSubmit(onSubmit)}
          data-testid='edit-project-form'
          // NOTE: the padding is needed so the MUI label is not cut off
          className='pt-2'
        >
          <fieldset disabled={isSubmitting} data-testid='edit-project-fieldset'>
            <Stack spacing={8}>
              <FormField
                control={form.control}
                name='name'
                rules={{
                  required: isRequiredLabel,
                }}
                render={({ field: { value, ...field } }) => (
                  <FormItem className='flex flex-col gap-1'>
                    <CustomFormLabel>{t('landSteward.projects.new.form.name.label')}*</CustomFormLabel>
                    <FormControl>
                      <TextInput
                        placeholder={t('landSteward.projects.new.form.name.placeholder')}
                        value={value}
                        {...field}
                        data-cy='project-name'
                      />
                    </FormControl>
                    <CustomFormMessage>{form.formState.errors.name?.message}</CustomFormMessage>
                    <FormDescription data-cy='project-name-helperText'>
                      {t('landSteward.projects.new.form.name.helperText')}
                    </FormDescription>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='location_description'
                rules={{
                  required: isRequiredLabel,
                }}
                render={({ field: { value, ...field } }) => (
                  <FormItem className='flex flex-col gap-1'>
                    <CustomFormLabel>{t('landSteward.projects.new.form.location.label')}*</CustomFormLabel>
                    <FormControl>
                      <TextInput
                        placeholder={t('landSteward.projects.new.form.location.placeholder')}
                        value={value}
                        {...field}
                        data-cy='location-name'
                      />
                    </FormControl>
                    <CustomFormMessage>{form.formState.errors.location_description?.message}</CustomFormMessage>
                    <FormDescription data-cy='location-helperText'>
                      {t('landSteward.projects.new.form.location.helperText')}
                    </FormDescription>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='landtypes_allowed'
                rules={{
                  required: isRequiredLabel,
                }}
                render={({ field: { ref, value, ...field } }) => (
                  <FormItem className='flex flex-col gap-1'>
                    <CustomFormLabel>{t('landSteward.projects.new.form.landType.label')}*</CustomFormLabel>
                    <Select {...field}>
                      <FormControl>
                        <SelectTrigger
                          disabled={true}
                          ref={ref}
                          leftAdornment={landtype && <PlotTypeIcon type={landtype} />}
                          placeholder={
                            landtype !== PlotType.UNDEFINED
                              ? t(`global.plotTypes.${landtype}`)
                              : t('global.plotTypes.other')
                          }
                          data-testid='land-type-select'
                          value={value}
                          data-cy='landType-name'
                        />
                      </FormControl>
                      <CustomFormMessage>{form.formState.errors.landtypes_allowed?.message}</CustomFormMessage>
                      <FormDescription data-cy='landtype-helperText'>
                        {t('landSteward.projects.new.form.landType.helperText')}
                      </FormDescription>
                    </Select>
                  </FormItem>
                )}
              />
            </Stack>
          </fieldset>
        </form>
      </DialogBody>
      <DialogFooter className='mt-12'>
        <DialogClose asChild>
          <Button variant='text' size='small' disabled={isSubmitting} data-cy='cancel-btn'>
            {t('global.ui.buttons.cancel')}
          </Button>
        </DialogClose>
        <Button
          form='edit-project-form'
          size='small'
          type='submit'
          disabled={isSubmitting || isFormPristine}
          loading={isSubmitting}
          data-cy='save-btn'
        >
          {t('shared.projects.editProject.saveButton')}
        </Button>
      </DialogFooter>
    </FormProvider>
  );
};

const CustomFormLabel: FC<PropsWithChildren> = ({ children }) => {
  const { error } = useFormField();

  return (
    <FormLabel
      className={cn('text-[.75rem] font-medium leading-4 text-text-secondary md:text-[.8125rem] md:leading-5', {
        'text-error': error,
      })}
    >
      {children}
    </FormLabel>
  );
};

const CustomFormMessage: FC<PropsWithChildren> = ({ children }) => (
  <FormMessage className='typography-inputLabel mt-0 text-error'>{children}</FormMessage>
);
