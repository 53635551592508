import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  toast,
} from 'component-library';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FactsByFactType } from '@/api/rest/resources/types/dashboard';
import { Plot, PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { usePlotReportsForProject } from '@/pages/shared/hooks/usePlotReportsForProject';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

import { useDestinationPath } from '../../pages/plots/hooks/useDestinationPath';
import { usePaginatedPlotsForProject } from '../../pages/plots/hooks/usePaginatedPlotsForProject';
import { AreaCell, NameCell, PlotRow, PlotTypeCell } from './Cells';
import { PAGE_SIZE } from './constants';
import { DeletePlotWithConfirmation } from './DeletePlotWithConfirmation';
import { ToggleSortButton } from './ToggleSortButton';

export type PlotListDesktopProps = {
  plotFilters?: Record<string, unknown>;
  customColumns?: ColumnDef<PlotRow>[];
};

export const PlotListDesktop: FC<PlotListDesktopProps> = ({ plotFilters, customColumns }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectId = useProjectId();
  const projectDetail = useProjectDetailById(projectId).data;
  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');

  const [plotForDeletion, setPlotForDeletion] = useState<Plot | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const setCurrentPage = (page: number) => setSearchParams({ page: `${page}` }, { preventScrollReset: true });

  const { count: totalPlotCount, results: plotDetails } = usePaginatedPlotsForProject(
    plotFilters ?? {},
    PAGE_SIZE,
    (currentPage - 1) * PAGE_SIZE,
    'order_by_status',
  ).data;

  const plotReports = usePlotReportsForProject({
    pathVariables: { project_id: projectDetail.id },
    queryParams: { plot_id: plotDetails.map((plot) => plot.id) },
  }).data;

  const plots = useMemo(() => {
    return plotDetails.map((plotDetail: Plot) => {
      return { ...plotDetail, facts: { ...(plotReports[plotDetail.id] as FactsByFactType) } };
    });
  }, [plotDetails, plotReports]);

  const metadataColumns: ColumnDef<PlotRow>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.plots.tableHeader.name')}
        </ToggleSortButton>
      ),
      cell: NameCell,
    },
    {
      accessorKey: 'area',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.plots.tableHeader.size')}
        </ToggleSortButton>
      ),
      cell: AreaCell,
    },
    {
      accessorKey: 'type',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('shared.plots.tableHeader.type')}
        </ToggleSortButton>
      ),
      cell: PlotTypeCell,
    },
  ];

  const customMetadataColumns = customColumns ?? [];

  const columns = [...metadataColumns, ...customMetadataColumns];

  const [sorting, setSorting] = useState<SortingState>([]);
  const { getDestinationPath } = useDestinationPath();

  const table = useReactTable({
    data: plots,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      },
    },
    state: {
      sorting,
    },
  });

  const pageStart = (currentPage - 1) * PAGE_SIZE;
  const pageEnd = pageStart + PAGE_SIZE;

  return (
    <>
      <Stack spacing={8} data-testid='plot-list-desktop'>
        <div className='rounded-3xl bg-white-100'>
          <Table data-testid='plots-table'>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className='bg-list-header'>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row) => {
                const destinationPath = getDestinationPath(row.original);
                const onRowClick = () => {
                  const plot = row.original;
                  if (plot.status === PlotStatusEnum.invalid && !isProjectEditable) {
                    toast({
                      toastId: 'project-is-locked-redirect-toast',
                      title: t('landSteward.plot.lockedToasts.redirectAddEditLink'),
                      type: 'error',
                      autoClose: 10_000,
                    });
                  }
                  return destinationPath && navigate(destinationPath, { state: { plotPage: `${currentPage}` } });
                };
                return (
                  <TableRow data-testid='plot-row' key={row.id} onClick={onRowClick}>
                    {row.getVisibleCells().map((cell) => {
                      /* 
                      TODO: MVP-3284 removed spanning logic of plot list notice as part of MVP-3399
                      Need to find a more optimal way of overriding default cell spanning internally in this componenet or driven externally 
                      (eg. when Plot Notice needs to be shown across all fact cells) 
                      */
                      return (
                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        <Stack className='flex items-center justify-between gap-2 md:flex-row'>
          <span className='typography-overline'>
            {t('shared.plots.pagination.titleDesktop', {
              count: totalPlotCount,
              start: Math.max(1, pageStart + 1), // start at 1 for display (pageStart is 0-indexed)
              end: Math.min(pageEnd, totalPlotCount), // show total plots if pageEnd overshoots in the end
            })}
          </span>
          <Pagination
            className='typography-overline text-text-secondary'
            currentPage={currentPage}
            totalCount={totalPlotCount}
            pageSize={PAGE_SIZE}
            onPageChange={(nextPage) => {
              setCurrentPage(nextPage);
            }}
            data-testid='pagination'
          />
        </Stack>
      </Stack>

      <DeletePlotWithConfirmation plot={plotForDeletion} onClose={() => setPlotForDeletion(null)} />
    </>
  );
};
