import { IconButton, RiArrowLeftLine, Stack } from 'component-library';
import { cn } from 'component-library/utils';
import { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

export type HeadingProps = HTMLAttributes<HTMLDivElement> & {
  backPath?: string;
};

export const Heading: FC<HeadingProps> = ({ children, className, backPath, ...delegated }) => (
  <Stack
    direction='row'
    spacing={2}
    className={cn('mb-6 items-center justify-center md:justify-start', className)}
    {...delegated}
  >
    {backPath && (
      <IconButton asChild data-testid='heading-back-icon-button'>
        <Link to={backPath}>
          <RiArrowLeftLine size={24} />
        </Link>
      </IconButton>
    )}
    <div className={cn('typography-h3 text-text-primary', className)} {...delegated}>
      {children}
    </div>
  </Stack>
);
