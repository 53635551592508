import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PROJECT_BY_ID_DASHBOARD_PATH,
  GET_PROJECT_BY_ID_DASHBOARD_PATH,
  GetBuyerProjectByIdDashboardErrorDto,
  GetBuyerProjectByIdDashboardRequestQueryParams,
  GetBuyerProjectByIdDashboardSuccessDto,
  GetProjectByIdDashboardErrorDto,
  GetProjectByIdDashboardRequestQueryParams,
  GetProjectByIdDashboardSuccessDto,
} from '@/api/rest/resources/types/project';
import { useMembershipType } from '@/hooks/useMembershipType';

type QueryParams = GetProjectByIdDashboardRequestQueryParams | GetBuyerProjectByIdDashboardRequestQueryParams;
type SuccessDto = GetProjectByIdDashboardSuccessDto | GetBuyerProjectByIdDashboardSuccessDto;
type ErrorDto = GetProjectByIdDashboardErrorDto | GetBuyerProjectByIdDashboardErrorDto;

export const useDashboardByProjectId = (projectId: string, queryParams?: QueryParams) => {
  const membershipType = useMembershipType();

  const pathVariables = { id: projectId };

  const queryFn = async () => {
    return getRequest<SuccessDto>(
      membershipType === MembershipWithOrganizationTypeEnum.land_steward
        ? GET_PROJECT_BY_ID_DASHBOARD_PATH
        : GET_BUYER_PROJECT_BY_ID_DASHBOARD_PATH,
      pathVariables,
      queryParams,
    );
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'project', pathVariables, 'dashboard', queryParams],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
