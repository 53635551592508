import { ButtonLink, Container, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { SecondaryNav } from '@/components';
import { MeasurementExplainer } from '@/components/NCExplainers/MeasurementExplainer';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { paths } from '@/routing';

import { useMenuItems } from '../hooks/useMenuItems';
import { ProjectsList } from './components/projectList/ProjectsList';
import { ProjectsMap } from './components/projectsMap/ProjectsMap';

export const ProjectsPage = () => {
  const { t } = useTranslation();

  const menuItems = useMenuItems();
  const isSmallScreen = useScreenSize() === 'small';

  const membershipType = useMembershipType();

  return (
    <SignedInPageLayout items={menuItems} withCustomContentContainer>
      <SecondaryNav
        title={t('shared.navbar.projects')}
        action={
          <Stack direction='row' spacing={6}>
            {!isSmallScreen && <MeasurementExplainer />}
            {membershipType === MembershipWithOrganizationTypeEnum.land_steward && (
              <ButtonLink to={paths.landSteward.newProject} data-cy='add-project-button'>
                {t('shared.navbar.labels.createProject')}
              </ButtonLink>
            )}
          </Stack>
        }
      />

      <Container
        contentWidth='lg'
        className='gap-10 pb-10 pt-8'
        gutterWidth={{ sm: 4, md: 10 }}
        data-testid='project-map-wrapper'
        data-cy='project-map-wrapper'
      >
        <div className='h-[60vh] animate-in fade-in'>
          <ProjectsMap />
        </div>

        <ProjectsList />
      </Container>
    </SignedInPageLayout>
  );
};
