import {
  RiInformationFill,
  RiInformationLine,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from 'component-library';
import { ElementRef, FC, forwardRef } from 'react';

import { CarbonInput, Tillage } from '@/api/rest/resources/types/fact';

import { ScenarioType } from '../../../hooks/types';

const FilterTypes = { ...ScenarioType, ...CarbonInput, ...Tillage };

type CustomSelectItemProps = {
  label: string;
  tooltip: string;
  value: keyof typeof FilterTypes | string;
};

export const CustomSelectItem: FC<CustomSelectItemProps> = ({ label, tooltip, value }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <SelectItem key={label} value={value} asChild>
          <span className='flex flex-row items-center justify-start gap-2'>
            <SelectItemText>{label}</SelectItemText>
            <RiInformationLine size={16} className='text-neutral-black-30' />
          </span>
        </SelectItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='right' className='z-10'>
        {tooltip}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

type CustomSelectTriggerProps = {
  label: string;
  tooltip: string;
  value: keyof typeof FilterTypes | string;
};

export const CustomSelectTrigger = forwardRef<ElementRef<typeof SelectTrigger>, CustomSelectTriggerProps>(
  ({ label, tooltip, value }, ref) => {
    return (
      <Tooltip>
        <TooltipTrigger asChild>
          <SelectTrigger ref={ref} key={value} className='px-3 py-2'>
            <span className='flex flex-row items-center justify-start gap-2 rounded-2xl bg-secondary-12 px-2 py-1'>
              <span className='max-w-[300px] truncate'>{label}</span>
              <RiInformationFill size={16} className='text-text-disabled' />
            </span>
          </SelectTrigger>
        </TooltipTrigger>
        <TooltipContent sideOffset={5} side='right' className='z-10'>
          {tooltip}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    );
  },
);
CustomSelectTrigger.displayName = 'CustomSelectTrigger';
