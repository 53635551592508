import { Box, Stack, useTheme } from '@mui/material';
import { Button, Heading, Icon } from 'deprecated-component-library';
import React, { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';

export interface EditContainerLayoutProps {
  isSaveButtonDisabled: boolean;
  isSubmitting: boolean;
  onSave: () => void;
  saveButtonLabel?: string;
  title: string;
  dataCy?: string;
}

export const EditContainerLayout: React.FC<PropsWithChildren<EditContainerLayoutProps>> = ({
  children,
  isSaveButtonDisabled,
  isSubmitting,
  onSave,
  saveButtonLabel,
  title,
  dataCy,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { accountPath } = useAccountPageRoutes();

  const handleNavigateCancel = () => {
    navigate(accountPath);
  };

  const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave();
  };

  return (
    <Stack component='form' spacing={3} onSubmit={handleSave} data-cy={dataCy}>
      <Stack direction='row' spacing={1} mb={2}>
        <Box
          role='button'
          onClick={handleNavigateCancel}
          sx={{ cursor: 'pointer', display: 'flex' }}
          alignItems='center'
        >
          <Icon icon='arrow-back' />
        </Box>
        <Heading variant='h5' data-cy={dataCy ? `${dataCy}-title` : undefined}>
          {title}
        </Heading>
      </Stack>
      {children}
      <Stack direction='row' justifyContent='end' width='100%' spacing={1.5}>
        <Button variant='text' onClick={handleNavigateCancel} data-cy={dataCy ? `${dataCy}-cancel-button` : undefined}>
          {t('global.ui.buttons.cancel')}
        </Button>
        <Button
          type='submit'
          size='large'
          variant='contained'
          isLoading={isSubmitting}
          disabled={isSaveButtonDisabled || isSubmitting}
          onClick={onSave}
          data-cy={dataCy ? `${dataCy}-submit-button` : undefined}
        >
          {saveButtonLabel ?? t('shared.account.buttons.save')}
        </Button>
      </Stack>
    </Stack>
  );
};

export const EditContainerLayoutBox: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box px={3} py={5} bgcolor={theme.palette.background.paper} borderRadius={2.5}>
      {children}
    </Box>
  );
};
