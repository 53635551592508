import { Divider, Stack, useTheme } from '@mui/material';
import { Text } from 'deprecated-component-library';
import React, { DataTestAttributes } from 'react';

import { InformationBoxEntryProps } from './entry';

interface InformationBoxProps extends DataTestAttributes {
  title: string;
  entries: Array<React.ReactElement<InformationBoxEntryProps>>;
}

export const InformationBox: React.FC<InformationBoxProps> = ({ title, entries, ...props }) => {
  const theme = useTheme();

  return (
    <Stack p={3} bgcolor={theme.palette.background.paper} borderRadius={2.5} {...props}>
      <Text
        variant='overline'
        pb={1}
        mb={1.5}
        borderBottom={`1px solid ${theme.palette.divider}`}
        data-cy={props['data-cy'] ? `${props['data-cy']}-subtitle` : undefined}
      >
        {title}
      </Text>
      <Stack spacing={1.5}>
        {entries.map((entry, index) => {
          const isLastElement = index === entries.length - 1;
          return (
            <React.Fragment key={index}>
              {entry}
              {!isLastElement && <Divider />}
            </React.Fragment>
          );
        })}
      </Stack>
    </Stack>
  );
};
