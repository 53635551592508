import { HTMLAttributes } from 'react';

import { Spinner } from '../Spinner/Spinner';
import { cn } from '../utils';

export type LoaderProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * @default 10
   */
  size?: number;
};

export const Loader = ({ size, className, 'data-testid': dataTestid, ...delegated }: LoaderProps) => {
  return (
    <div
      className={cn('flex min-h-screen flex-1 items-center justify-center', className)}
      data-testid={dataTestid ?? 'loading-indicator'}
      {...delegated}
    >
      <Spinner size={size ?? 10} />
    </div>
  );
};
