import { InfoPopover } from 'component-library';
import { cn } from 'component-library/utils';
import { FC, HTMLAttributes } from 'react';

import { isReactNode } from '@/utils/isReactNode';

import { ChartLegendBarItem } from './types';

type ChartLegendBarProps = HTMLAttributes<HTMLDivElement> & {
  items: ChartLegendBarItem[];
};

export const ChartLegendBar: FC<ChartLegendBarProps> = ({ items, className, ...delegated }) => {
  const styles = cn(
    'flex flex-col sm:flex-row items-center self-center sm:self-start rounded-md bg-bg-light-grey p-2 gap-2 w-fit sm:divide-x sm:divide-divider',
    className,
  );

  return (
    <div className={styles} {...delegated}>
      {items.map(({ label, color, shape = 'area', popoverContent }) => (
        <div key={label} className='flex flex-row items-center gap-1 pl-2 first-of-type:pl-0 '>
          {isReactNode(shape) && typeof shape !== 'string' ? (
            shape
          ) : (
            <div
              className={cn({
                'h-2 w-2 rounded-full': shape === 'area',
                'h-0.5 w-2 rounded': shape === 'line',
              })}
              style={{ backgroundColor: color }}
            />
          )}
          <span className='typography-caption text-text-secondary'>{label}</span>
          {popoverContent && <InfoPopover title={popoverContent.title} body={popoverContent.body} />}
        </div>
      ))}
    </div>
  );
};
