import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { BenchmarkType, PlotReportFactLabelNameEnum, R1FactType } from '@/api/rest/resources/types/fact';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { GraphFact } from '@/components/Charts/types';
import { WaterHoldingScenarioChart } from '@/components/Charts/WaterHoldingCapacityScenario';
import { useNCCardContext } from '@/components/NCCard/NCCard';
import { useBenchmarksForPlot, useBenchmarksForProject } from '@/pages/shared/hooks/useBenchmarkForFact';
import { usePlotFact } from '@/pages/shared/hooks/usePlotFact';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';

import { useIsLandPortionProject } from '../../hooks/useIsLandPortionProject';
import { useScenarioForm } from '../../hooks/useScenarioForm';
import { NoData } from '../NoData';

export const WaterGraphTile = () => {
  const isLandPortionProject = useIsLandPortionProject();
  return isLandPortionProject ? <ProjectWaterGraph /> : <PlotWaterGraph />;
};

export const ProjectWaterGraph = () => {
  const { ...filters } = useScenarioForm().watch();
  const { analysisType } = useNCCardContext();

  const historicWaterAndSoilMoisture = useProjectFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_historic_water_and_soil_moisture_graph
      : R1FactType.r1_historic_water_and_soil_moisture_per_ha_graph,
  );
  const whcHistoricData = historicWaterAndSoilMoisture?.value ?? [];

  const waterHoldingCapacity10yearUpliftPotential = useProjectFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_10year_uplift_potential_graph
      : R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha_graph,
    null,
    filters,
  );
  const whcData = waterHoldingCapacity10yearUpliftPotential?.value;

  const whcLowBenchmark = useProjectFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    null,
    { [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.low },
  );
  const whcLowBenchmarkData = (whcLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const whcModerateBenchmark = useProjectFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.moderate,
    },
  );
  const whcModerateBenchmarkData = (whcModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const whcHighBenchmark = useProjectFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.high,
    },
  );
  const whcHighBenchmarkData = (whcHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const benchmarks = useBenchmarksForProject(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
  );

  /**
   * We only null check for whcData since whcData is the primary source data of the graph
   * wpData and other data are reference lines that are displayed only when whcData is rendered
   */
  const noChartData = !whcData?.length;
  if (noChartData) {
    return <NoData />;
  }

  const chartData = [
    ...whcHistoricData,
    ...whcData,
    ...whcLowBenchmarkData,
    ...whcModerateBenchmarkData,
    ...whcHighBenchmarkData,
  ];

  return <WaterGraph chartData={chartData} benchmarks={benchmarks} />;
};

export const PlotWaterGraph = () => {
  const { analysisType } = useNCCardContext();
  const { landPortionId: plotId, ...filters } = useScenarioForm().watch();

  const historicWaterAndSoilMoisture = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_historic_water_and_soil_moisture_graph
      : R1FactType.r1_historic_water_and_soil_moisture_per_ha_graph,
    plotId,
  );
  const whcHistoricData = historicWaterAndSoilMoisture?.value ?? [];

  const waterHoldingCapacity10yearUpliftPotential = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_10year_uplift_potential_graph
      : R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha_graph,
    plotId,
    filters,
  );
  const whcData = waterHoldingCapacity10yearUpliftPotential?.value;

  const whcLowBenchmark = usePlotFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    plotId,
    { [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.low },
  );
  const whcLowBenchmarkData = (whcLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const whcModerateBenchmark = usePlotFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    plotId,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.moderate,
    },
  );
  const whcModerateBenchmarkData = (whcModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const whcHighBenchmark = usePlotFact<GraphFact[]>(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    plotId,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.high,
    },
  );
  const whcHighBenchmarkData = (whcHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const benchmarks = useBenchmarksForPlot(
    analysisType === 'total' ? R1FactType.water_fc_total_benchmark_graph : R1FactType.water_fc_per_ha_benchmark_graph,
    plotId,
  );

  /**
   * We only null check for whcData since whcData is the primary source data of the graph
   * wpData and other data are reference lines that are displayed only when whcData is rendered
   */
  const noChartData = !whcData?.length;
  if (noChartData) {
    return <NoData />;
  }

  const chartData = [
    ...whcHistoricData,
    ...whcData,
    ...whcLowBenchmarkData,
    ...whcModerateBenchmarkData,
    ...whcHighBenchmarkData,
  ];

  return <WaterGraph chartData={chartData} benchmarks={benchmarks} />;
};

export const WaterGraph = ({
  chartData,
  benchmarks,
}: {
  chartData: GraphFact[];
  benchmarks: (number | undefined)[];
}) => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();
  const timeFrame = useScenarioForm().watch('timeFrame');

  return (
    <Stack center data-testid='water-uplift-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center px-1 py-6 lg:items-start lg:p-8 lg:pl-1'>
        <ChartHeading
          heading={t('shared.projects.project.impactProjections.water.labels.graphHeadline', {
            timeFrame,
          })}
          popover={{
            title: t('shared.ncaDetail.details.water.explainers.upliftPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.upliftPotential.body'),
          }}
        />
        <WaterHoldingScenarioChart
          analysisType={analysisType}
          data={chartData}
          benchmarks={benchmarks}
          showTooltip={true}
          className='relative'
          data-testid='water-holding-capacity-chart'
        />
      </Stack>
    </Stack>
  );
};
