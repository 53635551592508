import { Button, cn, Popover, PopoverContent, PopoverTrigger, RiEdit2Fill, Stack, StackProps } from 'component-library';
import React, { HTMLAttributes, PropsWithChildren, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { KycEnum } from '@/api/rest/resources/types/user';
import { KycDialog } from '@/components/KycDialog/kyc-dialog';
import { HELP_EMAIL_ADDRESS } from '@/config/constants';
import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { useUser } from '@/lib/auth';
import { printFullName } from '@/utils/formatting/user';

import { EmailInformationBoxEntryStatus, EmailVerificationStatus, SelectLanguage } from '../../../components';
import { AccountKycStatus } from '../../../components/kyc/status/status';

export const MobileView = () => {
  const { t } = useTranslation();

  const user = useUser();
  const { accountEditEmailPath, accountEditPasswordPath, accountEditNamePath } = useAccountPageRoutes();
  const [openKycDialog, setOpenKycDialog] = useState(false);

  const companyName = user.membership.organization.name;
  const isUserLandSteward = user.membership.type === MembershipWithOrganizationTypeEnum.land_steward;
  const isKycSectionShown = isUserLandSteward && user.kyc !== KycEnum.success;

  return (
    <>
      <KycDialog open={openKycDialog} onChange={setOpenKycDialog} />
      <Stack spacing={6} data-testid='account-page-mobile-view'>
        <Stack spacing={8} className='rounded-2xl bg-white-100 p-6'>
          <Section>
            <SectionHeading>{t('shared.account.titles.loginData')}</SectionHeading>
            <SectionContent>
              <SectionItem
                label={t('shared.account.labels.email')}
                value={user.email}
                EditButtonProps={{ link: accountEditEmailPath }}
              >
                <EmailInformationBoxEntryStatus
                  status={user.email_activated ? EmailVerificationStatus.VERIFIED : EmailVerificationStatus.UNVERIFIED}
                />
              </SectionItem>
              <SectionItem
                label={t('shared.account.labels.password')}
                value='********'
                EditButtonProps={{ link: accountEditPasswordPath }}
              />
            </SectionContent>
          </Section>
          <Section>
            <SectionHeading>{t('shared.account.titles.personalData')}</SectionHeading>
            <SectionContent>
              <SectionItem
                label={t('shared.account.labels.name')}
                value={printFullName(user)}
                EditButtonProps={{
                  link: accountEditNamePath,
                  disabled: [KycEnum.in_progress, KycEnum.success].includes(user.kyc),
                  disabledHint:
                    user.kyc === KycEnum.in_progress
                      ? t('shared.account.popovers.editNameDisabledDueToKycInProgress')
                      : t('shared.account.popovers.editNameDisabledDueToKycSuccess'),
                }}
              >
                {isUserLandSteward && <AccountKycStatus status={user.kyc} />}
              </SectionItem>
              <SectionItem label={t('shared.account.labels.company')} value={companyName} />
            </SectionContent>
          </Section>
          {isKycSectionShown && (
            <Section>
              <SectionHeading>{t('shared.account.verifyAccount.title')}</SectionHeading>
              <SectionContent>
                <div className='flex flex-col items-start justify-between gap-2 lg:flex-row'>
                  <span className='typography-body2 text-text-primary'>
                    {t('shared.account.verifyAccount.description')}
                  </span>
                  <Button
                    variant='contained'
                    type='button'
                    onClick={() => setOpenKycDialog(true)}
                    disabled={user.kyc === KycEnum.in_progress}
                  >
                    {t('shared.account.verifyAccount.button')}
                  </Button>
                </div>
              </SectionContent>
            </Section>
          )}
          <Section>
            <SectionHeading>{t('shared.account.titles.settings')}</SectionHeading>
            <SelectLanguage />
          </Section>
        </Stack>

        <Stack spacing={8} className='rounded-2xl bg-white-100 p-6'>
          <Section>
            <SectionHeading>{t('shared.account.titles.customerSupport')}</SectionHeading>
            <span className='typography-body1'>
              <Trans
                i18nKey='shared.account.labels.customerSupportDescription'
                values={{ email: HELP_EMAIL_ADDRESS }}
                components={{
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  a: <a href={`mailto:${HELP_EMAIL_ADDRESS}`} />,
                }}
              />
            </span>
          </Section>
        </Stack>
      </Stack>
    </>
  );
};

const Section = Stack;

const SectionHeading = (props: HTMLAttributes<HTMLDivElement>) => {
  return <div className='typography-overline mb-4 border-b border-divider pb-4' {...props} />;
};

const SectionContent = (props: StackProps) => <Stack spacing={4} {...props} />;

type SectionItemProps = {
  label: string;
  value: string;
  EditButtonProps?: EditIconButtonProps;
  children?: React.ReactNode;
};

const SectionItem: React.FC<PropsWithChildren<SectionItemProps>> = ({ label, value, EditButtonProps, children }) => {
  return (
    <Stack spacing={2} data-testid={`section-item-${label}`}>
      <span className='typography-inputLabel text-text-secondary'>{label}</span>
      <Stack>
        <Stack direction='row' className='items-center justify-between'>
          <span>{value}</span>
          {EditButtonProps && <EditButton {...EditButtonProps} />}
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
};

type EditIconButtonProps = {
  link: string;
  disabled?: boolean;
  disabledHint?: string;
};

const EditButton: React.FC<EditIconButtonProps> = ({ link, disabled, disabledHint }) => {
  if (!disabled) {
    return (
      <Link to={link}>
        <EditButtonIcon />
      </Link>
    );
  }

  if (!disabledHint) {
    return <EditButtonIcon disabled />;
  }

  return (
    <Popover>
      <PopoverTrigger className='cursor-pointer' data-testid='popover-trigger'>
        <EditButtonIcon disabled />
      </PopoverTrigger>
      <PopoverContent position='right' size='small' className='whitespace-pre-wrap'>
        {disabledHint}
      </PopoverContent>
    </Popover>
  );
};

const EditButtonIcon: React.FC<{ disabled?: boolean }> = ({ disabled }) => (
  <RiEdit2Fill
    className={cn('text-text-primary', {
      'text-text-disabled': disabled === true,
    })}
    size={20}
  />
);
