import { cn, Select, SelectContent, SelectItem, SelectTrigger, Stack, TextInput } from 'component-library';
import { FC, PropsWithChildren } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RoleEnum } from '@/api/rest/resources/types/membership';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, useFormField } from '@/components';
import { validateEmail } from '@/utils/validators';

import { InviteInputs } from '../hooks/useSendInvite';

type FormContentProps = {
  form: UseFormReturn<InviteInputs>;
};

export const FormContent: FC<FormContentProps> = ({ form }) => {
  const { t } = useTranslation();

  const requiredLabel = t('global.ui.form.input.required');

  return (
    <Stack spacing={6} className='max-w-full sm:max-w-[220px]'>
      <FormField
        control={form.control}
        name='user.firstname'
        rules={{
          required: true,
        }}
        render={({ field: { value, ...field } }) => (
          <FormItem className='flex flex-col gap-1'>
            <CustomFormLabel>{t('shared.account.teamInvite.form.firstName.label')}</CustomFormLabel>
            <FormControl>
              <TextInput
                placeholder={t('shared.account.teamInvite.form.firstName.placeholder')}
                value={value ?? ''}
                {...field}
              />
            </FormControl>
            <CustomFormMessage>{requiredLabel}</CustomFormMessage>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name='user.lastname'
        rules={{ required: true }}
        render={({ field: { value, ...field } }) => (
          <FormItem className='flex flex-col gap-1'>
            <CustomFormLabel>{t('shared.account.teamInvite.form.lastName.label')}</CustomFormLabel>
            <FormControl>
              <TextInput
                placeholder={t('shared.account.teamInvite.form.lastName.placeholder')}
                value={value ?? ''}
                {...field}
              />
            </FormControl>
            <CustomFormMessage>{requiredLabel}</CustomFormMessage>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name='user.email'
        rules={{
          required: requiredLabel,
          validate: (value) => validateEmail(value) || t('global.ui.form.input.emailInvalid'),
        }}
        render={({ field: { value, ...field } }) => (
          <FormItem className='flex flex-col gap-1'>
            <CustomFormLabel>{t('shared.account.teamInvite.form.emailAddress.label')}</CustomFormLabel>
            <FormControl>
              <TextInput
                type='email'
                placeholder={t('shared.account.teamInvite.form.emailAddress.placeholder')}
                value={value ?? ''}
                {...field}
              />
            </FormControl>
            <CustomFormMessage>{form.formState.errors.user?.email?.message}</CustomFormMessage>
          </FormItem>
        )}
      />
      <FormField
        name='role'
        rules={{ required: true }}
        control={form.control}
        render={({ field: { ref, value, ...field } }) => (
          <FormItem className='flex flex-col gap-1'>
            <CustomFormLabel>{t('shared.account.teamInvite.form.permissionLevel.label')}</CustomFormLabel>
            <Select value={value ?? ''} {...field}>
              <FormControl>
                <SelectTrigger
                  ref={ref}
                  placeholder={t('shared.account.teamInvite.form.permissionLevel.placeholder')}
                />
              </FormControl>
              <SelectContent>
                <SelectItem value={RoleEnum.admin}>{t('global.memberRoles.admin')}</SelectItem>
                <SelectItem value={RoleEnum.editor}>{t('global.memberRoles.editor')}</SelectItem>
              </SelectContent>
            </Select>
            <CustomFormMessage>{requiredLabel}</CustomFormMessage>
          </FormItem>
        )}
      />
    </Stack>
  );
};

const CustomFormLabel: FC<PropsWithChildren> = ({ children }) => {
  const { error } = useFormField();

  return (
    <FormLabel
      className={cn('text-[.75rem] font-medium leading-4 text-text-secondary md:text-[.8125rem] md:leading-5', {
        'text-error': error,
      })}
    >
      {children}
    </FormLabel>
  );
};

const CustomFormMessage: FC<PropsWithChildren> = ({ children }) => (
  <FormMessage className='typography-inputLabel mt-0 text-error'>{children}</FormMessage>
);
