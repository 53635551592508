import { Divider, Select, SelectContent, Stack } from 'component-library';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CarbonInput, Tillage } from '@/api/rest/resources/types/fact';
import { FormControl, FormField, FormItem, FormLabel } from '@/components';

import { DEFAULT_SCENARIO_INPUTS } from '../../../constants';
import { ScenarioInputs, ScenarioType } from '../../../hooks/types';
import { useScenarioForm } from '../../../hooks/useScenarioForm';
import { CustomSelectItem, CustomSelectTrigger } from './CustomSelect';

export const LabelFilters = () => {
  const { t } = useTranslation();

  const form = useScenarioForm();

  useEffect(() => {
    const currentValues = form.getValues();
    if (
      !Object.entries(DEFAULT_SCENARIO_INPUTS).every(
        (label) => currentValues[label[0] as keyof ScenarioInputs] === label[1],
      )
    ) {
      form.setValue('scenarioType', ScenarioType.customised);
    }
  }, [form]);

  const scenarioTypes = useMemo(() => {
    return (Object.keys(ScenarioType) as ScenarioType[]).map((type) => ({
      value: type,
      label: t(`shared.projects.project.impactProjections.scenarioEditor.labelsFilter.scenarioTypes.${type}.display`),
      tooltip: t(`shared.projects.project.impactProjections.scenarioEditor.labelsFilter.scenarioTypes.${type}.tooltip`),
    }));
  }, [t]);

  const carbonInputs = useMemo(() => {
    return (Object.keys(CarbonInput) as CarbonInput[]).map((type) => ({
      value: type,
      label: t(`shared.projects.project.impactProjections.scenarioEditor.labelsFilter.carbonInput.${type}.display`),
      tooltip: t(`shared.projects.project.impactProjections.scenarioEditor.labelsFilter.carbonInput.${type}.tooltip`),
    }));
  }, [t]);

  const tillages = useMemo(() => {
    return (Object.keys(Tillage) as Tillage[]).map((type) => ({
      value: type,
      label: t(`shared.projects.project.impactProjections.scenarioEditor.labelsFilter.tillage.${type}.display`),
      tooltip: t(`shared.projects.project.impactProjections.scenarioEditor.labelsFilter.tillage.${type}.tooltip`),
    }));
  }, [t]);

  return (
    <FormProvider {...form}>
      <Stack spacing={4} className='flex-col justify-start md:flex-row'>
        <FormField
          control={form.control}
          name='scenarioType'
          render={({ field: { ref, value, ...field } }) => (
            <FormItem className='flex flex-col gap-1'>
              <FormLabel className='typography-button2 text-text-secondary'>
                {t('shared.projects.project.impactProjections.scenarioEditor.labelsFilter.scenarioOptimisation')}
              </FormLabel>
              <Select value={value} {...field}>
                <FormControl>
                  <CustomSelectTrigger
                    ref={ref}
                    value={value}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    label={scenarioTypes.find((type) => type.value === value)!.label}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    tooltip={scenarioTypes.find((type) => type.value === value)!.tooltip}
                  />
                </FormControl>
                <SelectContent>
                  {scenarioTypes.map((item) => (
                    <CustomSelectItem key={item.value} {...item} />
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
        <Divider direction='vertical' className='hidden md:block' />
        <Divider direction='horizontal' className='md:hidden' />
        <div className='md:w-96'>
          <FormField
            control={form.control}
            name='carbon_input'
            render={({ field: { ref, value, ...field } }) => (
              <FormItem className='flex flex-col gap-1'>
                <FormLabel className='typography-button2 text-text-secondary'>
                  {t('shared.projects.project.impactProjections.scenarioEditor.labelsFilter.agronomicMethods')}
                </FormLabel>
                <Select value={value} {...field}>
                  <FormControl>
                    <CustomSelectTrigger
                      ref={ref}
                      value={value}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      label={carbonInputs.find((type) => type.value === value)!.label}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      tooltip={carbonInputs.find((type) => type.value === value)!.tooltip}
                    />
                  </FormControl>
                  <SelectContent>
                    {carbonInputs.map((item) => (
                      <CustomSelectItem key={item.value} {...item} />
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name='tillage'
          render={({ field: { ref, value, ...field } }) => (
            <FormItem className='flex flex-col gap-1'>
              <FormLabel className='typography-button2 text-text-secondary'>
                {t('shared.projects.project.impactProjections.scenarioEditor.labelsFilter.cultivationMethods')}
              </FormLabel>
              <Select value={value} {...field}>
                <FormControl>
                  <CustomSelectTrigger
                    ref={ref}
                    value={value}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    label={tillages.find((type) => type.value === value)!.label}
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    tooltip={tillages.find((type) => type.value === value)!.tooltip}
                  />
                </FormControl>
                <SelectContent>
                  {tillages.map((item) => (
                    <CustomSelectItem key={item.value} {...item} />
                  ))}
                </SelectContent>
              </Select>
            </FormItem>
          )}
        />
      </Stack>
    </FormProvider>
  );
};
