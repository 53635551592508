import { RiRoadMapLine, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { SecondaryNav } from '@/components';
import { usePathSet } from '@/hooks/usePathSet';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { SignedInPageLayoutContentContainer } from '@/layout/signed-in-page/components';
import { useMenuItems } from '@/pages/shared/hooks/useMenuItems';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';

import { FloraAndFaunaEvolutionCard } from './components/FloraAndFaunaEvolutionCard';
import { SpeciesBreakdownCard } from './components/SpeciesBreakdownCard';

export const FloraAndFaunaPage = () => {
  const { t } = useTranslation();

  const menuItems = useMenuItems();
  const projectId = useProjectId();
  const pathSet = usePathSet();

  return (
    <SignedInPageLayout items={menuItems} withCustomContentContainer>
      <SecondaryNav
        title={t('shared.ncaDetail.floraAndFauna.pageTitle')}
        backPath={buildPath(pathSet.projectDetails, { pathParams: { projectId } })}
        action={{
          to: buildPath(pathSet.interactiveMap, { pathParams: { projectId } }),
          variant: 'contained',
          children: t('shared.ncaDetail.buttons.seeInteractiveMap'),
          leftAdornment: <RiRoadMapLine size={20} />,
        }}
      />

      <SignedInPageLayoutContentContainer>
        <Stack spacing={8}>
          <FloraAndFaunaEvolutionCard />
          <SpeciesBreakdownCard />
        </Stack>
      </SignedInPageLayoutContentContainer>
    </SignedInPageLayout>
  );
};
