import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { cn, RiDeleteBin7Fill, RiPencilFill, RiSearchLine, Stack } from 'component-library';
import { ButtonHTMLAttributes, forwardRef } from 'react';

export type DrawControlsProps = {
  onDeleteClick?: () => void;
  onDrawClick?: () => void;
  onLocationSearchClick?: () => void;
  disableDelete?: boolean;
  disableDraw?: boolean;
};

export const DrawControls = ({
  onDrawClick,
  onDeleteClick,
  onLocationSearchClick,
  disableDraw,
  disableDelete,
}: DrawControlsProps) => {
  return (
    <>
      <Stack spacing={4} className='absolute right-8 top-8' data-testid='draw-controls'>
        <DrawOptionButton onClick={onDrawClick} disabled={disableDraw} data-testid='draw-new-polygon'>
          <RiPencilFill />
        </DrawOptionButton>
        <DrawOptionButton onClick={onDeleteClick} disabled={disableDelete} data-testid='delete-polygon'>
          <RiDeleteBin7Fill />
        </DrawOptionButton>
        <DrawOptionButton onClick={onLocationSearchClick} data-testid='open-location-search'>
          <RiSearchLine />
        </DrawOptionButton>
      </Stack>
    </>
  );
};

const DrawOptionButton = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(
  ({ className, ...delegated }, ref) => (
    <button
      ref={ref}
      className={cn(
        'rounded-lg bg-white-100 p-1.5 text-3xl text-primary-100',
        'disabled:bg-bg-light-grey disabled:text-text-disabled',
        className,
      )}
      {...delegated}
    />
  ),
);
DrawOptionButton.displayName = 'DrawOptionButton';
