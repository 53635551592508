import { Loader } from 'component-library';
import React from 'react';

import { OnboardingPage } from '@/layout/OnboardingPage';

import { BuyerCompleteAccountError } from './components/error/error';
import { BuyerCompleteAccountForm } from './components/form/form';
import { PageState, useBuyerCompleteAccountPage } from './hooks/useBuyerCompleteAccountPage';

export const BuyerCompleteAccountPage: React.FC = () => {
  const { pageState, predefinedData, handleCompleteAccount, isSubmitting } = useBuyerCompleteAccountPage();
  return (
    <>
      {(() => {
        switch (pageState) {
          case PageState.LOADING:
            return <Loader data-testid='loading-indicator' />;
          case PageState.SUCCESS:
            return (
              <OnboardingPage>
                <BuyerCompleteAccountForm
                  predefinedData={predefinedData}
                  handleCompleteAccount={handleCompleteAccount}
                  isSubmitting={isSubmitting}
                />
              </OnboardingPage>
            );
          default:
            return <BuyerCompleteAccountError pageState={pageState} />;
        }
      })()}
    </>
  );
};
