import { cn, Divider, InfoPopover, Pill, RiLoaderFill, Stack } from 'component-library';
import React, { FC, HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { PlotStatusEnum, PlotType } from '@/api/rest/resources/types/plot';
import { UnitEnum } from '@/api/rest/resources/types/units';
import { PlotTypeIcon } from '@/components';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useScreenSize } from '@/hooks/useScreenSize';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotFact } from '@/pages/shared/hooks/usePlotFact';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { formatUnit } from '@/utils/formatting';
import { squareMetersToHectares } from '@/utils/plot';

const maxDisplayCropCount = 10;

export const PlotOverview: React.FC = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const projectDetail = useProjectDetailById().data;
  const plot = usePlot().data;

  const areaDisplay = `${useDisplayNumber(squareMetersToHectares(plot.area))} ${formatUnit(UnitEnum.ha)}`;

  const deforestedPlotCount = usePlotFact<number>(R1FactType.r1_deforestation_occurred_since_2020, plot.id);

  const plotType = plot.type ?? null;

  const crops =
    plotType === PlotType.CROPLAND && plot.crops && plot.crops.length > 0
      ? plot.crops.map((crop) => t(`global.crops.${crop}`)).sort()
      : undefined;

  const deforestationActivityText = useMemo(() => {
    if (deforestedPlotCount?.value == null) {
      if ([PlotStatusEnum.calculating, PlotStatusEnum.scheduled_for_analysis].includes(plot.status)) {
        return (
          <DataStackContent className='text-text-secondary'>
            {t('global.analysis.calculationInProgress')}
          </DataStackContent>
        );
      }
      return <DataStackContent className='text-text-secondary'>{t('global.analysis.noAnalysis')}</DataStackContent>;
    }
    const isOutdatedValue = deforestedPlotCount?.value !== null && plot.status !== PlotStatusEnum.analysed;
    const textColor = isOutdatedValue ? 'text-text-secondary' : 'text-text-primary';

    if (deforestedPlotCount?.value === 0) {
      return (
        <DataStackContent className={textColor}>
          {t('shared.ncaDetail.details.deforestation.noDeforestationActivityDetected')}
        </DataStackContent>
      );
    }
    if (deforestedPlotCount?.value === 1) {
      return (
        <DataStackContent className={textColor}>
          {t('shared.ncaDetail.details.deforestation.deforestationActivityDetected')}
        </DataStackContent>
      );
    }
    return <DataStackContent className='text-text-secondary'>{t('global.analysis.noAnalysis')}</DataStackContent>;
  }, [t, deforestedPlotCount, plot]);

  return (
    <div className='grid-row grid auto-rows-min items-start rounded-3xl bg-white-100' data-testid='plot-overview'>
      <DataGroup className='items-start'>
        <DataStack>
          <DataStackLabel>{t('global.plot.mapPopup.labels.plotName')}</DataStackLabel>
        </DataStack>
        <DataStackContent>{plot.name}</DataStackContent>
      </DataGroup>

      <Divider />

      <DataGroup className={cn('grid grid-cols-1 gap-6 md:grid-cols-2')}>
        <DataStack>
          <DataStackLabel>{t('shared.ncaDetail.details.labels.plotSize')}</DataStackLabel>
          <DataStackContent>{areaDisplay}</DataStackContent>
        </DataStack>
        <DataStack>
          <DataStackLabel>{t('global.plot.plotLandType')}</DataStackLabel>
          <DataStackContent>
            <Stack direction='row' spacing={1}>
              <PlotTypeIcon type={plotType} size={20} />
              <span>{t(`global.plotTypes.${plotType}`)}</span>
            </Stack>
          </DataStackContent>
        </DataStack>
        <DataStack>
          <DataStackLabel>{t('shared.ncaDetail.details.labels.location')}</DataStackLabel>
          <DataStackContent>
            {projectDetail.location_description || t('global.plot.mapPopup.unknownLocation')}
          </DataStackContent>
        </DataStack>
        {crops && (
          <DataStack>
            <DataStackLabel>{t('shared.ncaDetail.details.labels.crops')}</DataStackLabel>
            <Stack className='flex flex-row flex-wrap gap-2'>
              {crops.slice(0, maxDisplayCropCount).map((crop, index) => (
                <Pill key={index} size='small' className='whitespace-nowrap bg-warning-light'>
                  {crop}
                </Pill>
              ))}
              {crops.length > maxDisplayCropCount && (
                <Pill key='...' size='small' className='bg-white whitespace-nowrap'>
                  {t('shared.ncaDetail.itemsMoreCount', {
                    count: crops.length - maxDisplayCropCount,
                  })}
                </Pill>
              )}
            </Stack>
          </DataStack>
        )}
      </DataGroup>

      <Divider className='mx-6' />

      <DataGroup>
        <DataStack>
          <DataStackLabel>{t('global.plot.plotStatus')}</DataStackLabel>
          <Pill
            data-testid='plot-status-badge'
            size='small'
            className={cn('typography-overline whitespace-nowrap px-4 py-2', 'bg-neutral-black-8 text-text-primary', {
              'bg-neutral-black-8 text-text-primary': plot.status === PlotStatusEnum.draft,
              'bg-warning text-warning-dark': plot.status === PlotStatusEnum.ready_to_analyse,
              'bg-success text-white-100': plot.status === PlotStatusEnum.analysed,
              'bg-new-plot-light text-new-plot-dark': plot.status === PlotStatusEnum.new_plot,
              'bg-warning-light text-black-100': plot.status === PlotStatusEnum.calculating,
            })}
          >
            {plot.status === PlotStatusEnum.calculating && <RiLoaderFill size={12} />}
            {t(`global.plotStatus.${plot.status}`)}
          </Pill>
        </DataStack>
      </DataGroup>

      <Divider className='mx-6' />

      <DataGroup>
        <DataStack>
          <DataStackLabel>
            <Stack direction='row' spacing={1} className='items-center'>
              {t('shared.ncaDetail.details.deforestation.deforestationActivity')}
              {!isSmallScreen && (
                <InfoPopover position='top' body={t('shared.ncaDetail.details.deforestation.popoverBody')} />
              )}
            </Stack>
          </DataStackLabel>
          {deforestationActivityText}
        </DataStack>
      </DataGroup>
    </div>
  );
};

const DataGroup: FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...delegated }) => (
  <div className={cn('p-6', className)} {...delegated} />
);

const DataStack = Stack;

const DataStackLabel: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-overline mb-2', className)} {...delegated} />
);
const DataStackContent: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-h3', className)} {...delegated} />
);
