import 'mapbox-gl/dist/mapbox-gl.css';

import { cn, Container } from 'component-library';
import { useRef, useState } from 'react';
import { Layer, LayerProps, MapRef, Source } from 'react-map-gl';

import { Form, Map, SecondaryNav, withSuspenseBoundary } from '@/components';
import { MAP_MAX_ZOOM, MAP_OVERVIEW_PADDING_MOBILE } from '@/config/constants';
import { usePathSet } from '@/hooks/usePathSet';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { useMenuItems } from '@/pages/shared/hooks/useMenuItems';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';
import { isTestEnv } from '@/utils/isTestEnv';

import { useFact } from '../../hooks/useFact';
import { useProject } from '../../hooks/useProject';
import { useProjectBoundary, useProjectBounds, useProjectCells } from '../../hooks/useProjectPolygon';
import { Cells } from './components/Cells';
import { Controls } from './components/Controls';
import { EcologicalIntegrityLayers } from './components/EcologicalIntegrityLayers';
import { FloraAndFaunaLayers } from './components/FloraAndFaunaLayers';
import { HabitatThreatLayers } from './components/HabitatThreatLayers';
import { InfoPanel } from './components/InfoPanel';
import { PROJECT_BOUNDARY_SOURCE_ID } from './constants';
import { useControlsContext, useControlsForm } from './hooks/useControlsForm';
import { useInteractiveLayers } from './hooks/useInteractiveLayers';

export const InteractiveMap = () => {
  const menuItems = useMenuItems();
  const projectId = useProjectId();
  const { data: project } = useProject();
  const pathSet = usePathSet();

  /**
   * The Map's onLoad callback is not triggered in the test environment, and so
   * the map remains hidden. We set the default value of isMapReady to true in
   * the test environment.
   */
  const [isMapReady, setIsMapReady] = useState(isTestEnv);

  const mapRef = useRef<MapRef | null>(null);

  const currentYear = useFact<number>('r1_conservation_current_year').value;

  const controlsForm = useControlsForm({
    defaultValues: {
      year: `${currentYear}`,
      threatFilters: [],
    },
  });

  const [cell] = controlsForm.watch(['cell']);

  const { data: projectBounds } = useProjectBounds();
  const { data: projectBoundary } = useProjectBoundary();
  const { data: projectCells } = useProjectCells();

  const initialViewState = {
    bounds: projectBounds,
    fitBoundsOptions: { padding: MAP_OVERVIEW_PADDING_MOBILE, maxZoom: MAP_MAX_ZOOM },
  };

  const interactiveLayerIds = useInteractiveLayers(controlsForm);

  return (
    <SignedInPageLayout items={menuItems} withCustomContentContainer>
      <SecondaryNav title={project.name} backPath={buildPath(pathSet.projectDetails, { pathParams: { projectId } })} />
      <Form {...controlsForm}>
        <Container
          gutterWidth={{ sm: 4, md: 10 }}
          className='h-[calc(100%_-_var(--footer-height))] py-8'
          data-testid='conservation-interactive-map-page'
        >
          <div
            className={cn(
              'relative h-full w-full overflow-hidden rounded-3xl bg-transparent',
              !isMapReady && 'animate-pulse bg-info-light',
            )}
          >
            <Map
              ref={mapRef}
              data-testid='conservation-interactive-map-gl'
              initialViewState={initialViewState}
              onLoad={(e) => {
                /**
                 * The map randomly renders half-blank.
                 * The following steps prevent that from happening.
                 */
                e.target.resize();
                e.target.fitBounds(initialViewState.bounds, initialViewState.fitBoundsOptions);

                setIsMapReady(true);
              }}
              interactiveLayerIds={Array.from(interactiveLayerIds)}
              style={{ opacity: isMapReady ? 1 : 0, transition: 'opacity 0.2s', width: '100%', height: '100%' }}
              logoPosition='bottom-left'
            >
              <AllLayers />

              <Source id={PROJECT_BOUNDARY_SOURCE_ID} type='geojson' data={projectBoundary}>
                <Layer {...projectBoundaryLayer} />
              </Source>
              {projectCells && <Cells />}

              <Controls className='absolute left-6 top-6' />

              {cell && (
                <div className='absolute right-0 top-0 h-full animate-in fade-in slide-in-from-right-[10%]'>
                  <InfoPanel />
                </div>
              )}
            </Map>
          </div>
        </Container>
      </Form>
    </SignedInPageLayout>
  );
};

const projectBoundaryLayer: LayerProps = {
  id: 'feat-project-boundary',
  type: 'line',
  source: PROJECT_BOUNDARY_SOURCE_ID,
  paint: {
    'line-width': 1,
    'line-opacity': 0.6,
    'line-color': 'rgb(255, 255, 255)',
  },
};

const AllLayers = withSuspenseBoundary(() => {
  const controlsForm = useControlsContext();
  const [indicatorLayer] = controlsForm.watch(['indicatorLayer']);

  return (
    <>
      {indicatorLayer === 'ecologicalIntegrity' && <EcologicalIntegrityLayers />}
      {indicatorLayer === 'habitatIntactness' && <HabitatThreatLayers />}
      {indicatorLayer === 'floraFauna' && <FloraAndFaunaLayers />}
    </>
  );
}, null);
