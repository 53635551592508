import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import {
  NCCard,
  NCCard2SectionLayout,
  NCCardAnalysisTypeToggle,
  NCCardComplianceFooter,
  NCCardHeader,
} from '@/components/NCCard/NCCard';
import { WaterExplainer } from '@/components/NCExplainers/WaterExplainer';
import { COMPLIANCE_LOGOS_WATER } from '@/config/constants';
import { useScreenSize } from '@/hooks/useScreenSize';

import { WaterCardGraphTile } from './GraphTile';
import { WaterCardStatsTile } from './StatsTile';

export const WaterCard = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <NCCard explainer={<WaterExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.details.water.labels.waterHoldingCapacity')}>
        {!isSmallScreen && <NCCardAnalysisTypeToggle data-testid='water-toggle' />}
      </NCCardHeader>
      <NCCard2SectionLayout>
        <Stack className='items-center pt-4 sm:items-stretch sm:pt-0'>
          {isSmallScreen && <NCCardAnalysisTypeToggle data-testid='water-toggle' />}
          <WaterCardGraphTile />
        </Stack>
        <WaterCardStatsTile />
      </NCCard2SectionLayout>
      <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_WATER} />
    </NCCard>
  );
};
