import { ButtonLink, Stack } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { kgToTonneFormatter } from '@/components/Charts/utils';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCard/NCCard';
import {
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCFactCapsule,
  NCFactCapsuleProps,
} from '@/components/NCFactCapsule/NCFactCapsule';
import { BenchmarkPill } from '@/components/Pill/BenchmarkPill';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useFact } from '@/hooks/useFact';
import { usePathSet } from '@/hooks/usePathSet';
import { useBenchmarkForFact, useBenchmarksForProject } from '@/pages/shared/hooks/useBenchmarkForFact';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';
import { formatFactKilogramsToTonnes } from '@/utils/formatting/formatKgToTon';

export const CarbonCardStatsTile = () => {
  return (
    <Stack className='gap-8 py-8 lg:gap-4' data-testid='carbon-stats-tile'>
      <BelowGroundCarbonStorage />
    </Stack>
  );
};

const BelowGroundCarbonStorage = () => {
  const { t } = useTranslation();
  const pathname = useLocation();
  const pathSet = usePathSet();
  const projectId = useProjectId();

  const { analysisType } = useNCCardContext();

  const carbonBgLatestAnalysis = formatFactKilogramsToTonnes(
    useProjectFact(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_latest_analysis_total
        : R1FactType.r1_carbon_bg_latest_analysis_per_ha,
    ),
  );

  const cbgBenchmarks = useBenchmarksForProject(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    kgToTonneFormatter,
  );
  const cbgBenchmarkType = useBenchmarkForFact(cbgBenchmarks, carbonBgLatestAnalysis?.value);

  const carbonBg1YearTrend = useProjectFact<number>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change
      : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha_pct_change,
  );
  let carbonBg1YearTrendDisplay = `${useDisplayNumber(carbonBg1YearTrend?.value ?? 0, { signDisplay: 'always' })} %`;
  carbonBg1YearTrendDisplay = carbonBg1YearTrend?.value != null ? carbonBg1YearTrendDisplay : '-';

  const carbonBg1yrChange = formatFactKilogramsToTonnes(
    useProjectFact(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_previous_1y_uplift_total
        : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha,
    ),
  );

  const equivalentExplainer = useEquivalentsExplainer();

  const latestAnalysisDate = printLatestDate([carbonBgLatestAnalysis?.measured_at]);

  const latestAnalysisBody = t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
    date: latestAnalysisDate,
  });

  const popoverCarbonBgLatestAnalysis: NCFactCapsuleProps['infoPopoverProps'] = equivalentExplainer
    ? {
        title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
        body: `${latestAnalysisBody}\n${equivalentExplainer}`,
        popoverTriggerProps: {
          'data-testid': 'carbon-bg-latest-analysis-popover',
        },
      }
    : undefined;

  const carbonBg1YrChangeInitialMeasuredAt = printLatestDate([carbonBgLatestAnalysis?.measured_at]);
  const carbonBg1YrChangePreviousMeasuredAt = printLatestDate([carbonBg1yrChange?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.carbon.labels.belowTheGroundCarbonStorage')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.latestAnalysis')} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.latestAnalysis')}
          fact={carbonBgLatestAnalysis}
          trend={
            typeof carbonBg1YearTrend?.value === 'number' && (
              <CapsuleTrend variant={carbonBg1YearTrend.value < 0 ? 'negative' : 'positive'}>
                {carbonBg1YearTrendDisplay}
              </CapsuleTrend>
            )
          }
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBgLatestAnalysis),
            variant: getNCChangeIconVariant(carbonBgLatestAnalysis),
          }}
          infoPopoverProps={popoverCarbonBgLatestAnalysis}
        />
        {!!carbonBg1yrChange?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.oneYearChange')}
            fact={carbonBg1yrChange}
            capsuleIconProps={{
              icon: getNCChangeIcon(carbonBg1yrChange),
              variant: getNCChangeIconVariant(carbonBg1yrChange),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.carbon.explainers.oneYearChange.title'),
              body: t('shared.ncaDetail.details.carbon.explainers.oneYearChange.body', {
                date: carbonBg1YrChangeInitialMeasuredAt,
                previousDate: carbonBg1YrChangePreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'carbon-1yr-change-tooltip',
              },
            }}
          />
        )}
      </Stack>
      {cbgBenchmarkType && (
        <Stack className='mt-4 gap-4 rounded-2xl bg-neutral-black-4 px-4 py-6'>
          <BenchmarkPill benchmarkType={cbgBenchmarkType} />
          <span className='typography-h4'>{t('shared.ncaDetail.details.carbon.explainers.cbgBenchmark.title')}</span>
          <Trans
            i18nKey={`shared.ncaDetail.details.carbon.explainers.cbgBenchmark.${cbgBenchmarkType}`}
            components={{
              p: <p className='typography-body1 text-text-secondary' />,
            }}
          />
          <ButtonLink
            to={buildPath(pathSet.impactProjections, { pathParams: { projectId } })}
            state={{
              previousPath: pathname,
            }}
            variant='outline'
            className='w-fit'
          >
            {t('shared.ncaDetail.details.carbon.explainers.cbgBenchmark.buttonLabel')}
          </ButtonLink>
        </Stack>
      )}
    </div>
  );
};

const useEquivalentsExplainer = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const carbonStorageBg = formatFactKilogramsToTonnes(
    useProjectFact(
      analysisType === 'total' ? R1FactType.r1_carbon_storage_bg_total : R1FactType.r1_carbon_storage_bg_per_ha,
    ),
  );
  const carbonStorageBgCo2Equivalent = formatFactKilogramsToTonnes(
    useProjectFact(
      analysisType === 'total'
        ? R1FactType.r1_carbon_storage_bg_co2_equivalent_total
        : R1FactType.r1_carbon_storage_bg_co2_equivalent_per_ha,
    ),
  );

  const { display: carbonStorageBgDisplay, value: carbonStorageBgValue } = useFact(carbonStorageBg);

  const { display: carbonStorageBgCo2EquivalentDisplay, value: carbonStorageBgCo2EquivalentValue } =
    useFact(carbonStorageBgCo2Equivalent);

  if (!carbonStorageBgValue || !carbonStorageBgCo2EquivalentValue) return undefined;

  return t('shared.ncaDetail.details.carbon.explainers.equivalents', {
    cValue: carbonStorageBgDisplay,
    co2Value: carbonStorageBgCo2EquivalentDisplay,
  });
};
