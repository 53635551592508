import { Stack } from '@mui/material';
import { ButtonLink } from 'component-library';
import { Text } from 'deprecated-component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OnboardingPageHeading, OnboardingPagePaper } from '@/layout/OnboardingPage';
import { paths } from '@/routing';

export const ResetPasswordSubmitted: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <OnboardingPageHeading dataCy='link-sent' headlineText={t('passwordReset.success.title')} />
      <OnboardingPagePaper>
        <Stack spacing={3}>
          <Text variant='body1' data-cy='link-sent-subtitle'>
            {t('passwordReset.success.subtitle')}
          </Text>
          <ButtonLink to={paths.login} size='medium' className='self-end' data-cy='link-sent-back-button'>
            {t('passwordReset.success.backButton')}
          </ButtonLink>
        </Stack>
      </OnboardingPagePaper>
    </>
  );
};
