import {
  ButtonLink,
  InfoPopover,
  RiArrowRightSLine,
  RiArrowRightUpLine,
  RiCoinLine,
  RiCompass4Line,
  RiDownloadLine,
  RiPriceTag3Line,
  Stack,
} from 'component-library';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { NCCard, NCCardHeader } from '@/components/NCCard/NCCard';
import { NatureUnitBalanceExplainer } from '@/components/NCExplainers/NatureUnitBalanceExplainer';
import { usePathSet } from '@/hooks/usePathSet';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';

import { FactCapsule, FactCapsuleTrend } from '../components/FactCapsule';
import { useFact } from '../hooks/useFact';
import { useMethodologyPageLink } from '../hooks/useMethodologyPageLink';
import { useNatureUnitVariant } from '../hooks/useNatureUnitVariant';
import { useStaticImage } from '../hooks/useStaticImage';

export const NcaPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const pathSet = usePathSet();
  const projectId = useProjectId();

  const natureUnitVariant = useNatureUnitVariant();

  const currentYear = useFact('r1_conservation_current_year');
  const natureUnitsCount = useFact('r1_conservation_no_nature_units');
  const ecologicalIntegrityAverageCurrent = useFact('r1_conservation_ecological_integrity');
  const ecologicalIntegrityHighest = useFact('r1_conservation_ecological_integrity_highest');
  const ecologicalIntegrityLowest = useFact('r1_conservation_ecological_integrity_lowest');
  const ecologicalIntegrityPctChange = useFact('r1_conservation_ecological_integrity_change_percentage');

  const ecologicalIntegrityMapImage = useStaticImage('ecological_integrity');

  const methodologyPageLink = useMethodologyPageLink();

  return (
    <Stack spacing={10}>
      <span className='typography-overline'>{t('shared.projects.labels.naturalCapitalAccount')}</span>
      <NCCard explainer={<NatureUnitBalanceExplainer />} data-testid='nature-unit-balance'>
        <NCCardHeader title={t('shared.ncaDetail.details.natureUnitBalance.title', { context: natureUnitVariant })} />

        <Stack spacing={10} className='w-full px-10 py-6 md:flex-row md:items-start [&>*]:flex-1'>
          <Stack spacing={6}>
            <Stack className='items-start rounded-2xl bg-bg-light-grey p-6' spacing={4}>
              <FactCapsule
                label={t('shared.ncaDetail.details.natureUnitBalance.labels.numberOfNatureUnits', {
                  context: natureUnitVariant,
                })}
                capsuleIconProps={{ icon: <RiCoinLine />, variant: 'default' }}
                fact={natureUnitsCount}
              />
              <Stack spacing={2} className='items-start'>
                <span className='typography-body1 text-text-secondary'>
                  {t('shared.ncaDetail.details.natureUnitBalance.explainers.numberOfNatureUnits.body', {
                    context: natureUnitVariant,
                  })}
                </span>
                <ButtonLink
                  to={methodologyPageLink}
                  target='_blank'
                  variant='text'
                  rightAdornment={<RiArrowRightUpLine />}
                  className='-left-5'
                >
                  {t('shared.learnMoreAboutMethodology')}
                </ButtonLink>
              </Stack>
            </Stack>

            <Stack spacing={2}>
              <span className='typography-body1'>
                {t('shared.ncaDetail.details.natureUnitBalance.labels.natureUnitValueFromEcologicalIntegrity')}
              </span>
              <Stack className='divide-y divide-dashed divide-divider px-6'>
                <div className='w-full py-6'>
                  <FactCapsule
                    label={t('shared.ncaDetail.details.natureUnitBalance.labels.avgValue', {
                      year: currentYear.value,
                    })}
                    capsuleIconProps={{ icon: <RiPriceTag3Line />, variant: 'default' }}
                    fact={ecologicalIntegrityAverageCurrent}
                    trend={<FactCapsuleTrend trend={ecologicalIntegrityPctChange} />}
                    infoPopoverProps={{
                      title: t('shared.ncaDetail.details.natureUnitBalance.explainers.averageValue.title', {
                        year: currentYear.value,
                      }),
                      body: t('shared.ncaDetail.details.natureUnitBalance.explainers.averageValue.body', {
                        year: currentYear.value,
                      }),
                    }}
                  />
                </div>

                <Stack direction='row' className='w-full flex-wrap gap-x-16 gap-y-6 py-6'>
                  <FactCapsule
                    label={t('shared.ncaDetail.details.natureUnitBalance.labels.highestValue')}
                    capsuleIconProps={{ icon: <RiDownloadLine className='rotate-180' />, variant: 'default' }}
                    fact={ecologicalIntegrityHighest}
                    infoPopoverProps={{
                      title: t('shared.ncaDetail.details.natureUnitBalance.explainers.highestValue.title'),
                      body: t('shared.ncaDetail.details.natureUnitBalance.explainers.highestValue.body'),
                    }}
                  />
                  <FactCapsule
                    label={t('shared.ncaDetail.details.natureUnitBalance.labels.lowestValue')}
                    capsuleIconProps={{ icon: <RiDownloadLine />, variant: 'default' }}
                    fact={ecologicalIntegrityLowest}
                    infoPopoverProps={{
                      title: t('shared.ncaDetail.details.natureUnitBalance.explainers.lowestValue.title'),
                      body: t('shared.ncaDetail.details.natureUnitBalance.explainers.lowestValue.body'),
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={6} className='rounded-2xl bg-bg-light-grey p-6'>
            <ChartHeading
              heading={t('shared.ncaDetail.details.ecologicalIntegrity.title')}
              popover={
                <InfoPopover
                  title={t('shared.ncaDetail.details.ecologicalIntegrity.explainers.ecologicalIntegrity.title')}
                  body={t('shared.ncaDetail.details.ecologicalIntegrity.explainers.ecologicalIntegrity.body')}
                  popoverTriggerProps={{
                    className: 'text-text-secondary',
                  }}
                />
              }
            />
            <Stack spacing={6} centerMain className='flex-col-reverse justify-between md:flex-row'>
              <Stack spacing={6}>
                <Stack spacing={10} className='py-3'>
                  <FactCapsule
                    label={t('shared.ncaDetail.details.ecologicalIntegrity.labels.projectAverageValue')}
                    capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
                    fact={ecologicalIntegrityAverageCurrent}
                    infoPopoverProps={{
                      title: t('shared.ncaDetail.details.ecologicalIntegrity.explainers.projectAverageValue.title'),
                      body: t('shared.ncaDetail.details.ecologicalIntegrity.explainers.projectAverageValue.body', {
                        year: currentYear.value,
                      }),
                    }}
                  />
                </Stack>
                <Stack spacing={2} className='p-2'>
                  <div className='h-3 w-full max-w-[222px] rounded-sm bg-gradient-to-r from-[#FFFACA] to-[#0C5326]' />
                  <Stack direction='row' className='justify-between'>
                    {[0.0, 0.2, 0.4, 0.6, 0.8, 1.0].map((num) => (
                      <span key={num} className='typography-caption text-text-secondary'>
                        {Intl.NumberFormat(window.navigator.language, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(num)}
                      </span>
                    ))}
                  </Stack>
                </Stack>
              </Stack>

              <img
                src={ecologicalIntegrityMapImage}
                className='max-h-[265px] min-w-0 max-w-full'
                alt={t('shared.ncaDetail.details.ecologicalIntegrity.labels.ecologicalIntegrityMap')}
              />
            </Stack>

            <Stack spacing={4}>
              <ButtonLink
                to={buildPath(pathSet.ecologicalIntegrity, { pathParams: { projectId } })}
                state={{ from: pathname }}
                variant='outline'
                rightAdornment={<RiArrowRightSLine size={20} />}
                className='self-start'
              >
                {t('shared.ncaDetail.details.ecologicalIntegrity.labels.seeMoreDetails')}
              </ButtonLink>

              <span className='typography-caption text-text-secondary'>
                {t('shared.ncaDetail.details.ecologicalIntegrity.explainers.ecologicalIntegrityScope')}
              </span>
            </Stack>
          </Stack>
        </Stack>
      </NCCard>
    </Stack>
  );
};
