import { PasswordInputProps, TextInput, TextInputProps } from 'deprecated-component-library';
import React from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';

export interface ControlledTextInputProps extends Omit<TextInputProps, 'label' | 'type' | 'value' | 'onChange'> {
  control: UseControllerProps<any>['control']; // eslint-disable-line @typescript-eslint/no-explicit-any
  defaultValue?: UseControllerProps<any>['defaultValue']; // eslint-disable-line @typescript-eslint/no-explicit-any
  name: string;
  id: string;
  label?: string;
  type?: string;
  rules?: UseControllerProps<any>['rules']; // eslint-disable-line @typescript-eslint/no-explicit-any
  component?: React.ComponentType<TextInputProps | PasswordInputProps>;
}

const constructLabel = (label?: string, required?: boolean) => {
  if (!label) return '';
  return required ? `${label}*` : label;
};

export const ControlledTextInput: React.FC<ControlledTextInputProps> = ({
  id,
  label,
  type = 'text',
  rules,
  name,
  control,
  defaultValue = '',
  component = TextInput,
  ...props
}) => {
  const Component = component;
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Component
          id={id}
          label={constructLabel(label, Boolean(rules?.required))}
          type={type}
          {...field}
          error={Boolean(error)}
          helperText={error?.message}
          {...props}
        />
      )}
    />
  );
};
