import { Slot } from '@radix-ui/react-slot';
import { TAILWIND_SCALE_MULTIPLIER } from 'component-library/constants';
import React, { HTMLAttributes } from 'react';

import { cn } from '../utils';

export type ContainerProps = HTMLAttributes<HTMLDivElement> & {
  /**
   * Max width of the content.
   * 
   * If the width is a number, it will use the Tailwind scale.
   * (for eg., 100 == `400px`).
   * If the width is one of 'sm' , 'md' , 'lg' , 'xl', the respective breakpoint
   * value will be used.
   * (for eg., `lg` == '1440px').
   * If the width is a string, it is used as-is.
   * (for eg., `768px` == `768px`).
   
   * Default 'lg' (1440px).
   */
  contentWidth?: 'sm' | 'md' | 'lg' | 'xl' | number | string;
  /**
   * Width of the left and right margins when the parent element's width is less
   * than `maxWidth`.
   *
   * If the width is a number, it will use the Tailwind scale
   * (for eg., 4 == `16px`).
   * If the width is a string, it is used as-is
   * (for eg., `13px` == `13px`).
   *
   *  Default `32px` for small screens and `48px` for large (>= 'md') screens.
   */
  gutterWidth?: { sm?: number | string; md?: number | string } | number | string;
  asChild?: boolean;
};

/**
 * Use this to center your content horizontally in a page while leaving a
 * responsive margin on both sides.
 */
export const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  ({ contentWidth: contentWidthProp, gutterWidth: gutterWidthProp, asChild, className, ...delegated }, ref) => {
    const contentWidth = resolveContentWidth(contentWidthProp);
    const gutterWidth = resolveGutterWidth(gutterWidthProp);

    const Component = asChild ? Slot : 'div';

    return (
      <Component
        ref={ref}
        style={
          {
            '--content-width': contentWidth,
            '--gutter-width-sm': `${gutterWidth.sm}`,
            '--gutter-width-md': `${gutterWidth.md}`,
          } as React.CSSProperties
        }
        className={cn(
          'grid w-full [&>*]:col-start-2',
          'grid-cols-[1fr_min(var(--content-width),_calc(100%_-_calc(var(--gutter-width-sm)_*_2)))_1fr] gap-x-[var(--gutter-width-sm)]',
          'md:grid-cols-[1fr_min(var(--content-width),_calc(100%_-_calc(var(--gutter-width-md)_*_2)))_1fr] md:gap-x-[var(--gutter-width-md)]',
          className,
        )}
        {...delegated}
      />
    );
  },
);
Container.displayName = 'Container';

export const resolveContentWidth = (contentWidth?: ContainerProps['contentWidth']) => {
  if (contentWidth == null) {
    return 'var(--bp-lg)';
  }

  if (typeof contentWidth === 'number') {
    return `${contentWidth * 4}px`;
  }

  if (['sm', 'md', 'lg', 'xl'].includes(contentWidth as string)) {
    return `var(--bp-${contentWidth})`;
  }

  return contentWidth;
};

export const resolveGutterWidth = (gutterWidth?: ContainerProps['gutterWidth']) => {
  if (typeof gutterWidth === 'string') {
    return {
      sm: gutterWidth,
      md: gutterWidth,
    };
  }

  if (typeof gutterWidth === 'number') {
    return {
      sm: `${gutterWidth * TAILWIND_SCALE_MULTIPLIER}px`,
      md: `${gutterWidth * TAILWIND_SCALE_MULTIPLIER}px`,
    };
  }

  let sm = gutterWidth?.sm ?? '32px';
  let md = gutterWidth?.md ?? '48px';

  if (typeof sm === 'number') {
    sm = `${sm * TAILWIND_SCALE_MULTIPLIER}px`;
  }

  if (typeof md === 'number') {
    md = `${md * TAILWIND_SCALE_MULTIPLIER}px`;
  }

  return { sm, md };
};
