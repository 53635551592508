import { cn, IconHalo, IconHaloProps, InfoPopover, InfoPopoverProps, Stack, StackProps } from 'component-library';
import React, { HTMLAttributes } from 'react';
import { IconContext } from 'react-icons';

import { useScreenSize } from '@/hooks/useScreenSize';

export type CapsuleProps = Omit<StackProps, 'children'> & {
  label?: React.ReactNode;
  content?: React.ReactNode;
  thumbnail?: React.ReactNode;
};

export const Capsule = React.forwardRef<HTMLDivElement, CapsuleProps>(
  ({ label, content, thumbnail, ...delegated }, ref) => {
    return (
      <Stack ref={ref} direction='row' centerMain spacing={4} {...delegated}>
        {thumbnail}
        <Stack spacing={2}>
          {label}
          {content}
        </Stack>
      </Stack>
    );
  },
);
Capsule.displayName = 'Capsule';

export type CapsuleLabelProps = HTMLAttributes<HTMLSpanElement> & {
  infoPopoverProps?: InfoPopoverProps | null;
};

export const CapsuleLabel = React.forwardRef<HTMLSpanElement, CapsuleLabelProps>(
  ({ infoPopoverProps, children, className, ...delegated }, ref) => {
    const isSmallScreen = useScreenSize() === 'small';
    const showPopover = (!!infoPopoverProps?.title || !!infoPopoverProps?.body) && !isSmallScreen;

    return (
      <Stack direction='row' centerMain spacing={1}>
        <span ref={ref} className={cn('typography-overline text-text-secondary', className)} {...delegated}>
          {children}
          {showPopover && (
            <div className='relative inline-block'>
              <div className='absolute -top-2.5 left-2'>
                <InfoPopover position='top' {...infoPopoverProps} />
              </div>
            </div>
          )}
        </span>
      </Stack>
    );
  },
);
CapsuleLabel.displayName = 'CapsuleLabel';

export type CapsuleContentProps = HTMLAttributes<HTMLSpanElement> & {
  trend?: React.ReactNode;
};

export const CapsuleContent = React.forwardRef<HTMLSpanElement, CapsuleContentProps>(
  ({ children, trend, className, ...delegated }, ref) => {
    return (
      <Stack direction='row' centerMain spacing={2}>
        <span ref={ref} className={cn('typography-h3 text-text-primary', className)} {...delegated}>
          {children}
        </span>
        {trend}
      </Stack>
    );
  },
);
CapsuleContent.displayName = 'CapsuleContent';

export type CapsuleIconProps = Omit<IconHaloProps, 'children'> & {
  icon?: React.ReactElement;
  /**
   * @default default
   */
  variant?: 'default' | 'contained' | 'disabled' | 'negative';
};

/**
 * Override the icon halo and icon colors using the
 * `bg-` and `text-` tailwind classes respectively.
 */
export const CapsuleIcon = ({ icon, variant = 'default', className, ...delegated }: CapsuleIconProps) => {
  return (
    <IconHalo
      className={cn(
        'bg-neutral-black-8',
        {
          'bg-primary-100': variant === 'contained',
        },
        {
          'text-primary-100': variant === 'default',
          'text-white-100': variant === 'contained',
          'text-neutral-black-12': variant === 'disabled',
          'text-error': variant === 'negative',
        },
        className,
      )}
      {...delegated}
    >
      <IconContext.Provider value={{ size: '24px' }}>{icon}</IconContext.Provider>
    </IconHalo>
  );
};

export type CapsuleTrendProps = HTMLAttributes<HTMLSpanElement> & {
  variant: 'positive' | 'negative';
};

export const CapsuleTrend = ({ variant, className, ...delegated }: CapsuleTrendProps) => {
  return (
    <span
      className={cn(
        'typography-button2',
        {
          'text-success': variant === 'positive',
          'text-error': variant === 'negative',
        },
        className,
      )}
      {...delegated}
    />
  );
};
