import { SidebarPageLayout } from 'deprecated-component-library';
import { Outlet } from 'react-router-dom';

import { useScreenSize } from '@/hooks/useScreenSize';
import { AccountSidebar } from '@/pages/shared/account/components';

import { LandStewardPageLayout } from '../../layouts/LandStewardPage.layout';

export const LandstewardAccountPageWrapper = () => {
  const isLargeScreen = useScreenSize() === 'large';

  return (
    <LandStewardPageLayout>
      <SidebarPageLayout sidebar={isLargeScreen && <AccountSidebar />}>
        <Outlet />
      </SidebarPageLayout>
    </LandStewardPageLayout>
  );
};
