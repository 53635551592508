import { RiLayout2Line, RiLineChartLine, RiMoneyEuroCircleLine } from 'component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuItemProps } from '@/components/SignedInNavbar/Menu';
import { paths } from '@/routing';

export const useMenuItems = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    const items: MenuItemProps[] = [
      {
        active: false,
        icon: <RiLayout2Line className='h-6 w-6' />,
        name: 'projects',
        'data-cy': 'projects-link',
        text: t('navbar.projects'),
        to: paths.landSteward.projects,
      },
      {
        active: false,
        icon: <RiMoneyEuroCircleLine className='h-6 w-6' />,
        name: 'investments',
        'data-cy': 'investments-link',
        text: t('navbar.investments'),
        to: paths.landSteward.investments,
      },
      {
        active: false,
        icon: <RiLineChartLine className='h-6 w-6' />,
        name: 'portfolio',
        'data-cy': 'portfolio-link',
        text: t('navbar.portfolio'),
        to: paths.landSteward.portfolio,
      },
    ];

    return items;
  }, [t]);
};
