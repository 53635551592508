import { RiLinksLine, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useLegalUrls } from '@/hooks/useLegalUrls';

import { Section, SectionContent, SectionHeading, SectionItem } from '../../../components/Section';

export const MobileView = () => {
  const { t } = useTranslation();

  const { privacyPolicyUrl, termsAndConditionsUrl } = useLegalUrls();

  return (
    <Stack spacing={6} data-testid='account-legal-page-mobile-view'>
      <Stack spacing={8} className='rounded-2xl bg-white-100 p-6'>
        <Section>
          <SectionHeading>{t('shared.account.titles.legalDocuments')}</SectionHeading>
          <SectionContent>
            <SectionItem
              label=''
              value={t('shared.account.labels.termsAndConditions')}
              ActionButton={
                <Link to={termsAndConditionsUrl} target='_blank' rel='noreferrer' data-testid='tc-page-button'>
                  <RiLinksLine className='text-text-primary' size={20} />
                </Link>
              }
            />
            <SectionItem
              label=''
              value={t('shared.account.labels.privacyPolicy')}
              ActionButton={
                <Link to={privacyPolicyUrl} target='_blank' rel='noreferrer' data-testid='pp-page-button'>
                  <RiLinksLine className='text-text-primary' size={20} />
                </Link>
              }
            />
          </SectionContent>
        </Section>
      </Stack>
    </Stack>
  );
};
