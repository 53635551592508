import { Stack } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { KycEnum } from '@/api/rest/resources/types/user';
import { HELP_EMAIL_ADDRESS } from '@/config/constants';
import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { useUser } from '@/lib/auth';
import { printFullName } from '@/utils/formatting/user';

import {
  ActionButton,
  EmailInformationBoxEntryStatus,
  EmailVerificationStatus,
  InformationBox,
  InformationBoxEntry,
  SelectLanguage,
} from '../../../components';
import { AccountKycStatus } from '../../../components/kyc/status/status';
import { AccountKycVerificationBox } from '../../../components/kyc/verification-box/VerificationBox';

export const DesktopView = () => {
  const { t } = useTranslation();

  const user = useUser();
  const { accountEditEmailPath, accountEditPasswordPath, accountEditNamePath } = useAccountPageRoutes();

  const companyName = user.membership.organization.name;
  const isUserLandSteward = user.membership.type === MembershipWithOrganizationTypeEnum.land_steward;
  const isKycSectionShown = isUserLandSteward && user.kyc !== KycEnum.success;
  const editButtonLabel = t('shared.account.buttons.edit');
  return (
    <Stack spacing={3} className='mx-auto' data-testid='account-page-desktop-view'>
      <InformationBox
        title={t('shared.account.titles.loginData')}
        data-cy='login-data'
        entries={[
          <InformationBoxEntry
            key='email'
            label={t('shared.account.labels.email')}
            value={user.email}
            status={
              <EmailInformationBoxEntryStatus
                status={user.email_activated ? EmailVerificationStatus.VERIFIED : EmailVerificationStatus.UNVERIFIED}
              />
            }
            ActionButton={
              <ActionButton label={editButtonLabel} link={accountEditEmailPath} dataCy='account-email-button' />
            }
            dataCy='email'
          />,
          <InformationBoxEntry
            key='password'
            label={t('shared.account.labels.password')}
            value='******'
            ActionButton={
              <ActionButton label={editButtonLabel} link={accountEditPasswordPath} dataCy='account-password-button' />
            }
            dataCy='password'
          />,
        ]}
      />
      <InformationBox
        title={t('shared.account.titles.personalData')}
        data-cy='personal-data'
        entries={[
          <InformationBoxEntry
            key='name'
            label={t('shared.account.labels.name')}
            value={printFullName(user)}
            ActionButton={
              <ActionButton
                label={editButtonLabel}
                link={accountEditNamePath}
                disabled={[KycEnum.in_progress, KycEnum.success].includes(user.kyc)}
                disabledHint={
                  user.kyc === KycEnum.in_progress
                    ? t('shared.account.popovers.editNameDisabledDueToKycInProgress')
                    : t('shared.account.popovers.editNameDisabledDueToKycSuccess')
                }
                dataCy='account-name-button'
              />
            }
            dataCy='name'
            status={isUserLandSteward ? <AccountKycStatus status={user.kyc} /> : undefined}
          />,
          <InformationBoxEntry
            key='company'
            label={t('shared.account.labels.company')}
            value={companyName}
            dataCy='company'
          />,
        ]}
      />
      {isKycSectionShown && <AccountKycVerificationBox status={user.kyc} />}
      <InformationBox
        title={t('shared.account.titles.settings')}
        data-cy='settings'
        entries={[<SelectLanguage key='select-language' />]}
      />
      <InformationBox
        title={t('shared.account.titles.customerSupport')}
        data-cy='support'
        entries={[
          <span key='customer-support' className='typography-body1' data-cy='account-support-text'>
            <Trans
              i18nKey='shared.account.labels.customerSupportDescription'
              values={{ email: HELP_EMAIL_ADDRESS }}
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a href={`mailto:${HELP_EMAIL_ADDRESS}`} />,
              }}
            />
          </span>,
        ]}
      />
    </Stack>
  );
};
