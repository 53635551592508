import { ButtonLink, cn } from 'component-library';
import React, { PropsWithChildren } from 'react';
import { LinkProps } from 'react-router-dom';

type NavbarItemProps = Pick<LinkProps, 'to' | 'target' | 'rel'> & {
  active: boolean;
};

export const NavbarItem: React.FC<PropsWithChildren<NavbarItemProps>> = ({ active, children, ...props }) => {
  const styles = cn(
    'border-b-2 min-h-[64px] rounded-none px-4 pt-4 hover:border-secondary-100 hover:text-primary-100 hover:bg-transparent',
    {
      'border-transparent text-text-secondary': !active,
      'border-secondary-100 text-primary-100': active,
    },
  );

  return (
    <ButtonLink variant='text' className={styles} {...props}>
      {children}
    </ButtonLink>
  );
};
