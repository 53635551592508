import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  GET_BUYER_DASHBOARD_PLOT_REPORT_PATH,
  GET_DASHBOARD_PLOT_REPORT_PATH,
  GetBuyerDashboardPlotReportErrorDto,
  GetBuyerDashboardPlotReportRequestQueryParams,
  GetBuyerDashboardPlotReportSuccessDto,
  GetDashboardPlotReportErrorDto,
  GetDashboardPlotReportRequestQueryParams,
  GetDashboardPlotReportSuccessDto,
} from '@/api/rest/resources/types/dashboard';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useMembershipType } from '@/hooks/useMembershipType';

type QueryParams = GetDashboardPlotReportRequestQueryParams | GetBuyerDashboardPlotReportRequestQueryParams;
type SuccessDto = GetDashboardPlotReportSuccessDto | GetBuyerDashboardPlotReportSuccessDto;
type ErrorDto = GetDashboardPlotReportErrorDto | GetBuyerDashboardPlotReportErrorDto;

export const usePlotReportForPlot = (plotId: string, queryParams?: QueryParams) => {
  const membershipType = useMembershipType();

  const pathVariables = { plot_id: plotId };

  const queryFn = async () => {
    return getRequest<SuccessDto>(
      membershipType === MembershipWithOrganizationTypeEnum.land_steward
        ? GET_DASHBOARD_PLOT_REPORT_PATH
        : GET_BUYER_DASHBOARD_PLOT_REPORT_PATH,
      pathVariables,
      queryParams,
    );
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'dashboard', 'plot-report', pathVariables, queryParams],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
