import { cn } from 'component-library/utils';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { useDisplayCurrency } from '@/hooks/useDisplayCurrency';
import { SignedInPageLayoutContentContainer } from '@/layout/signed-in-page/components';
import { Logger } from '@/lib/logs/logger';

import { useBuyerContract } from '../../../hooks/useBuyerContract';

export const Overview = () => {
  const { data } = useBuyerContract();

  const { t } = useTranslation();

  const investmentSum = data.results.reduce((acc, contract) => {
    if (contract.total_investment === undefined) {
      return acc;
    }

    if (Number.isNaN(Number(contract.total_investment))) {
      Logger.errorOnce(
        `Error while calculating total investment for contract of project ${contract.project}. ${contract.total_investment} is not a number.`,
      );

      return acc;
    }

    return acc + Number(contract.total_investment);
  }, 0);

  const totalInvestments = data.results.length;

  const formattedInvestmentSum = useDisplayCurrency(investmentSum);

  return (
    <div className='bg-white-100 full-bleed-x' data-testid='investments-overview' data-cy='investments-overview'>
      <SignedInPageLayoutContentContainer>
        <div className='flex: grid grid-cols-none flex-col gap-4 divide-y divide-divider lg:flex-none lg:grid-cols-12 lg:divide-x lg:divide-y-0'>
          <Widget
            label={t('buyer.investments.labels.totalInvestment')}
            data-cy='investments-overview-sum'
            value={formattedInvestmentSum}
          />
          <Widget
            label={t('buyer.investments.labels.investments')}
            data-cy='investments-overview-count'
            value={totalInvestments.toString()}
            className='pt-4 lg:pl-4 lg:pt-0'
          />
        </div>
      </SignedInPageLayoutContentContainer>
    </div>
  );
};

type WidgetProps = HTMLAttributes<HTMLDivElement> & {
  label: string;
  value: string;
};

const Widget: FC<WidgetProps> = ({ label, value, className, ...delegated }) => {
  const styles = cn(
    'col-span-full flex flex-row gap-10 lg:col-span-3 lg:flex-col justify-between items-center lg:items-start',
    className,
  );

  return (
    <div className={styles} {...delegated}>
      <span className='typography-overline text-text-secondary' data-cy='widget-label'>
        {label}
      </span>
      <span className='typography-h2 text-right lg:text-left' data-cy='widget-value'>
        {value}
      </span>
    </div>
  );
};
