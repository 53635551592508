import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { BenchmarkType, PlotReportFactLabelNameEnum, R1FactType } from '@/api/rest/resources/types/fact';
import { CarbonStorageBGHistoricalChart } from '@/components/Charts';
import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import { GraphFact } from '@/components/Charts/types';
import { kgToTonneFormatter } from '@/components/Charts/utils';
import { useNCCardContext } from '@/components/NCCard/NCCard';
import { useBenchmarksForPlot } from '@/pages/shared/hooks/useBenchmarkForFact';
import { usePlotFact } from '@/pages/shared/hooks/usePlotFact';

import { NoPlotData } from '../NoPlotData';

export const CarbonGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const carbonBgHistorical = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_soil_carbon_historic_total_graph
      : R1FactType.r1_soil_carbon_historic_per_ha_graph,
  );
  const carbonbgHistoricalData = carbonBgHistorical?.value;

  const carbonBgLowBenchmark = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    { [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.low },
  );
  const cbgLowBenchmarkData = (carbonBgLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const carbonBgModerateBenchmark = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.moderate,
    },
  );
  const cbgModerateBenchmarkData = (carbonBgModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const carbonHighBenchmark = usePlotFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    {
      [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.high,
    },
  );
  const cbgHighBenchmarkData = (carbonHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const cbgBenchmarks = useBenchmarksForPlot(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    kgToTonneFormatter,
  );

  const noChartData = !carbonbgHistoricalData?.length;
  if (noChartData) {
    return <NoPlotData />;
  }

  const chartData = [
    ...carbonbgHistoricalData,
    ...cbgLowBenchmarkData,
    ...cbgModerateBenchmarkData,
    ...cbgHighBenchmarkData,
  ];

  return (
    <Stack
      spacing={6}
      className='items-center justify-center px-1 py-6 lg:items-start lg:p-8 lg:pl-1'
      data-testid='carbon-graph-tile'
    >
      <ChartHeading
        heading={t('shared.ncaDetail.details.carbon.labels.historicalGraphHeadline')}
        popover={{
          // TODO: MVP-3284 Confirm if the popover copy is correct
          title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
        }}
      />
      <CarbonStorageBGHistoricalChart
        analysisType={analysisType}
        data={chartData}
        benchmarks={cbgBenchmarks}
        showTooltip={true}
        className='relative'
        data-testid='carbon-storage-bg-chart'
      />
    </Stack>
  );
};
