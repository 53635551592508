import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { paths } from '@/routing';

export const PasswordResetHelperText = React.forwardRef<HTMLDivElement, { helperText?: ReactNode }>(
  ({ helperText }, ref) => {
    const { t } = useTranslation();

    return (
      <span ref={ref}>
        {helperText && <p className='mb-2'>{helperText}. </p>}
        <span data-cy='forgot-password-text'>{`${t('login.forgotPassword')} `}</span>
        <Link to={paths.resetPassword} color='inherit' data-cy='forgot-password-link' className='underline'>
          {t('login.forgotPasswordLink')}
        </Link>
      </span>
    );
  },
);
PasswordResetHelperText.displayName = 'PasswordResetHelperText';
