import {
  Button,
  ButtonLink,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  RiCheckDoubleLine,
  RiUserAddLine,
} from 'component-library';
import { FC } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';

import { InviteInputs } from '../hooks/useSendInvite';

type SuccessDialogContentProps = {
  form: UseFormReturn<InviteInputs>;
};

export const SuccessDialogContent: FC<SuccessDialogContentProps> = ({ form }) => {
  const { t } = useTranslation();
  const { accountTeamPath } = useAccountPageRoutes();

  const formValues = form.getValues();
  const inviteeName = `${formValues.user.firstname} ${formValues.user.lastname}`;

  return (
    <DialogContent>
      <DialogHeader>
        <RiCheckDoubleLine size={82} className='self-center text-secondary-100' />
        <DialogTitle className='text-secondary-100'>{t('shared.account.teamInvite.successDialog.title')}</DialogTitle>
        <DialogDescription className='whitespace-pre-line'>
          <Trans
            i18nKey='shared.account.teamInvite.successDialog.description'
            values={{ name: `${inviteeName}` }}
            components={{
              strong: <span className='typography-body1Semibold' />,
            }}
          />
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <ButtonLink variant='outline' to={accountTeamPath}>
          {t('shared.account.teamInvite.successDialog.backButton')}
        </ButtonLink>
        <DialogClose asChild>
          <Button leftAdornment={<RiUserAddLine size={16} />} onClick={() => form.reset()}>
            {t('shared.account.teamInvite.successDialog.submitButton')}
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};
