import { Box } from '@mui/material';
import { Loader } from 'component-library';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NewPasswordPageContent } from '../page-content/PageContent';

export const NewPasswordLoading: React.FC = () => {
  const { t } = useTranslation();

  return (
    <NewPasswordPageContent headlineText={t('newPassword.title')}>
      <Box>
        <Loader />
      </Box>
    </NewPasswordPageContent>
  );
};
