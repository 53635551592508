import { Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { ScheduleMeeting } from '../schedule-meeting/ScheduleMeeting';

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <Stack
      spacing={8}
      className='max-w-5xl rounded-3xl bg-white-100 p-6 lg:p-10'
      data-testid='portfolio-hero'
      data-cy='portfolio-hero'
    >
      <Stack spacing={4}>
        <span className='typography-display2 text-primary-100' data-cy='portfolio-hero-title'>
          {t('buyer.portfolio.hero.title')}
        </span>
        <span className='typography-h4 whitespace-pre-line text-text-secondary' data-cy='portfolio-hero-subtitle'>
          {t('buyer.portfolio.hero.subtitle')}
        </span>
      </Stack>
      <ScheduleMeeting className='bg-neutral-black-4' />
    </Stack>
  );
};
