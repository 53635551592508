import {
  ButtonLink,
  ButtonLinkProps,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  IconButton,
  RiArrowLeftLine,
  RiMore2Fill,
  Stack,
} from 'component-library';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useScreenSize } from '@/hooks/useScreenSize';
import { isReactNode } from '@/utils/isReactNode';

type SecondaryNavPropsBase = {
  /** The title of the SecondaryNav element. */
  title: string;
  /** The action of the SecondaryNav element. */
  action?: ReactNode | ButtonLinkProps;
};

type SecondaryNavPropsWithBackPath = SecondaryNavPropsBase & {
  /** The path the back icon will link to. */
  backPath?: string;
};

type SecondaryNavPropsWithBackAction = SecondaryNavPropsBase & {
  /** The callback function to invoke when the back icon is clicked. */
  backAction?: () => void;
};

export type SecondaryNavProps = SecondaryNavPropsWithBackPath | SecondaryNavPropsWithBackAction;

export const SecondaryNav: FC<PropsWithChildren<SecondaryNavProps>> = ({ title, action, ...rest }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const restProps = rest as any;

  const { state } = useLocation();

  const hasBackButton = restProps.backPath || restProps.backAction;

  return (
    <Stack
      direction='row'
      spacing={4}
      className='min-h-[64px] items-center justify-between bg-white-100 px-4 py-2 md:px-6'
      data-testid='secondary-nav'
      data-cy='secondary-nav'
    >
      <Stack direction='row' spacing={2} className='min-w-0 items-center'>
        {hasBackButton && (
          <SecondaryNavBackButton
            href={state?.previousPath ?? state?.from ?? restProps.backPath}
            onClick={restProps.backAction}
          />
        )}
        <span className='typography-h3 truncate text-text-secondary' data-cy='title'>
          {title}
        </span>
      </Stack>
      {action && (isReactNode(action) ? action : <SecondaryNavActionButton {...action} />)}
    </Stack>
  );
};

const SecondaryNavBackButton: FC<{ href?: string; onClick?: () => void }> = ({ href, onClick }) => {
  const icon = <RiArrowLeftLine className='text-text-secondary' size={24} />;

  return (
    <IconButton onClick={onClick} data-testid='secondary-nav-back-button' data-cy='back-button' asChild={!!href}>
      {href ? (
        <Link role='link' data-testid='back-link' to={href}>
          {icon}
        </Link>
      ) : (
        icon
      )}
    </IconButton>
  );
};

const SecondaryNavActionButton: FC<ButtonLinkProps> = ({ children, to, ...delegated }) => {
  const isLargeScreen = useScreenSize() === 'large';

  return isLargeScreen ? (
    <ButtonLink to={to} data-testid='secondary-nav-action-desktop' {...delegated}>
      {children}
    </ButtonLink>
  ) : (
    <Dropdown>
      <DropdownTrigger asChild>
        <IconButton className='border text-primary-100' data-testid='secondary-nav-action-mobile'>
          <RiMore2Fill size={24} />
        </IconButton>
      </DropdownTrigger>
      <DropdownContent align='end'>
        <DropdownItem>
          <Link to={to}>{children}</Link>
        </DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};
