import MediaIcon from '@assets/images/icons/media.png';
import { featureCollection, point } from '@turf/turf';
import { cn, Image, RiExternalLinkLine, Stack } from 'component-library';
import { Feature, Geometry, Point } from 'geojson';
import { Expression, GeoJSONSource, LngLatLike, MapLayerMouseEvent, Point as MapboxPoint } from 'mapbox-gl';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Layer, LayerProps, Popup, PopupProps, Source, useMap } from 'react-map-gl';

import { Observation, SpeciesGroupId } from '@/api/rest/resources/conservation';
import { MaybeLink } from '@/components';
import { useEffectOnce } from '@/hooks/useEffectOnce';

import { useObservation } from '../../../hooks/useObservation';
import { useProject } from '../../../hooks/useProject';
import { useTilesetsByLayer } from '../../../hooks/useTilesets';
import {
  CLUSTER_COUNT_LAYER_ID,
  CLUSTER_LAYER_ID,
  COLORS,
  OBSERVATION_MARKERS_SOURCE_ID,
  observationMarkerLayers,
  UNCLUSTERED_POINT_ICON_LAYER_ID,
  UNCLUSTERED_POINT_LAYER_ID,
} from '../constants';
import { useControlsContext } from '../hooks/useControlsForm';
import { useMoveInteractiveLayersToTop } from '../hooks/useInteractiveLayers';
import { BinaryLegend, Legend } from './Legend';

export const FloraAndFaunaLayers = () => {
  return (
    <>
      <SpeciesPresenceLayer />
      <ObservationPoints />
    </>
  );
};

const SOURCE_ID = 'species-presence-source';
const LAYER_ID = 'species-presence-layer';

const SpeciesPresenceLayer = () => {
  const { t } = useTranslation();
  const controlsForm = useControlsContext();

  const [year, speciesFilter] = controlsForm.watch(['year', 'speciesFilter']);

  const project = useProject().data;

  const tilesetsByLayer = useTilesetsByLayer().data;
  const tileset = tilesetsByLayer.species_presence?.[year as string];

  useMoveInteractiveLayersToTop(SOURCE_ID);

  if (!tileset) {
    return null;
  }

  const fillColor = (() => {
    if (!speciesFilter) {
      return 'transparent';
    }

    return [
      'case',
      ['==', ['get', 'group'], speciesFilter],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, security/detect-object-injection
      (COLORS as any)[speciesFilter]?.binary,
      'transparent',
    ] as Expression;
  })();

  return (
    <>
      <Source
        key={tileset.id} // Force load tilesets
        id={SOURCE_ID}
        type='vector'
        url={`mapbox://${tileset.id}`}
      >
        <Layer
          id={LAYER_ID}
          type='fill'
          source={SOURCE_ID}
          source-layer={tileset.sourceLayer}
          layout={{
            'fill-sort-key': 0,
          }}
          paint={{
            'fill-color': fillColor,
            'fill-opacity': 0.4,
          }}
        />
      </Source>

      {speciesFilter && (
        <Legend>
          <BinaryLegend
            title={t('shared.projects.project.conservation.interactiveMap.labels.floraFauna')}
            layers={[
              {
                label: project.speciesGroups[speciesFilter as SpeciesGroupId] ?? '',
                // eslint-disable-next-line security/detect-object-injection, @typescript-eslint/no-explicit-any
                color: (COLORS as any)[speciesFilter].binary as string,
              },
            ]}
            infoPopoverProps={{
              title: t('shared.projects.project.conservation.interactiveMap.labels.floraFauna'),
              body: t('shared.projects.project.conservation.interactiveMap.explainers.floraFauna'),
            }}
          />
        </Legend>
      )}
    </>
  );
};

const MEDIA_ICON_IMAGE = 'media-icon';

type ObservationFeature = Feature<Geometry, Observation>;

const ObservationPoints = () => {
  const controlsForm = useControlsContext();

  const [speciesFilter] = controlsForm.watch(['speciesFilter']);

  const observation = useObservation().data;
  const observationFiltered = speciesFilter
    ? observation.filter((o) => o.indicator_group === speciesFilter)
    : observation;

  const [selectedPoint, setSelectedPoint] = useState<ObservationFeature | null>(null);

  const mapRef = useMap();

  /**
   * Prevents a warning from getting logged to the console when the effect is
   * executed more than once causing the `addImage` method to be invoked
   * multiple times.
   */
  useEffectOnce(
    // eslint-disable-next-line prefer-arrow-callback
    function loadPinIcon() {
      const map = mapRef.current;

      if (!map) {
        return;
      }

      if (!map.hasImage?.(MEDIA_ICON_IMAGE)) {
        map.loadImage?.(MediaIcon, (error, image) => {
          if (error || image === undefined) throw error;
          map.addImage(MEDIA_ICON_IMAGE, image, { sdf: true });
        });
      }
    },
    [],
  );

  /**
   * If a cluster is clicked, explode the cluster and zoom in.
   * If a marker is clicked, show the details popup.
   */
  const handleMapClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const feature = event.features?.[0] as Feature<Point> | undefined;
      const map = event.target;

      if (!feature) {
        return;
      }

      /**
       * Explode the cluster and zoom in when a cluster is clicked.
       */
      const handleClusterMarkerClick = (clusterId: number) => {
        const mapboxSource = map.getSource(OBSERVATION_MARKERS_SOURCE_ID) as GeoJSONSource;

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          map.easeTo({
            center: feature.geometry.coordinates as LngLatLike,
            zoom,
            duration: 500,
          });
        });
      };

      /**
       * Show a details popup when a marker is clicked.
       */
      const handleUnclusteredMarkerClick = () => {
        const markerPoint = feature as ObservationFeature | undefined;

        if (!markerPoint) {
          return null;
        }

        if (selectedPoint != null) {
          map.setFeatureState({ source: OBSERVATION_MARKERS_SOURCE_ID, id: selectedPoint.id }, { active: false });

          if (selectedPoint.id === markerPoint.id) {
            return setSelectedPoint(null);
          }
        }

        /**
         * We do this song and dance so that the popup is first torn-down
         * and then created afresh. Without this, the popup does not update
         * when a different plot is clicked while the popup is open.
         */
        setSelectedPoint(null);
        setTimeout(() => {
          setSelectedPoint(markerPoint);
        }, 0);

        map.setFeatureState({ source: OBSERVATION_MARKERS_SOURCE_ID, id: markerPoint.id }, { active: true });

        map.easeTo({
          center: feature.geometry.coordinates as LngLatLike,
          duration: 500,
          offset: new MapboxPoint(-200, 0),
        });

        return null;
      };

      const clusterId = feature.properties?.cluster_id;

      if (clusterId) {
        handleClusterMarkerClick(clusterId);
      } else {
        handleUnclusteredMarkerClick();
      }
    },
    [selectedPoint],
  );

  const hoveredPointIdRef = useRef<number | null>(null);

  const handleMapMouseMove = useCallback((event: MapLayerMouseEvent) => {
    const map = event.target;

    if (!map) return;

    const feature = event.features?.[0];

    const featureId = feature?.id;

    if (!featureId) {
      return;
    }

    if (hoveredPointIdRef.current !== null) {
      map.setFeatureState(
        { source: OBSERVATION_MARKERS_SOURCE_ID, id: hoveredPointIdRef.current as number },
        { hover: false },
      );
    }

    hoveredPointIdRef.current = featureId as number;
    map.setFeatureState(
      { source: OBSERVATION_MARKERS_SOURCE_ID, id: hoveredPointIdRef.current as number },
      { hover: true },
    );
  }, []);

  const handleMapMouseLeave = useCallback((event: MapLayerMouseEvent) => {
    const map = event.target;

    if (!map) return;

    if (hoveredPointIdRef.current !== null) {
      map.setFeatureState(
        { source: OBSERVATION_MARKERS_SOURCE_ID, id: hoveredPointIdRef.current as number },
        { hover: false },
      );
      hoveredPointIdRef.current = null;
    }
  }, []);

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function addHoverHandler() {
      const map = mapRef.current;

      if (!map) {
        return undefined;
      }

      map.on('click', observationMarkerLayers, handleMapClick);
      map.on('mousemove', observationMarkerLayers, handleMapMouseMove);
      map.on('mouseleave', observationMarkerLayers, handleMapMouseLeave);

      return () => {
        map.off('click', observationMarkerLayers, handleMapClick);
        map.off('mousemove', observationMarkerLayers, handleMapMouseMove);
        map.off('mouseleave', observationMarkerLayers, handleMapMouseLeave);
      };
    },
    [mapRef, handleMapMouseMove, handleMapMouseLeave, handleMapClick],
  );

  const markers = featureCollection(
    observationFiltered.map((o) => point([o.decimalLongitude, o.decimalLatitude], o, { id: o.id })),
  );

  const handlePopupClose = () => {
    mapRef.current?.setFeatureState(
      { source: OBSERVATION_MARKERS_SOURCE_ID, id: selectedPoint?.id },
      { active: false },
    );

    setSelectedPoint(null);
  };

  return (
    <>
      <Source id={OBSERVATION_MARKERS_SOURCE_ID} type='geojson' data={markers} cluster={true}>
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...unclusteredPointLayer} />
        <Layer {...iconLayer} />
      </Source>

      {selectedPoint && <ObservationPopup observationFeature={selectedPoint} onClose={handlePopupClose} />}
    </>
  );
};

const clusterLayer: LayerProps = {
  id: CLUSTER_LAYER_ID,
  type: 'circle',

  source: OBSERVATION_MARKERS_SOURCE_ID,
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': [
      'case',
      // eslint-disable-next-line sonarjs/no-duplicate-string
      ['boolean', ['feature-state', 'hover'], false],
      '#F3EDD3',
      '#ffffff',
    ],
    'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1.0, 0.8],
    'circle-stroke-width': 15,
    'circle-stroke-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#F3EDD3',
      ['boolean', ['feature-state', 'active'], false],
      '#F3EDD3',
      '#ffffff',
    ],
    'circle-stroke-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.6, 0.4],
    'circle-radius': 20,
  },
};

const clusterCountLayer: LayerProps = {
  id: CLUSTER_COUNT_LAYER_ID,
  type: 'symbol',
  source: OBSERVATION_MARKERS_SOURCE_ID,
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 12,
  },
};

const unclusteredPointLayer: LayerProps = {
  id: UNCLUSTERED_POINT_LAYER_ID,
  type: 'circle',
  source: OBSERVATION_MARKERS_SOURCE_ID,
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#F3EDD3',
      ['boolean', ['feature-state', 'active'], false],
      '#F3EDD3',
      '#ffffff',
    ],
    'circle-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      1.0,
      ['boolean', ['feature-state', 'active'], false],
      1.0,
      0.8,
    ],
    'circle-stroke-width': 10,
    'circle-stroke-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#F3EDD3',
      ['boolean', ['feature-state', 'active'], false],
      '#F3EDD3',
      '#ffffff',
    ],
    'circle-stroke-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.6,
      ['boolean', ['feature-state', 'active'], false],
      0.6,
      0.4,
    ],
    'circle-radius': ['case', ['boolean', ['feature-state', 'active'], false], 17, 15],
  },
};

const iconLayer: LayerProps = {
  id: UNCLUSTERED_POINT_ICON_LAYER_ID,
  type: 'symbol',
  source: OBSERVATION_MARKERS_SOURCE_ID,
  filter: ['!', ['has', 'point_count']],
  layout: {
    'icon-image': MEDIA_ICON_IMAGE,
    'icon-size': 0.3,
    'text-field': ['get', 'name'],
    'text-offset': [0, 3],
    'text-anchor': 'bottom',
    'text-size': 12,
  },
};

const ObservationPopup = ({
  observationFeature: { properties: observation },
  ...delegated
}: Omit<PopupProps, 'longitude' | 'latitude' | 'children'> & { observationFeature: ObservationFeature }) => {
  const { t } = useTranslation();

  const dateDisplay = !observation.eventDate
    ? null
    : new Date(observation.eventDate).toLocaleDateString(window.navigator.language, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });

  return (
    <Popup
      anchor='left'
      data-testid='observation-popup'
      longitude={observation.decimalLongitude}
      latitude={observation.decimalLatitude}
      closeButton={false}
      maxWidth='none'
      offset={12}
      {...delegated}
    >
      <Stack
        data-testid='observation-popup-content'
        className='relative items-center animate-in fade-in slide-in-from-left-2'
        style={{ '--popup-content-translate-y': '-0.75rem' } as React.CSSProperties}
      >
        {/* Pointer arrow */}
        <div className='absolute left-0 h-6 w-6 translate-x-4 translate-y-[var(--popup-content-translate-y)] rotate-45 rounded bg-white-100' />

        <Stack
          className={cn(
            'absolute left-6 w-[370px] max-w-[90vw] -translate-y-1/2 items-stretch',
            'rounded-lg bg-white-100 p-4',
          )}
          spacing={3}
        >
          {observation.image_url && (
            <MaybeLink to={observation.references} target='_blank'>
              <Image
                src={observation.image_url}
                alt={observation.species}
                className='aspect-video w-full rounded-[6px] object-cover'
              />
            </MaybeLink>
          )}

          <Stack spacing={4}>
            <MaybeLink
              to={observation.references}
              target='_blank'
              className='group/link relative rounded-md px-3 pb-2 pt-3 transition-colors duration-100 hover:bg-neutral-hover'
            >
              <InfoStack
                label={t('shared.projects.project.conservation.interactiveMap.labels.speciesName')}
                info={observation.species}
              />
              <RiExternalLinkLine
                size={20}
                className={cn(
                  'absolute right-3 top-1/2 -translate-y-1/2',
                  'text-text-disabled opacity-0 transition-opacity duration-100 group-hover/link:opacity-100',
                )}
              />
            </MaybeLink>

            <Stack direction='row' spacing={6} className='px-3'>
              {dateDisplay && (
                <InfoStack
                  label={t('shared.projects.project.conservation.interactiveMap.labels.timeOfSighting')}
                  info={dateDisplay}
                />
              )}
              <InfoStack
                label={t('shared.projects.project.conservation.interactiveMap.labels.modeOfSighting')}
                info={t(
                  `shared.projects.project.conservation.interactiveMap.labels.${observation.source}`,
                  observation.source,
                )}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Popup>
  );
};

const InfoStack = ({ label, info }: { label: React.ReactNode; info: React.ReactNode }) => {
  return (
    <Stack spacing={2}>
      <span className='typography-overline text-text-secondary'>{label}</span>
      <span className='typography-body1'>{info}</span>
    </Stack>
  );
};
