import 'mapbox-gl/dist/mapbox-gl.css';

import bbox from '@turf/bbox';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import { cn, Spinner, Stack } from 'component-library';
import { PropsWithChildren, useMemo, useState } from 'react';
import { AttributionControl, LngLatBoundsLike, Map as MapGL, MapProps, NavigationControl } from 'react-map-gl';

import { Plot } from '@/api/rest/resources/types/plot';
import { Footer, getMapPadding, MapPlotLayerWithMarker } from '@/components';
import { DEFAULT_MAP_STYLE, MAPBOX_TOKEN } from '@/config/constants';
import { useScreenSize } from '@/hooks/useScreenSize';
import { Logger } from '@/lib/logs/logger';
import { getBoundsForPlots } from '@/utils/plot';

import { usePlotForm } from '../../hooks/usePlotForm';
import { DrawPolygon, DrawPolygonProps } from './components/drawPolygon/DrawPolygon';

export type PlotBoundaryEditorProps = PropsWithChildren<Omit<MapProps, 'fog' | 'terrain'>> & {
  existingPlots: Plot[];
  ForwardButton: DrawPolygonProps['ForwardButton'];
  BackButton: DrawPolygonProps['BackButton'];
};

export const PlotBoundaryEditor = ({
  existingPlots,
  children,
  ForwardButton,
  BackButton,
  ...delegated
}: PlotBoundaryEditorProps) => {
  const isSmallScreen = useScreenSize() === 'small';

  const [isMapReady, setIsMapReady] = useState(false);

  const form = usePlotForm();
  const [polygon] = form.watch(['polygon']);

  const initialViewState: MapProps['initialViewState'] = useMemo(() => {
    const completeWorldMap = {
      bounds: [-170, -60, 170, 60] as BBox2d,
    };

    if (polygon) {
      return {
        bounds: bbox(polygon) as LngLatBoundsLike,
        fitBoundsOptions: { padding: getMapPadding() },
      };
    }

    if (!existingPlots.length) {
      return completeWorldMap;
    }

    try {
      return {
        bounds: getBoundsForPlots(existingPlots),
        fitBoundsOptions: { padding: getMapPadding() },
      };
    } catch (error) {
      Logger.errorOnce(`Failed to calculate bounds`);
      Logger.errorOnce(error as Error);

      return completeWorldMap;
    }
  }, [existingPlots, polygon]);

  return (
    <div className='relative h-full w-full' data-testid='plot-boundary-editor'>
      <Stack className='absolute flex h-full w-full items-center justify-center'>
        {!isMapReady && <Spinner size={10} />}
      </Stack>
      <MapGL
        initialViewState={initialViewState}
        onLoad={() => setIsMapReady(true)}
        style={{
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.10)',
          opacity: isMapReady ? 1 : 0,
          transition: 'opacity 0.2s',
        }}
        attributionControl={false}
        preserveDrawingBuffer
        dragRotate={false}
        touchPitch={false}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle={DEFAULT_MAP_STYLE}
        {...delegated}
      >
        <DrawPolygon ForwardButton={ForwardButton} BackButton={BackButton} existingPlots={existingPlots} />

        {existingPlots.map((plot) => (
          <MapPlotLayerWithMarker key={plot.id} plot={plot} withIcon withName />
        ))}

        {!isSmallScreen && (
          <NavigationControl position='bottom-left' showCompass={false} style={{ bottom: 30, position: 'absolute' }} />
        )}
        <AttributionControl compact />

        {children}

        <Footer
          variant='white'
          className={cn(
            'absolute bottom-0 left-0 right-0 hidden pb-2 pl-28 pt-4 md:block',
            'bg-gradient-to-t from-neutral-black-60 to-transparent',
          )}
        />
      </MapGL>
    </div>
  );
};
