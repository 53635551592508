import { Stack } from '@mui/material';
import { toast } from 'component-library';
import { PasswordInput, Text } from 'deprecated-component-library';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { usePostUserMeChangeMailRequest } from '@/api/rest/resources/user';
import { ControlledTextInput } from '@/components/Form/ControlledTextInput';
import { useUser } from '@/lib/auth';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';
import { validateEmail } from '@/utils/validators';

import { EditContainerLayout, EditContainerLayoutBox } from '../../../../components';

interface Inputs {
  email: string;
  password: string;
}

// NOTE: The form field names must match the api param names to make useRestApiServerErrorHandling work
const formFields: (keyof Inputs)[] = ['email', 'password'];

export const AccountEditEmailForm: React.FC = () => {
  const { t } = useTranslation();
  const { email } = useUser();
  const { control, formState, handleSubmit, setError, reset } = useForm<Inputs>();

  const handleServerError = useRestApiServerErrorHandling(setError, formFields);
  const { isPending, mutate } = usePostUserMeChangeMailRequest();

  const areAllInputsFilled = Object.keys(formState.dirtyFields).length === formFields.length;

  const handleSave: SubmitHandler<Inputs> = (data) => {
    mutate(
      { bodyData: { email: data.email, password: data.password } },
      {
        onSuccess: () => {
          reset();

          toast({
            title: t('shared.account.editEmail.requestSucceeded'),
            type: 'success',
          });
        },
        onError: handleServerError,
      },
    );
  };

  return (
    <EditContainerLayout
      isSubmitting={isPending}
      onSave={handleSubmit(handleSave)}
      isSaveButtonDisabled={!areAllInputsFilled}
      saveButtonLabel={t('shared.account.editEmail.buttons.submit')}
      title={t('shared.account.editEmail.title')}
      dataCy='edit-email'
    >
      <EditContainerLayoutBox>
        <Stack spacing={4} maxWidth='480px'>
          <Stack spacing={0.75}>
            <Text variant='caption' data-cy='edit-email-current-email-label'>
              {t('shared.account.editEmail.labels.currentEmail')}
            </Text>
            <Text variant='body1' data-cy='edit-email-current-email'>
              {email}
            </Text>
          </Stack>
          <Stack spacing={2}>
            <ControlledTextInput
              id='email'
              name='email'
              type='email'
              label={t('shared.account.editEmail.labels.newEmail')}
              control={control}
              rules={{
                required: t('global.ui.form.input.required'),
                validate: (value) => validateEmail(value) || t('global.ui.form.input.emailInvalid'),
              }}
              data-cy='edit-email-new-email-container'
            />
            <ControlledTextInput
              id='password'
              name='password'
              label={t('shared.account.labels.password')}
              control={control}
              component={PasswordInput}
              rules={{
                required: t('global.ui.form.input.required'),
              }}
              data-cy='edit-email-current-password-container'
            />
          </Stack>
          <Text color='text.secondary' variant='helper' data-cy='edit-email-helper-text'>
            {t('shared.account.editEmail.info')}
          </Text>
        </Stack>
      </EditContainerLayoutBox>
    </EditContainerLayout>
  );
};
