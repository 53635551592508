import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  AlertBody,
  AlertIcon,
  AppBar,
  AppBarContent,
  Button,
  cn,
  Container,
  RiErrorWarningLine,
  RiFileUploadLine,
  Stack,
} from 'component-library';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { usePostDraftPlot } from '@/api/rest/resources/plot';
import { Footer } from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';
import { Logger } from '@/lib/logs/logger';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';
import { isRestApiServerError } from '@/utils/useRestApiServerErrorHandling';

import { usePlotForm } from '../../../hooks/usePlotForm';
import { PlotCreationType } from '../../../types';
import { TitleBar } from '../components/TitleBar';

export const UploadPolygonPage = () => {
  const { projectId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLargeScreen = useScreenSize() === 'large';
  const queryClient = useQueryClient();

  const historyState = useLocation().state as undefined | { entryMethod: PlotCreationType };
  const polygonEntryMethod = historyState?.entryMethod;
  const form = usePlotForm();

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function storePolgyonEntryMethod() {
      form.setValue('type', polygonEntryMethod);
    },
    [form, polygonEntryMethod],
  );

  const selectedPlotType = form.watch('plotType');

  const [isDropzoneDisabled, setIsDropzoneDisabled] = useState(false);
  const { getRootProps, getInputProps, acceptedFiles, isDragActive, fileRejections } = useDropzone({
    accept: {
      'application/vnd.google-earth.kml+xml': ['.kml'],
      'application/zip': ['.zip'],
    },
    maxSize: 5 * 1024 * 1024, // 5 MB
    maxFiles: 1,
    disabled: isDropzoneDisabled,
  });

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  const acceptedFile = acceptedFiles[0];

  const { mutateAsync, progress, status } = usePostDraftPlot();

  /**
   * We do this because otherwise there will be a circular dependency between
   * the data returned by `useDropzone` and `usePostDraftPlot`.
   */
  useEffect(() => {
    setIsDropzoneDisabled(status === 'pending');
  }, [status]);

  const [uploadError, setUploadError] = useState<string | undefined>();

  useEffect(() => {
    async function uploadFile() {
      if (!acceptedFile) return;

      // TODO: MVP-2559: Why is this needed? - Rutha
      if (!projectId) {
        throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
      }
      if (!selectedPlotType) {
        throw new UnexpectedMissingDataError({ dataLabel: 'selectedPlotType' });
      }

      try {
        await mutateAsync({
          bodyData: { file: acceptedFile, project: projectId, land_type: selectedPlotType },
        });
        queryClient.resetQueries({ queryKey: ['land_steward', 'draftplots'] });
        navigate(buildPath(paths.landSteward.newPlotReviewUpload, { pathParams: { projectId } }));
      } catch (error) {
        if (isRestApiServerError(error) && error.formErrors[0]?.messages[0]) {
          setUploadError(error.formErrors[0].messages[0]);
        } else {
          setUploadError(t('landSteward.plot.new.uploadPage.error.unknown-error'));
        }
      }
    }

    uploadFile();
  }, [acceptedFile, form, mutateAsync, navigate, t, projectId, selectedPlotType, queryClient]);

  useEffect(
    // eslint-disable-next-line prefer-arrow-callback
    function clearUploadErrors() {
      setUploadError(undefined);
    },
    [acceptedFiles],
  );

  const fileErrors = useMemo(() => {
    return Array.from(
      new Set(
        fileRejections
          .flatMap((fileRejection) => fileRejection.errors)
          .map((error) => {
            const knownErrorCodes = ['file-invalid-type', 'file-too-large', 'too-many-files'] as const;
            const isKnownErrorCode = (errorCode: string): errorCode is (typeof knownErrorCodes)[number] => {
              return knownErrorCodes.some((knownError) => knownError === errorCode);
            };

            if (isKnownErrorCode(error.code)) {
              return t(`landSteward.plot.new.uploadPage.error.${error.code}`);
            }

            Logger.errorOnce(
              `Unrecognised error while uploading file. Error code: ${error.code}, Error message: ${error.message}`,
            );
            return t(`landSteward.plot.new.uploadPage.error.unknown-error`);
          }),
      ),
    );
  }, [fileRejections, t]);

  if (!selectedPlotType) throw new UnexpectedMissingDataError({ dataLabel: 'plotType' });

  return (
    <Stack className='min-h-screen items-center'>
      <TitleBar currentStep={2} />
      <Container contentWidth='md' className='flex-1 py-10 text-center [text-wrap:_balance]'>
        <Stack>
          <Stack spacing={4} className='mb-10'>
            <h1 className='typography-display2 text-center text-primary-100' data-cy='upload-file-title'>
              {t('landSteward.plot.new.uploadPage.title')}
            </h1>
            <span className='whitespace-pre-line text-center' data-cy='upload-file-description'>
              {t('landSteward.plot.new.uploadPage.subtitle')}
            </span>
          </Stack>
          <Stack spacing={6} className='mx-auto w-full max-w-2xl rounded-3xl bg-white-100 p-12'>
            <Stack
              {...getRootProps({
                className: cn(
                  'relative border border-dashed p-8 items-center justify-center rounded-2xl flex-1 transition-colors',
                  {
                    'bg-primary-12': isDragActive,
                  },
                ),
              })}
            >
              <input {...getInputProps()} />
              <Stack spacing={2} className='items-center'>
                <RiFileUploadLine size={48} className='text-primary-100' />
                <span className='typography-h4' data-cy='upload-here'>
                  {t('landSteward.plot.new.uploadPage.filezone.titleDefault')}
                </span>
                <span
                  className='typography-body1 text-text-secondary transition-colors'
                  data-cy='supported-file-formats'
                >
                  {t('landSteward.plot.new.uploadPage.filezone.subtitle')}
                </span>
              </Stack>
              {status === 'pending' && (
                <Stack
                  spacing={4}
                  className='absolute h-full w-full cursor-not-allowed items-center justify-center rounded-2xl bg-white-100'
                >
                  <span className='typography-body1Semibold text-primary-100'>
                    {t('landSteward.plot.new.uploadPage.uploading')}
                  </span>
                  <div role='progressbar' className='h-2 w-[80%] rounded-full border border-outlined-border-23p'>
                    <div
                      className='h-full rounded-full bg-primary-100 transition-all'
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </Stack>
              )}
            </Stack>
            {fileErrors.length > 0 && (
              <Stack spacing={2}>
                {fileErrors.map((error, index) => (
                  <Alert key={index} type='error' data-cy='upload-file-error'>
                    <AlertIcon>
                      <RiErrorWarningLine size={24} />
                    </AlertIcon>
                    <AlertBody>{error}</AlertBody>
                  </Alert>
                ))}
              </Stack>
            )}
            {uploadError && (
              <Alert type='error'>
                <AlertIcon>
                  <RiErrorWarningLine size={24} />
                </AlertIcon>
                <AlertBody>{uploadError}</AlertBody>
              </Alert>
            )}
            {isLargeScreen && <BottomControls />}
          </Stack>
        </Stack>
      </Container>
      {isLargeScreen ? (
        <Footer />
      ) : (
        <AppBar placement='bottom'>
          <AppBarContent>
            <BottomControls />
          </AppBarContent>
        </AppBar>
      )}
    </Stack>
  );
};

const BottomControls = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(-1)} variant='outline' className='self-start' data-cy='back-btn'>
      {t('global.ui.buttons.back')}
    </Button>
  );
};
