import '@/styles/index.css';
import '@/theme/variables';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Loader, ToastContainer } from 'component-library';
import { ThemeProvider } from 'deprecated-component-library';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';

import { queryClient } from '@/config/queryClient';
import { tokenStorage } from '@/config/tokenStorage';
import { CustomUserContextProvider, ImpersonatingBannerHandler } from '@/lib/auth';
import { CustomLandlerAuthenticationProvider } from '@/lib/auth/CustomLandlerAuthenticationProvider';

import { ErrorBoundary } from './components/error-boundary/error-boundary';
import { FallbackError } from './components/fallback-error/fallback-error';
import { pageRoutes } from './routing/PageRoutes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={
        <CustomLandlerAuthenticationProvider tokenStorage={tokenStorage}>
          <CustomUserContextProvider>
            <ThemeProvider>
              <ErrorBoundary fallback={<FallbackError />}>
                <Suspense fallback={<Loader />}>
                  <ImpersonatingBannerHandler>
                    <Outlet />
                  </ImpersonatingBannerHandler>
                </Suspense>
              </ErrorBoundary>
            </ThemeProvider>
          </CustomUserContextProvider>
        </CustomLandlerAuthenticationProvider>
      }
    >
      {pageRoutes}
    </Route>,
  ),
);

function App() {
  return (
    <>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          {import.meta.env.SHOW_REACT_QUERY_DEVTOOLS && <ReactQueryDevtools initialIsOpen={false} />}
          <RouterProvider router={router} />
        </QueryClientProvider>
      </HelmetProvider>
      <ToastContainer />
    </>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
