import {
  Button,
  ButtonLink,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RiArrowRightSLine,
  Stack,
} from 'component-library';
import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from 'component-library/Tooltip/Tooltip';
import React from 'react';

import { useScreenSize } from '@/hooks/useScreenSize';

export interface InformationBoxEntryProps {
  label: string;
  value: string;
  status?: React.ReactElement;
  ActionButton?: React.ReactNode;
  dataCy?: string;
}

export const InformationBoxEntry: React.FC<InformationBoxEntryProps> = ({
  label,
  value,
  ActionButton,
  status,
  dataCy,
}) => (
  <Stack direction='row' className='items-center' data-testid={`infobox-entry-${label}`}>
    <Stack spacing={1} className='flex-grow'>
      <span
        className='typography-caption mt-1 text-text-secondary'
        data-cy={dataCy ? `account-${dataCy}-label` : undefined}
      >
        {label}
      </span>
      <span className='typography-body1' data-cy={dataCy ? `account-${dataCy}-text` : undefined}>
        {value}
      </span>
      {status}
    </Stack>
    {ActionButton}
  </Stack>
);

type ActionButtonProperties = {
  label: string;
  link: string;
  isExternalLink?: boolean;
  disabled?: boolean;
  disabledHint?: string;
  dataCy?: string;
};

export const ActionButton: React.FC<ActionButtonProperties> = ({
  label,
  link,
  isExternalLink,
  disabled,
  disabledHint,
  dataCy,
}) => {
  const isLargeScreen = useScreenSize() === 'large';
  const externalLinkProps = isExternalLink ? { target: '_blank', rel: 'noreferrer' } : {};

  if (!disabled) {
    return (
      <ButtonLink
        to={link}
        variant='outline'
        size='small'
        {...externalLinkProps}
        rightAdornment={<RiArrowRightSLine size={20} />}
        data-cy={dataCy}
      >
        {label}
      </ButtonLink>
    );
  }

  if (!disabledHint) {
    return (
      <Button variant='outline' size='small' rightAdornment={<RiArrowRightSLine size={20} />} disabled data-cy={dataCy}>
        {label}
      </Button>
    );
  }

  if (isLargeScreen) {
    return (
      <Tooltip>
        <TooltipTrigger asChild data-testid='tooltip-trigger'>
          <div className='cursor-not-allowed'>
            <Button
              variant='outline'
              size='small'
              rightAdornment={<RiArrowRightSLine size={20} />}
              disabled
              className='pointer-events-none'
              data-cy={dataCy}
            >
              {label}
            </Button>
          </div>
        </TooltipTrigger>
        <TooltipContent sideOffset={5}>
          {disabledHint}
          <TooltipArrow />
        </TooltipContent>
      </Tooltip>
    );
  }

  return (
    <Popover>
      <PopoverTrigger asChild className='cursor-pointer' data-testid='popover-trigger'>
        <div className='cursor-not-allowed'>
          <Button
            variant='outline'
            size='small'
            rightAdornment={<RiArrowRightSLine size={20} />}
            disabled
            className='pointer-events-none'
            data-cy={dataCy}
          >
            {label}
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent position='right' size='small' className='whitespace-pre-wrap'>
        {disabledHint}
      </PopoverContent>
    </Popover>
  );
};
