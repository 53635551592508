import { useParams } from 'react-router-dom';

import { PlotReportFactLabelFilter, R1FactType } from '@/api/rest/resources/types/fact';

import { usePlotReportForPlot } from './usePlotReportForPlot';
import { filterValidLabels, getFact } from './utils';

/**
 * @param plotIdArg Falls back to the plotId in the URL if no value is provided.
 */
export const usePlotFact = <T = unknown>(
  type: R1FactType,
  plotIdArg?: string | null,
  labelFilters?: PlotReportFactLabelFilter,
) => {
  const routeParams = useParams();
  const plotId = plotIdArg ?? routeParams.plotId;

  if (!plotId) {
    throw new Error('Missing URL param: plotId');
  }

  const plotReportFactLabelFilters = labelFilters ? filterValidLabels(labelFilters) : undefined;
  const plotReport = usePlotReportForPlot(plotId, plotReportFactLabelFilters).data;

  return getFact<T>(plotReport, type, plotReportFactLabelFilters);
};
