import { Drawer, Stack } from '@mui/material';
import { IconButton } from 'component-library';
import { Icon } from 'deprecated-component-library';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useScreenSize } from '@/hooks/useScreenSize';

import { Navbar, NavbarItem } from '../Navbar';
import { SignedInNavbarAccountDropdown } from './AccountDropdown';
import { MenuItemProps, SignedInNavbarMenu } from './Menu';

export type SignedInNavbarProps = {
  items: MenuItemProps[];
};

export const SignedInNavbar: React.FC<SignedInNavbarProps> = ({ items }) => {
  const { pathname } = useLocation();
  const isLargeScreen = useScreenSize() === 'large';

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  const isItemActive = (to: string) => {
    return pathname === to || pathname.startsWith(`${to}/`);
  };

  if (isLargeScreen) {
    return (
      <Navbar>
        <Stack direction='row' flexGrow={1} spacing={4} justifyContent='center'>
          {items.map(({ text, ...props }, index) => (
            <NavbarItem key={index} {...props} active={isItemActive(props.to)}>
              {text}
            </NavbarItem>
          ))}
        </Stack>
        <SignedInNavbarAccountDropdown />
      </Navbar>
    );
  }

  return (
    // The padding of the right size is less then the one of the left side to make the pl and pr equal including the icon button padding.
    <Navbar ToolbarProps={{ sx: { pl: 2, pr: 1 } }}>
      <IconButton className='ml-auto' onClick={openDrawer} data-testid='mobile-menu-button'>
        <Icon icon='menu-filled' width={40} height={40} />
      </IconButton>
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={closeDrawer}
        PaperProps={{ sx: { minWidth: '320px', maxWidth: '90vw' } }}
      >
        <SignedInNavbarMenu closeDrawer={closeDrawer} items={items} />
      </Drawer>
    </Navbar>
  );
};
