/* eslint-disable camelcase */
import {
  Button,
  ButtonLink,
  cn,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectItemText,
  SelectTrigger,
  Stack,
  TextInput,
} from 'component-library';
import { FC, PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PlotType } from '@/api/rest/resources/types/plot';
import {
  Form as FormProvider,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  PlotTypeIcon,
  useFormField,
} from '@/components';
import { useSortedPlotTypes } from '@/hooks/useSortedPlotTypes';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';

import { CreateProjectInputs, useCreateProject } from './hooks/useCreateProject';
import { NewProjectLayout } from './layout/NewProject.layout';

export const ProjectsNewPage = () => {
  const { t } = useTranslation();

  return (
    <NewProjectLayout>
      <div className='mx-5 my-10' data-testid='new-project-page' data-cy='new-project-page'>
        <div className='m-auto w-full max-w-[664px] justify-between rounded-3xl bg-white-100 px-12 py-10'>
          <Stack spacing={8}>
            <span className='typography-display3 self-center text-primary-100' data-cy='create-new-project-headline'>
              {t('landSteward.projects.new.headline')}
            </span>
            <Form />
          </Stack>
        </div>
      </div>
    </NewProjectLayout>
  );
};

// NOTE: The form field names must match the api param names to make useRestApiServerErrorHandling work
const formFields: (keyof CreateProjectInputs)[] = ['name', 'location_description', 'landtypes_allowed'];

type FormFields = Pick<CreateProjectInputs, 'name' | 'location_description'> & { landtypes_allowed: PlotType };

const Form = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sortedPlotTypes = useSortedPlotTypes();

  const { submit, isSubmitting } = useCreateProject();

  const form = useForm<FormFields>({
    defaultValues: { name: '', location_description: '', landtypes_allowed: undefined },
  });

  const handleServerError = useRestApiServerErrorHandling(form.setError, formFields);

  const handleSubmit = async (inputs: FormFields) => {
    try {
      const formData = { ...inputs, landtypes_allowed: [inputs.landtypes_allowed] };
      const project = await submit(formData);

      const navigationPath = buildPath(paths.landSteward.projectDetails, { pathParams: { projectId: project.id } });
      navigate(navigationPath);
    } catch (e) {
      handleServerError(e);
    }
  };

  const { name, location_description, landtypes_allowed } = form.watch();

  const isFormSubmittable = !!name && !!location_description && !!landtypes_allowed;
  const isFormDisabled = isSubmitting;
  const isRequiredLabel = t('global.ui.form.input.required');

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className='flex flex-col gap-12'>
        <fieldset disabled={isFormDisabled} className='flex flex-col gap-8' data-testid='new-project-fieldset'>
          <FormField
            control={form.control}
            name='name'
            rules={{
              required: isRequiredLabel,
            }}
            render={({ field: { value, ...field } }) => (
              <FormItem className='flex flex-col gap-1'>
                <CustomFormLabel>{t('landSteward.projects.new.form.name.label')}*</CustomFormLabel>
                <FormControl>
                  <TextInput
                    data-cy='project-name'
                    placeholder={t('landSteward.projects.new.form.name.placeholder')}
                    value={value}
                    {...field}
                  />
                </FormControl>
                <CustomFormMessage>{form.formState.errors.name?.message}</CustomFormMessage>
                <FormDescription data-cy='project-name-helper-text'>
                  {t('landSteward.projects.new.form.name.helperText')}
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='location_description'
            rules={{
              required: isRequiredLabel,
            }}
            render={({ field: { value, ...field } }) => (
              <FormItem className='flex flex-col gap-1'>
                <CustomFormLabel>{t('landSteward.projects.new.form.location.label')}*</CustomFormLabel>
                <FormControl>
                  <TextInput
                    data-cy='location-name'
                    placeholder={t('landSteward.projects.new.form.location.placeholder')}
                    value={value}
                    {...field}
                  />
                </FormControl>
                <CustomFormMessage>{form.formState.errors.location_description?.message}</CustomFormMessage>
                <FormDescription data-cy='location-name-helper-text'>
                  {t('landSteward.projects.new.form.location.helperText')}
                </FormDescription>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='landtypes_allowed'
            rules={{
              required: isRequiredLabel,
            }}
            render={({ field: { ref, ...field } }) => (
              <FormItem className='flex flex-col gap-1'>
                <CustomFormLabel>{t('landSteward.projects.new.form.landType.label')}*</CustomFormLabel>
                <Select {...field}>
                  <FormControl>
                    <SelectTrigger
                      ref={ref}
                      leftAdornment={landtypes_allowed && <PlotTypeIcon type={landtypes_allowed} />}
                      placeholder={t('landSteward.projects.new.form.landType.placeholder')}
                      data-testid='land-type-select'
                      data-cy='land-type-dropdown'
                    />
                  </FormControl>
                  <CustomFormMessage>{form.formState.errors.landtypes_allowed?.message}</CustomFormMessage>
                  <FormDescription data-cy='land-type-helper-text'>
                    {t('landSteward.projects.new.form.landType.helperText')}
                  </FormDescription>
                  <SelectContent>
                    <SelectGroup>
                      {sortedPlotTypes.supported.map((item) => (
                        <SelectItem key={item.type} value={item.type} leftAdornment={<PlotTypeIcon type={item.type} />}>
                          {item.translation}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                    <SelectGroup>
                      {sortedPlotTypes.unsupported.map((item) => (
                        <SelectItem
                          disabled={true}
                          key={item.type}
                          value={item.type}
                          leftAdornment={<PlotTypeIcon className='text-[#00000061]' type={item.type} />}
                          className='flex-1 text-text-disabled'
                          asChild
                        >
                          <Stack direction='row' spacing={6} className='flex-1 justify-between'>
                            <SelectItemText>{item.translation}</SelectItemText>
                            <span>{t('global.misc.comingSoon')}</span>
                          </Stack>
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        </fieldset>
        <Stack direction='row' spacing={4} className='justify-end'>
          <ButtonLink to={paths.landSteward.projects} variant='text' data-cy='cancel-button'>
            {t('global.ui.buttons.cancel')}
          </ButtonLink>
          <Button type='submit' disabled={!isFormSubmittable} loading={isSubmitting} data-cy='create-project-btn'>
            {t('landSteward.projects.new.form.submitButton')}
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
};

const CustomFormLabel: FC<PropsWithChildren> = ({ children }) => {
  const { error } = useFormField();

  return (
    <FormLabel
      className={cn('text-[.75rem] font-medium leading-4 text-text-secondary md:text-[.8125rem] md:leading-5', {
        'text-error': error,
      })}
    >
      {children}
    </FormLabel>
  );
};

const CustomFormMessage: FC<PropsWithChildren> = ({ children }) => (
  <FormMessage className='typography-inputLabel mt-0 text-error'>{children}</FormMessage>
);
