import { Loader } from 'component-library';
import React from 'react';

import { OnboardingPage } from '@/layout/OnboardingPage';

import { LandStewardCompleteAccountError } from './components/error/error';
import { LandStewardCompleteAccountForm } from './components/form/form';
import { PageState, useLandStewardCompleteAccountPage } from './hooks/useLandStewardCompleteAccountPage';

export const LandStewardCompleteAccountPage: React.FC = () => {
  const { pageState, predefinedData, handleCompleteAccount, isSubmitting } = useLandStewardCompleteAccountPage();

  return (
    <>
      {(() => {
        switch (pageState) {
          case PageState.LOADING:
            return <Loader data-testid='loading-indicator' />;
          case PageState.SUCCESS:
            return (
              <OnboardingPage>
                <LandStewardCompleteAccountForm
                  predefinedData={predefinedData}
                  handleCompleteAccount={handleCompleteAccount}
                  isSubmitting={isSubmitting}
                />
              </OnboardingPage>
            );
          default:
            return <LandStewardCompleteAccountError pageState={pageState} />;
        }
      })()}
    </>
  );
};
