import { Box } from '@mui/material';
import { Heading } from 'deprecated-component-library';
import { useTranslation } from 'react-i18next';

import { useScreenSize } from '@/hooks/useScreenSize';

import { DesktopView } from './components/DesktopView';
import { MobileView } from './components/MobileView';

export const AccountPage = () => {
  const { t } = useTranslation();

  const isLargeScreen = useScreenSize() === 'large';

  return (
    <Box>
      <Heading variant='h5' mb={3} data-cy='personal-info-title'>
        {t('shared.account.titles.personalInformation')}
      </Heading>
      {isLargeScreen ? <DesktopView /> : <MobileView />}
    </Box>
  );
};
