import { cn, Stack } from 'component-library';
import { DataTestAttributes, FC } from 'react';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';

export type MapOverviewHudItem = {
  label: string;
  value?: string | number | null;
  unit?: string | UnitEnum;
} & DataTestAttributes;

type MapOverviewHudProps = {
  items: MapOverviewHudItem[];
};

export const MapOverviewHud: FC<MapOverviewHudProps> = ({ items }) => {
  return (
    <div
      className={cn(
        'pointer-events-none',
        'absolute left-0 top-0 w-full gap-2 md:gap-5',
        'px-5 py-10 text-white-100 md:px-10',
        'max-w-full bg-gradient-to-b from-black-100 to-transparent',
      )}
    >
      <Stack
        data-testid='map-overview-hud'
        direction='row'
        className='inherit max-w-[1600px] justify-between divide-x divide-white-100'
      >
        {items.map((item) => (
          <InfoBox
            data-testid={`map-overview-hud-${item.label}`}
            data-cy={item['data-cy'] ? `${item['data-cy']}-map-fact` : undefined}
            key={item.label}
            label={item.label}
            value={item.value}
            unit={item.unit}
          />
        ))}
      </Stack>
    </div>
  );
};

const InfoBox: FC<MapOverviewHudItem> = ({ label, value, unit, ...delegated }) => {
  const formattedNumber = useDisplayNumber(value ?? 0);

  /* value == null checks for both null and undefined */
  if (value == null) {
    return (
      <Stack className='gap-1 pl-2 first:pl-0 md:gap-5 md:pl-5' {...delegated}>
        <span className='typography-caption md:typography-overline' data-cy='label'>
          {label}
        </span>
        <span className='typography-h3 whitespace-nowrap md:typography-h1' data-cy='data'>
          --
        </span>
      </Stack>
    );
  }

  const formattedValue = unit ? `${formattedNumber} ${unit}` : formattedNumber;

  return (
    <Stack className='gap-1 pl-2 first:pl-0 md:gap-5 md:pl-5' {...delegated}>
      <span className='typography-caption md:typography-overline' data-cy='label'>
        {label}
      </span>
      <span className='typography-h3 whitespace-nowrap md:typography-h1' data-cy='data'>
        {formattedValue}
      </span>
    </Stack>
  );
};
