import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PLOTS_PATH,
  GET_PLOT_PATH,
  GetBuyerPlotsErrorDto,
  GetBuyerPlotsSuccessDto,
  GetPlotErrorDto,
  GetPlotSuccessDto,
} from '@/api/rest/resources/types/plot';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

type SuccessDto = GetPlotSuccessDto | GetBuyerPlotsSuccessDto;
type ErrorDto = GetPlotErrorDto | GetBuyerPlotsErrorDto;

export const usePaginatedPlotsForProject = (
  filters?: Record<string, unknown> | null,
  limit?: number,
  offset?: number,
  sort?: string,
) => {
  const membershipType = useMembershipType();
  const projectId = useProjectId();

  const queryParams = {
    project: projectId,
    ...filters,
    limit,
    offset,
    sort,
  };

  const queryFn = async () => {
    const queryPath =
      membershipType === MembershipWithOrganizationTypeEnum.land_steward ? GET_PLOT_PATH : GET_BUYER_PLOTS_PATH;

    const response = await getRequest<SuccessDto>(queryPath, undefined, queryParams);

    if (response.results.length > 0) {
      return response;
    }

    /**
     * If there are no results, we default to the first page. This handles the
     * edge case where page no. 5 has only one plot, and if we delete that plot,
     * we end up with a table with no items in it.
     */
    return getRequest<SuccessDto>(queryPath, undefined, { ...queryParams, offset: 0 });
  };

  return useSuspenseQuery<SuccessDto, ErrorDto>({
    queryKey: [membershipType, 'plots', queryParams],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
