import projectThumbnailSrc from '@assets/images/project-thumbnail.png';
import {
  Button,
  Divider,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  Image,
  Pill,
  RiEditLine,
  RiMore2Line,
  Shimmer,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from 'component-library';
import { Icon } from 'deprecated-component-library';
import { FC, HTMLProps, useMemo, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { OrganizationTypeEnum } from '@/api/rest/resources/types/organization';
import { PlotType } from '@/api/rest/resources/types/plot';
import {
  Project,
  ProjectBuyerDetail,
  ProjectDetail,
  ProjectStatus,
  ProjectType,
} from '@/api/rest/resources/types/project';
import {
  CopyLandStewardEmailButton,
  PlotTypeIcon,
  ProjectStatusBadge,
  ProjectStatusDemoBadge,
  withSuspenseBoundary,
} from '@/components';
import { AgricultureProjectThumbnail, ConservationProjectThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { PendingPill } from '@/components/Pill/PendingPill';
import { useFact } from '@/hooks/useFact';
import { useMembershipType, useRequireMembershipType } from '@/hooks/useMembershipType';
import { EditProject } from '@/pages/shared/components/EditProject';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { useProjectFact } from '@/pages/shared/hooks/useProjectFact';
import { useProject } from '@/pages/shared/projects/pages/project/pages/conservation/hooks/useProject';
import { useProjectBoundary } from '@/pages/shared/projects/pages/project/pages/conservation/hooks/useProjectPolygon';
import { Area, useAreaDisplay } from '@/utils/area';
import { formatFactKilogramsToTonnes } from '@/utils/formatting';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

export const ProjectCardTablet: FC<{ project: Project }> = ({ project }) => {
  const projectStatus = project.status;

  const membershipType = useMembershipType();

  const showCompleteInfo = useMemo(() => {
    if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) return true;

    return project.organization.type === OrganizationTypeEnum.DEMO || projectStatus !== ProjectStatus.initial;
  }, [membershipType, project.organization.type, projectStatus]);

  return (
    <article
      data-testid='projectcard-tablet'
      className='relative grid w-full grid-cols-[24px_1fr_24px] overflow-hidden rounded-2xl bg-white-100'
    >
      {showCompleteInfo ? <CompleteInfo project={project} /> : <MinimalInfo project={project} />}
    </article>
  );
};

const MinimalInfo = ({ project }: { project: Project }) => {
  const { t } = useTranslation();

  const invitationDate = (project as ProjectBuyerDetail).invited_at;

  return (
    <>
      <Stack direction='row' className='items-center justify-between border-b border-divider p-6 full-bleed-x'>
        <Stack direction='row' className='mr-4 flex min-w-0 items-center gap-6'>
          <Thumbnail project={project} />
          <DataStack>
            <DataLabel>{t('shared.projects.overviewCard.labels.landSteward')}</DataLabel>
            <DataText>{project.organization.name}</DataText>
          </DataStack>
        </Stack>
        <CopyLandStewardEmailButton email={(project as ProjectBuyerDetail).organization_email} />
      </Stack>
      <div className='col-start-2 flex min-w-0 flex-col gap-6 py-6'>
        <DataStack>
          <DataLabel>{t('shared.projects.overviewCard.labels.invitationDate')}</DataLabel>
          <DataText>{invitationDate ? new Date(invitationDate).toLocaleDateString() : '--'}</DataText>
        </DataStack>
      </div>
    </>
  );
};

const CompleteInfo = ({ project }: { project: Project }) => {
  const { t } = useTranslation();
  const membershipType = useMembershipType();

  const requireLandstewardMembership = useRequireMembershipType('land_steward');

  const plotTypes = project.landtypes_allowed;

  const isDemoProject = project.organization.type === OrganizationTypeEnum.DEMO;
  const hasWritePermission = getProjectPermissions(project).includes('write');

  const [isEditingProject, setIsEditingProject] = useState(false);

  return (
    <>
      <div className='flex items-center justify-between border-b border-divider p-6 full-bleed-x'>
        <div className='flex items-center gap-6'>
          <Thumbnail project={project} />
          <DataStack>
            <DataLabel>{t('shared.projects.overviewCard.labels.projectName')}</DataLabel>
            <DataText>{project.name}</DataText>
          </DataStack>
        </div>
        <Stack direction='row' spacing={2} className='shrink-0'>
          <Button variant='outline'>{t('shared.projects.overviewCard.labels.seeDetailsButton')}</Button>
          {requireLandstewardMembership(
            <Dropdown modal={true}>
              <DropdownTrigger data-testid='project-overflow-menu'>
                <div className='shrink-0 rounded-full border border-primary-100 p-2 text-primary-100 hover:border-primary-hover hover:bg-primary-12'>
                  <RiMore2Line size={24} />
                </div>
              </DropdownTrigger>
              <DropdownContent side='bottom' sideOffset={10}>
                {hasWritePermission ? (
                  <DropdownItem leftAdornment={<RiEditLine />} onClick={() => setIsEditingProject(true)}>
                    {t('shared.projects.overviewCard.labels.editProject')}
                  </DropdownItem>
                ) : (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <DropdownItem disabled>{t('shared.projects.overviewCard.labels.editProject')}</DropdownItem>
                    </TooltipTrigger>
                    <TooltipContent sideOffset={5} side='left'>
                      {t('global.tooltips.disabledLockedProject')}
                      <TooltipArrow />
                    </TooltipContent>
                  </Tooltip>
                )}
              </DropdownContent>
            </Dropdown>,
          )}
        </Stack>

        <EditProject
          projectId={project.id}
          open={isEditingProject}
          onOpenChange={(open) => setIsEditingProject(open)}
        />
      </div>
      <div className='col-start-2 flex min-w-0 flex-col gap-6 py-6'>
        <Stack direction='row' spacing={6}>
          <div className='grid w-full grid-cols-3 gap-6'>
            {(() => {
              if (project.type === ProjectType.conservation) {
                return (
                  <DataStack>
                    <DataLabel data-cy='linked-label'>{t('shared.projects.overviewCard.labels.management')}</DataLabel>
                    <DataText>{project.organization.name ?? '--'}</DataText>
                  </DataStack>
                );
              }

              if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
                return (
                  <DataStack>
                    <DataLabel>{t('shared.projects.overviewCard.labels.sponsor')}</DataLabel>
                    <DataText>{(project as ProjectDetail).buyer?.name ?? '--'}</DataText>
                  </DataStack>
                );
              }

              return (
                <DataStack>
                  <DataLabel>{t('shared.projects.overviewCard.labels.landSteward')}</DataLabel>
                  <DataText>{project.organization.name ?? '--'}</DataText>
                </DataStack>
              );
            })()}
            <DataStack>
              <DataLabel>{t('shared.projects.overviewCard.labels.location')}</DataLabel>
              <DataText>
                <ProjectLocation project={project} />
              </DataText>
            </DataStack>

            <DataStack>
              <DataLabel>{t('shared.projects.overviewCard.labels.area')}</DataLabel>
              <DataText>
                <ProjectArea project={project} />
              </DataText>
            </DataStack>
          </div>
        </Stack>

        <>
          {(() => {
            if (project.type === ProjectType.conservation) {
              return (
                <>
                  <Divider />

                  <DataStack>
                    <DataLabel>{t('shared.projects.overviewCard.labels.projectType')}</DataLabel>
                    <DataText>{t('shared.projects.projectType.conservation')}</DataText>
                  </DataStack>
                </>
              );
            }

            if (plotTypes.length > 0) {
              return (
                <>
                  <Divider />

                  <DataStack>
                    <DataLabel>{t('shared.projects.overviewCard.labels.projectLandType')}</DataLabel>
                    <DataText>
                      {plotTypes.length > 1 ? (
                        t('shared.projects.overviewCard.mixedLandtypes')
                      ) : (
                        <Stack direction='row' spacing={1} title={t(`global.plotTypes.${plotTypes[0] as PlotType}`)}>
                          <PlotTypeIcon type={plotTypes[0] as PlotType} size={20} />
                          <span>{t(`global.plotTypes.${plotTypes[0] as PlotType}`)}</span>
                        </Stack>
                      )}
                    </DataText>
                  </DataStack>
                </>
              );
            }

            return null;
          })()}
        </>

        {project.type === ProjectType.agriculture && (
          <>
            <Divider />
            <Stack direction='row' spacing={6}>
              <div className='grid w-full grid-cols-3 gap-6'>
                <DataStack className='h-12'>
                  <DataLabel>{t('shared.projects.overviewCard.labels.protectedHabitat')}</DataLabel>
                  <BiodiversityZoneCell projectId={project.id} />
                </DataStack>

                <DataStack className='h-12'>
                  <DataLabel>{t('shared.projects.overviewCard.labels.carbonStorage')}</DataLabel>
                  <CarbonStorageBgCell projectId={project.id} />
                </DataStack>

                <DataStack className='h-12'>
                  <DataLabel>{t('shared.projects.overviewCard.labels.waterHolding')}</DataLabel>
                  <WaterHoldingCapacityCell projectId={project.id} />
                </DataStack>
              </div>
            </Stack>

            <Divider />

            <DataStack>
              <DataLabel>{t('shared.projects.overviewCard.labels.projectStatus')}</DataLabel>
              {isDemoProject ? <ProjectStatusDemoBadge /> : <ProjectStatusBadge project={project} />}
            </DataStack>
          </>
        )}
      </div>
    </>
  );
};

const ProjectLocation = ({ project }: { project: Project }) => {
  if (project.type === ProjectType.conservation) {
    return <ConservationProjectLocation project={project} />;
  }
  if (project.type === ProjectType.agriculture) {
    return <AgricultureProjectLocation project={project} />;
  }

  return <UnknownLocation />;
};

const UnknownLocation = () => {
  const { t } = useTranslation();

  return <>{t('shared.projects.overviewCard.unknownLocation')}</>;
};

const AgricultureProjectLocation = ({ project }: { project: Project }) => {
  const projectLocation = project.location_description;

  if (!projectLocation) {
    return <UnknownLocation />;
  }

  return <>{projectLocation}</>;
};

const ConservationProjectLocation = withErrorBoundary<{ project: Project }>(
  withSuspenseBoundary(({ project }) => {
    const projectLocation = useProject(project.id).data.location_description;

    if (!projectLocation) {
      return <UnknownLocation />;
    }

    return <>{projectLocation}</>;
  }, <PendingPill />),
  { fallback: <UnknownLocation /> },
);

const ProjectArea = ({ project }: { project: Project }) => {
  if (project.type === ProjectType.conservation) {
    return <ConservationProjectArea project={project} />;
  }
  if (project.type === ProjectType.agriculture) {
    return <AgricultureProjectArea project={project} />;
  }

  return <UnknownArea />;
};

const UnknownArea = () => {
  return <>--</>;
};

const AgricultureProjectArea = ({ project }: { project: Project }) => {
  const areaDisplay = useAreaDisplay(new Area({ value: project.area, dimension: 'squareMeters' }).toHectares());

  if (project.area <= 0) {
    return <UnknownArea />;
  }

  return <>{areaDisplay}</>;
};

const ConservationProjectArea = withErrorBoundary<{ project: Project }>(
  withSuspenseBoundary(({ project }) => {
    const projectArea = useProject(project.id).data.area;

    return <>{projectArea}</>;
  }, <PendingPill />),
  { fallback: <UnknownArea /> },
);

const BiodiversityZoneCell = withSuspenseBoundary(({ projectId }: { projectId: string }) => {
  const biodiversityZone = useFact(useProjectFact(R1FactType.r1_biodiversity_zone_percent, projectId));
  return (
    <Pill
      size='small'
      leftAdornment={<Icon icon='biodiversity' width={12} height={12} />}
      className='bg-neutral-black-4'
    >
      {biodiversityZone.display}
    </Pill>
  );
}, <PendingPill />);

const CarbonStorageBgCell = withSuspenseBoundary(({ projectId }: { projectId: string }) => {
  const carbonStorage = useFact(
    formatFactKilogramsToTonnes(useProjectFact(R1FactType.r1_carbon_storage_bg_per_ha, projectId)),
  );

  return (
    <Pill size='small' leftAdornment={<Icon icon='co2' width={12} height={12} />} className='bg-neutral-black-4'>
      {carbonStorage.display}
    </Pill>
  );
}, <PendingPill />);

const WaterHoldingCapacityCell = withSuspenseBoundary(({ projectId }: { projectId: string }) => {
  const waterHoldingCapacity = useFact(useProjectFact(R1FactType.r1_water_holding_capacity_per_ha, projectId));

  return (
    <Pill size='small' leftAdornment={<Icon icon='water' width={12} height={12} />} className='bg-neutral-black-4'>
      {waterHoldingCapacity.display}
    </Pill>
  );
}, <PendingPill />);

const DataLabel: FC<HTMLProps<HTMLSpanElement>> = (delegated) => (
  <span
    className='typography-overline block truncate pb-2.5 leading-4 text-text-disabled'
    title={typeof delegated.children === 'string' ? delegated.children : undefined}
    {...delegated}
  />
);

const DataText: FC<HTMLProps<HTMLSpanElement>> = (delegated) => (
  <span
    className='typography-h4 truncate'
    title={typeof delegated.children === 'string' ? delegated.children : undefined}
    {...delegated}
  />
);

const DataStack: FC<HTMLProps<HTMLDivElement>> = (delegated) => <div className='truncate' {...delegated} />;

const Thumbnail = withErrorBoundary<{ project: Project }>(
  withSuspenseBoundary(({ project }) => {
    if (project.type === ProjectType.conservation) {
      return <ConservationThumbnail projectId={project.id} />;
    }

    return <AgricultureThumbnail projectId={project.id} />;
  }, <Shimmer className='h-20 w-20 rounded-2xl' />),
  { fallback: <Image src={projectThumbnailSrc} className='h-20 w-20 rounded-2xl' alt='project-thumbnail' /> },
);

const ConservationThumbnail = ({ projectId }: { projectId: string }) => {
  const polygons = useProjectBoundary(projectId).data;

  return <ConservationProjectThumbnail polygons={polygons} className='h-20 w-20 rounded-2xl' data-cy='thumbnail' />;
};

const AgricultureThumbnail = ({ projectId }: { projectId: string }) => {
  const plots = usePlotsForProject(projectId).data.results;

  if (!plots) {
    return <Image src={projectThumbnailSrc} className='h-20 w-20 rounded-2xl' alt='project-thumbnail' />;
  }

  return <AgricultureProjectThumbnail plots={plots} className='h-20 w-20 rounded-2xl' data-cy='thumbnail' />;
};
