import { cn, Stack, StackProps } from 'component-library';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { BiodiversityTile } from './components/BiodiversityTile';
import { CarbonTile } from './components/CarbonTile';
import { DeforestationTile } from './components/DeforestationTile';
import { WaterTile } from './components/WaterTile';

export const LandMonitoringPage = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={10} data-testid='landmonitoring-page'>
      <span className='typography-overline'>{t('shared.projects.labels.landMonitoring')}</span>
      <div className='grid grid-cols-1 gap-10 md:grid-cols-2'>
        <Card>
          <CardTitle>{t('global.analysis.carbon')}</CardTitle>
          <CarbonTile />
        </Card>
        <Card>
          <CardTitle>{t('global.analysis.water')}</CardTitle>
          <WaterTile />
        </Card>
        <Card>
          <CardTitle>{t('global.analysis.biodiversity')}</CardTitle>
          <BiodiversityTile />
        </Card>
        <Card>
          <CardTitle>{t('global.analysis.deforestation')}</CardTitle>
          <DeforestationTile />
        </Card>
      </div>
    </Stack>
  );
};

type CardProps = StackProps;

const Card = ({ className, ...delegated }: CardProps) => {
  return <Stack className={cn('rounded-[32px] bg-white-100 p-8', className)} {...delegated} />;
};

type CardTitleProps = HTMLAttributes<HTMLSpanElement>;

const CardTitle = ({ className, ...delegated }: CardTitleProps) => {
  return <span className={cn('typography-h3', className)} {...delegated} />;
};
