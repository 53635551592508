import { Button, ButtonLink, Dialog, Stack, toast, toastifyToast } from 'component-library';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RestApiBadRequestServerError } from '@/api/rest/resources/errors/RestApiBadRequestError';
import { Form } from '@/components';
import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';

import { Heading, SectionCard, SectionCardHeading } from '../../../../components';
import { FormContent } from './components/FormContent';
import { SuccessDialogContent } from './components/SuccessDialogContent';
import { InviteInputs, useSendInvite } from './hooks/useSendInvite';

// NOTE: The form field names must match the api param names to make useRestApiServerErrorHandling work
const formFields = ['user.firstname', 'user.lastname', 'user.email', 'role'] as (keyof InviteInputs)[];

export const AccountTeamInvitePage = () => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';
  const { accountTeamPath } = useAccountPageRoutes();

  const form = useForm<InviteInputs>({
    defaultValues: {
      user: {
        firstname: '',
        lastname: '',
        email: '',
      },
      role: undefined,
    },
  });

  const handleServerError = useRestApiServerErrorHandling(form.setError, formFields);

  const { submit, isSubmitting } = useSendInvite();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const sendInvite = async (inputs: InviteInputs) => {
    try {
      toastifyToast.clearWaitingQueue();
      toastifyToast.dismiss();

      await submit(inputs);

      setIsDialogOpen(true);
    } catch (error) {
      if (error instanceof RestApiBadRequestServerError) {
        handleServerError(error);
        return;
      }

      toast({
        title: t('shared.account.teamInvite.errorToast.title'),
        description: t('shared.account.teamInvite.errorToast.description'),
        type: 'error',
        autoClose: 10_000,
      });
    }
  };

  const { user, role } = form.watch();

  const isSubmitButtonEnabled = !!user.firstname && !!user.lastname && !!user.email && !!role;

  return (
    <>
      <Form {...form}>
        <Heading backPath={accountTeamPath}>{t('shared.account.teamInvite.labels.title')}</Heading>
        <form onSubmit={form.handleSubmit(sendInvite)} className='flex flex-col gap-6 md:gap-9'>
          <fieldset disabled={isSubmitting}>
            <SectionCard>
              <SectionCardHeading>{t('shared.account.teamInvite.labels.inviteTeamMembers')}</SectionCardHeading>
              <Stack spacing={6}>
                <span className='typography-body1 max-w-lg to-text-primary'>{t('shared.account.teamInvite.copy')}</span>
                <FormContent form={form} />
              </Stack>
            </SectionCard>
          </fieldset>
          <Stack direction='row' spacing={4} className='justify-end'>
            <ButtonLink to={accountTeamPath} variant='text' size={isLargeScreen ? 'medium' : 'small'}>
              {t('global.ui.buttons.cancel')}
            </ButtonLink>
            <Button
              type='submit'
              size={isLargeScreen ? 'medium' : 'small'}
              disabled={!isSubmitButtonEnabled}
              loading={isSubmitting}
            >
              {t('shared.account.teamInvite.labels.submitButton')}
            </Button>
          </Stack>
        </form>
      </Form>
      <Dialog
        open={isDialogOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsDialogOpen(isOpen);
        }}
      >
        <SuccessDialogContent form={form} />
      </Dialog>
    </>
  );
};
