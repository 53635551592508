import {
  BareAndSparseVegetationIcon,
  BuiltUpAreasIcon,
  CroplandIcon,
  GrasslandIcon,
  HerbaceousWetlandIcon,
  IconProps,
  MangrovesIcon,
  MossAndLichensIcon,
  PermanentWaterBodiesIcon,
  RiMoreFill,
  ShrublandIcon,
  SnowAndIceIcon,
  TreeCoverIcon,
} from 'component-library';
import React from 'react';

import { PlotType } from '@/api/rest/resources/types/plot';
import { Logger } from '@/lib/logs/logger';
import { getColorOfPlotType } from '@/utils/plot';

interface PlotTypeIconProps extends IconProps {
  type: PlotType;
  color?: string;
}

/**
 * Order of priority for icon color:
 * - `style` (highest priority)
 * - `className`
 * - `color` prop (least priority)
 *
 * Use the Tailwind class `text-[inherit]` to inherit the icon color.
 */
export const PlotTypeIcon: React.FC<PlotTypeIconProps> = ({ type, color, size, ...props }) => {
  const IconComponent = getIconForPlotType(type);

  if (!IconComponent) return null;

  return (
    <IconComponent
      role='img'
      id='plot-type-icon'
      data-testid={`plot-type-icon-${type}`}
      size={size ?? 24}
      color={color ?? getColorOfPlotType(type)}
      {...props}
    />
  );
};

const getIconForPlotType = (plotType: PlotType) => {
  const exhaustivenessCheck = (p: never) => {
    Logger.error(`No icon is defined for the plot type "${p}"`);
    return null;
  };

  switch (plotType) {
    case PlotType.BARE_AND_SPARSE_VEGETATION:
      return BareAndSparseVegetationIcon;
    case PlotType.BUILT_UP:
      return BuiltUpAreasIcon;
    case PlotType.CROPLAND:
      return CroplandIcon;
    case PlotType.GRASSLAND:
      return GrasslandIcon;
    case PlotType.HERBACEOUS_WETLAND:
      return HerbaceousWetlandIcon;
    case PlotType.MANGROVES:
      return MangrovesIcon;
    case PlotType.MOSS_AND_LICHENS:
      return MossAndLichensIcon;
    case PlotType.PERMANENT_WATER_BODIES:
      return PermanentWaterBodiesIcon;
    case PlotType.SHRUBLAND:
      return ShrublandIcon;
    case PlotType.SNOW_AND_ICE:
      return SnowAndIceIcon;
    case PlotType.TREE_COVER:
      return TreeCoverIcon;
    case PlotType.UNDEFINED:
      return RiMoreFill;
    default:
      return exhaustivenessCheck(plotType);
  }
};
