import { Button, ButtonLink, ButtonLinkProps, ButtonProps } from 'component-library';
import { Tooltip, TooltipArrow, TooltipContent, TooltipTrigger } from 'component-library/Tooltip/Tooltip';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type CopyLandStewardEmailButtonProps = ButtonProps & { email: string };

export const CopyLandStewardEmailButton = ({ email, ...delegated }: CopyLandStewardEmailButtonProps) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleButtonClick = () => {
    setCopied(true);
    navigator.clipboard.writeText(email);
  };

  const isClipboardWriteSupported = Boolean(navigator.clipboard?.writeText);

  if (!isClipboardWriteSupported)
    return (
      <ButtonLink
        variant='outline'
        className='shrink-0'
        to={`mailto:${email}`}
        {...(delegated as Omit<ButtonLinkProps, 'to'>)}
      >
        {t('shared.projects.overviewCard.labels.emailLandSteward')}
      </ButtonLink>
    );

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger asChild>
        <Button
          variant='outline'
          className='shrink-0'
          onClick={handleButtonClick}
          onMouseOver={() => setShowTooltip(true)}
          onMouseLeave={() => {
            setShowTooltip(false);
            setCopied(false);
          }}
          {...delegated}
        >
          {t('shared.projects.overviewCard.labels.emailLandSteward')}
        </Button>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {copied ? t('shared.projects.overviewCard.labels.copied') : t('shared.projects.overviewCard.labels.copyEmail')}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};
