import { cn, Select, SelectContent, SelectItem, SelectTrigger, TextInput } from 'component-library';
import { FC, PropsWithChildren } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RoleEnum } from '@/api/rest/resources/types/membership';
import { FormControl, FormField, FormItem, FormLabel, FormMessage, useFormField } from '@/components';

import { EditMemberInputs } from '../hooks/useEditTeamMember';

type FormContentProps = {
  form: UseFormReturn<EditMemberInputs>;
  isDisabled: boolean;
};

export const FormContent: FC<FormContentProps> = ({ form, isDisabled }) => {
  const { t } = useTranslation();

  const requiredLabel = t('global.ui.form.input.required');

  return (
    <>
      <FormField
        control={form.control}
        name='user.firstname'
        rules={{
          required: true,
        }}
        render={({ field: { value, ...field } }) => (
          <FormItem className='flex flex-col gap-1'>
            <CustomFormLabel>{t('shared.account.teamEditMember.form.firstName.label')}</CustomFormLabel>
            <FormControl>
              <TextInput
                placeholder={t('shared.account.teamEditMember.form.firstName.placeholder')}
                value={value ?? ''}
                {...field}
              />
            </FormControl>
            <CustomFormMessage>{requiredLabel}</CustomFormMessage>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name='user.lastname'
        rules={{ required: true }}
        render={({ field: { value, ...field } }) => (
          <FormItem className='flex flex-col gap-1'>
            <CustomFormLabel>{t('shared.account.teamEditMember.form.lastName.label')}</CustomFormLabel>
            <FormControl>
              <TextInput
                placeholder={t('shared.account.teamEditMember.form.lastName.placeholder')}
                value={value ?? ''}
                {...field}
              />
            </FormControl>
            <CustomFormMessage>{requiredLabel}</CustomFormMessage>
          </FormItem>
        )}
      />
      <FormField
        name='user.email'
        render={({ field: { value, ...field } }) => (
          <FormItem className='flex flex-col gap-1'>
            <CustomFormLabel>{t('shared.account.teamEditMember.form.emailAddress.label')}</CustomFormLabel>
            <FormControl>
              <TextInput type='email' value={value ?? ''} {...field} disabled />
            </FormControl>
          </FormItem>
        )}
      />
      <FormField
        name='role'
        rules={{ required: true }}
        control={form.control}
        render={({ field: { ref, value, ...field } }) => (
          <FormItem className='flex flex-col gap-1'>
            <CustomFormLabel>{t('shared.account.teamEditMember.form.permissionLevel.label')}</CustomFormLabel>
            <Select value={value ?? undefined} {...field}>
              <FormControl>
                <SelectTrigger
                  ref={ref}
                  placeholder={t('shared.account.teamEditMember.form.permissionLevel.placeholder')}
                  disabled={isDisabled}
                />
              </FormControl>
              <SelectContent>
                <SelectItem value={RoleEnum.admin}>{t('global.memberRoles.admin')}</SelectItem>
                <SelectItem value={RoleEnum.editor}>{t('global.memberRoles.editor')}</SelectItem>
              </SelectContent>
            </Select>
            <CustomFormMessage>{requiredLabel}</CustomFormMessage>
          </FormItem>
        )}
      />
    </>
  );
};

const CustomFormLabel: FC<PropsWithChildren> = ({ children }) => {
  const { error } = useFormField();

  return (
    <FormLabel
      className={cn('text-[.75rem] font-medium leading-4 text-text-secondary md:text-[.8125rem] md:leading-5', {
        'text-error': error,
      })}
    >
      {children}
    </FormLabel>
  );
};

const CustomFormMessage: FC<PropsWithChildren> = ({ children }) => (
  <FormMessage className='typography-inputLabel mt-0 text-error'>{children}</FormMessage>
);
