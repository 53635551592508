/* eslint-disable camelcase */
import {
  Button,
  Checkbox,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  toast,
  toastifyToast,
} from 'component-library';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { usePatchUserMe } from '@/api/rest/resources/user';
import { Form, FormField, FormItem, FormLabel, FormMessage } from '@/components';
import { useUser } from '@/lib/auth';

type KycIntroDialogFormInputs = {
  accepted_biometric_data_process: boolean;
};

export type IntroDialogProps = {
  onChange: (newValue: boolean) => void;
  onSubmit: () => Promise<void>;
  open: boolean;
};

export const KycIntroDialog: React.FC<IntroDialogProps> = ({ open, onChange, onSubmit }) => {
  const { t } = useTranslation();
  const user = useUser();
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: mutateUser } = usePatchUserMe();

  const form = useForm<KycIntroDialogFormInputs>({
    defaultValues: {
      accepted_biometric_data_process: false,
    },
  });

  const { accepted_biometric_data_process } = form.watch();

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      toastifyToast.clearWaitingQueue();
      toastifyToast.dismiss();

      if (!user.accepted_biometric_data_process) {
        await mutateUser({ bodyData: { accepted_biometric_data_process } });
      }

      await onSubmit();
    } catch (ex) {
      toast({
        title: t('global.ui.toast.errorToastFallbackTitle'),
        description: t('global.ui.toast.errorToastFallbackDescription'),
        type: 'error',
        autoClose: 10_000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const isSubmitDisabled = accepted_biometric_data_process === false || isLoading;

  return (
    <Dialog
      open={open}
      onOpenChange={(value) => {
        onChange(value);
      }}
    >
      <DialogContent withXButton={false} data-cy='kyc-intro-dialog' className='overflow-y-auto'>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)}>
            <fieldset disabled={isLoading}>
              <DialogBody>
                <div className='typography-h3 mb-6' data-cy='title'>
                  {t('global.kyc.intro.title')}
                </div>
                <div className='typography-body1 mb-6 flex flex-col gap-5'>
                  <span data-cy='text1'>{t('global.kyc.intro.body.paragraph1')}</span>
                  <span data-cy='text2'>{t('global.kyc.intro.body.paragraph2')}</span>
                </div>
                <FormField
                  name='accepted_biometric_data_process'
                  rules={{ validate: (value) => value === true || t('global.ui.form.input.required') }}
                  control={form.control}
                  render={({ field: { value, ...field } }) => (
                    <FormItem className='mb-10 flex flex-col gap-3'>
                      <Checkbox id='form:kyc:biometric' checked={value} {...field}>
                        <FormLabel htmlFor='form:kyc:biometric'>
                          <Trans
                            i18nKey='global.kyc.intro.body.checkbox.label'
                            values={{
                              email: 'info@landbankinggroup.com',
                            }}
                            components={{
                              a: (
                                // eslint-disable-next-line jsx-a11y/anchor-has-content
                                <a
                                  className='underline'
                                  href='mailto:info@landbankinggroup.com'
                                  target='_blank'
                                  rel='noreferrer'
                                />
                              ),
                            }}
                          />
                        </FormLabel>
                      </Checkbox>
                      <FormMessage className='typography-inputLabel mt-0 text-error'>
                        {t('global.ui.form.input.required')}
                      </FormMessage>
                    </FormItem>
                  )}
                />
                <div className='flex flex-col'>
                  <span className='typography-body1Semibold' data-cy='headline'>
                    {t('global.kyc.intro.body.paragraph3.headline')}
                  </span>
                  <span data-cy='text3'>{t('global.kyc.intro.body.paragraph3.text')}</span>
                </div>
                <div className='my-8 bg-secondary-12 p-4'>
                  <div className='flex flex-col'>
                    <span className='typography-body2Semibold' data-cy='infobox-title'>
                      {t('global.kyc.intro.body.infoBox.headline')}
                    </span>
                    <span className='typography-body2' data-cy='infobox-text'>
                      {t('global.kyc.intro.body.infoBox.text')}
                    </span>
                  </div>
                </div>
              </DialogBody>
              <DialogFooter>
                <Button onClick={() => onChange(false)} variant='outline'>
                  {t('global.ui.buttons.cancel')}
                </Button>
                <Button
                  type='submit'
                  disabled={isSubmitDisabled}
                  loading={isLoading}
                  data-cy='kyc-dialog-submit-button'
                >
                  {t('global.kyc.intro.submit')}
                </Button>
              </DialogFooter>
            </fieldset>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
