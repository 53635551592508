import {
  Button,
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Select,
  SelectContent,
  Stack,
} from 'component-library';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/api/rest/resources/types/plot';
import { FormControl, FormDescription, FormField, FormItem, FormLabel } from '@/components';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

import { TIME_FRAMES } from '../../../hooks/constants';
import { SettingsInput } from '../../../hooks/types';
import { useScenarioForm } from '../../../hooks/useScenarioForm';
import { CustomSelectItem, CustomSelectTrigger } from './CustomSelect';

export const EditSettings = () => {
  const { t } = useTranslation();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        <Button variant='outline' className='border-primary-100 text-info'>
          {t('shared.projects.project.impactProjections.scenarioEditor.editSettings.title')}
        </Button>
      </DialogTrigger>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>{t('shared.projects.project.impactProjections.scenarioEditor.editSettings.title')}</DialogTitle>
        </DialogHeader>
        <Form onSubmit={() => setIsDialogOpen(false)} />
      </DialogContent>
    </Dialog>
  );
};

const Form = (props: { onSubmit: () => void }) => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const plots = usePlotsForProject().data.results;

  const projectItem = useMemo(
    () => ({
      label: t(
        'shared.projects.project.impactProjections.scenarioEditor.editSettings.form.landPortion.defaultLandPortion',
      ),
      value: projectId,
      tooltip: t(
        'shared.projects.project.impactProjections.scenarioEditor.editSettings.form.landPortion.tooltip.project',
      ),
    }),
    [t, projectId],
  );

  const plotItems = useMemo(
    () =>
      plots
        .sort((plot1, plot2) => (plot1.name.toUpperCase() > plot2.name.toUpperCase() ? 1 : -1))
        .map((plot: Plot) => ({
          label: plot.name,
          value: plot.id,
          tooltip: t(
            'shared.projects.project.impactProjections.scenarioEditor.editSettings.form.landPortion.tooltip.plot',
          ),
        })),
    [t, plots],
  );

  const landPortionItems = [projectItem, ...plotItems];

  const timeFrames = useMemo(
    () =>
      Object.keys(TIME_FRAMES).map((timeFrame) => ({
        label: t('shared.projects.project.impactProjections.scenarioEditor.editSettings.form.timeFrame.display', {
          timeFrame,
        }),
        value: timeFrame,
        tooltip: t('shared.projects.project.impactProjections.scenarioEditor.editSettings.form.timeFrame.tooltip', {
          timeFrame,
        }),
      })),
    [t],
  );

  const scenarioForm = useScenarioForm();
  const settingsValues = scenarioForm.getValues();
  const form = useForm<SettingsInput>({
    defaultValues: {
      landPortionId: settingsValues.landPortionId,
      timeFrame: settingsValues.timeFrame,
    },
  });

  const isFormPristine = !form.formState.isDirty;

  const onSubmit = () => {
    props.onSubmit();

    const inputs = form.getValues();
    scenarioForm.reset(inputs);
  };

  return (
    <FormProvider {...form}>
      <DialogBody>
        <form id='edit-settings-form' onSubmit={form.handleSubmit(onSubmit)} data-testid='edit-settings-form'>
          <fieldset data-testid='edit-settings-fieldset'>
            <Stack spacing={8}>
              <FormField
                control={form.control}
                name='landPortionId'
                render={({ field: { ref, value, ...field } }) => (
                  <FormItem className='flex flex-col gap-1'>
                    <FormLabel>
                      {t(
                        'shared.projects.project.impactProjections.scenarioEditor.editSettings.form.landPortion.label',
                      )}
                    </FormLabel>
                    <Select value={value} {...field}>
                      <FormControl>
                        <CustomSelectTrigger
                          ref={ref}
                          value={value}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          label={landPortionItems.find((landPortion) => landPortion.value === value)!.label}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          tooltip={landPortionItems.find((landPortion) => landPortion.value === value)!.tooltip}
                        />
                      </FormControl>
                      <FormDescription>
                        {t(
                          'shared.projects.project.impactProjections.scenarioEditor.editSettings.form.landPortion.helper',
                        )}
                      </FormDescription>
                      <SelectContent>
                        {landPortionItems.map((item) => (
                          <CustomSelectItem key={item.value} {...item} />
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='timeFrame'
                render={({ field: { ref, value, ...field } }) => (
                  <FormItem className='flex flex-col gap-1'>
                    <FormLabel>
                      {t('shared.projects.project.impactProjections.scenarioEditor.editSettings.form.timeFrame.label')}
                    </FormLabel>
                    <Select value={value} {...field}>
                      <FormControl>
                        <CustomSelectTrigger
                          ref={ref}
                          value={value}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          label={timeFrames.find((timeFrame) => timeFrame.value === value)!.label}
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          tooltip={timeFrames.find((timeFrame) => timeFrame.value === value)!.tooltip}
                        />
                      </FormControl>
                      <FormDescription>
                        {t(
                          'shared.projects.project.impactProjections.scenarioEditor.editSettings.form.timeFrame.helper',
                        )}
                      </FormDescription>
                      <SelectContent>
                        {timeFrames.map((item) => (
                          <CustomSelectItem key={item.value} {...item} />
                        ))}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            </Stack>
          </fieldset>
        </form>
      </DialogBody>
      <DialogFooter className='mt-12'>
        <DialogClose asChild>
          <Button variant='text' size='small'>
            {t('global.ui.buttons.cancel')}
          </Button>
        </DialogClose>
        <Button form='edit-settings-form' size='small' type='submit' disabled={isFormPristine}>
          {t('global.ui.buttons.save')}
        </Button>
      </DialogFooter>
    </FormProvider>
  );
};
