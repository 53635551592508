import { Button, RiMailSendLine, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { INVESTMENTS_PORTFOLIO_SUPPORT_EMAIL_ADDRESS_FOR_BUYERS } from '@/config/constants';

import { Card } from '../card/Card';

export const Help = () => {
  const { t } = useTranslation();

  return (
    <Card label={t('buyer.portfolio.help.label')} data-testid='portfolio-help' data-cy='portfolio-help'>
      <Stack spacing={6} className='items-start'>
        <span className='typography-body1' data-cy='portfolio-help-text'>
          {t('buyer.portfolio.help.text')}
        </span>
        <Button leftAdornment={<RiMailSendLine className='h-5 w-5' />} asChild>
          <a href={`mailto:${INVESTMENTS_PORTFOLIO_SUPPORT_EMAIL_ADDRESS_FOR_BUYERS}`} data-cy='portfolio-help-link'>
            {t('buyer.portfolio.help.buttonLabel')}
          </a>
        </Button>
      </Stack>
    </Card>
  );
};
