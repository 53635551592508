import { RiCompass4Line, Stack } from 'component-library';
import { Trans, useTranslation } from 'react-i18next';

import { ChartHeading } from '@/components/Charts/components/ChartHeading';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCard/NCCard';
import { HabitatIntactnessExplainer } from '@/components/NCExplainers/HabitatIntactnessExplainer';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { printMonthYear } from '@/utils/formatting/date';

import { Area, AreaChart, Chart, useTimeseriesXAxisProps } from '../../../components/Chart';
import { FactCapsule } from '../../../components/FactCapsule';
import { useFact } from '../../../hooks/useFact';
import { useLatestAnalysisDatestamp } from '../../../hooks/useLatestAnalysisDatestamp';

export const HabitatIntactnessCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard explainer={<HabitatIntactnessExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.habitatIntactness.evolution.title')} />
      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  const threatsIndexTimeseries = useFact<{ value: Record<string, number> }>('r1_conservation_threats_i_ts');

  const chartData = Object.entries(threatsIndexTimeseries.value).map(([yearString, value]) => ({
    hi: value,
    date: new Date(yearString),
  }));

  const xAxisProps = useTimeseriesXAxisProps(chartData);

  return (
    <Stack center spacing={6}>
      <ChartHeading heading={t('shared.ncaDetail.habitatIntactness.evolution.chartHeading')} />

      <Chart.Container
        data={chartData}
        config={{
          hi: {
            label: t('shared.ncaDetail.habitatIntactness.evolution.tooltipLabel'),
            color: '#C69637',
          },
        }}
      >
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id='hiFill' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor='#CAA982' stopOpacity={0.46} />
              <stop offset='100%' stopColor='#D8C79F' stopOpacity={0.07} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            strokeWidth={3}
            stroke='var(--color-hi)'
            fill='url(#hiFill)'
            fillOpacity={1}
            activeDot={{ fill: 'var(--color-hi)' }}
            dataKey='hi'
            connectNulls
          />
          <Chart.Benchmark y={0.75}>
            <Chart.BenchmarkHorizontalLabel
              // @ts-expect-error position accepts string coordinates
              position={{ x: '99%', y: -8 }}
            >
              {t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.highIntegrity')}
            </Chart.BenchmarkHorizontalLabel>
          </Chart.Benchmark>
          <Chart.Benchmark y={0.9}>
            <Chart.BenchmarkHorizontalLabel
              // @ts-expect-error position accepts string coordinates
              position={{ x: '99%', y: -8 }}
            >
              {t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.exceptionallyHighIntegrity')}
            </Chart.BenchmarkHorizontalLabel>
          </Chart.Benchmark>
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis domain={[0, 1]}>
            <Chart.YAxisLabel>{t('shared.ncaDetail.habitatIntactness.evolution.value')}</Chart.YAxisLabel>
          </Chart.YAxis>

          <Chart.Tooltip content={(props) => <Chart.TooltipContent {...props} labelFormatter={printMonthYear} />} />

          <Chart.Legend content={(props) => <Chart.LegendContent {...props} />} />
        </AreaChart>
      </Chart.Container>
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const currentYear = useFact<number>('r1_conservation_current_year');
  const baselineYear = useFact<number>('r1_conservation_baseline_year');
  const projectBaselineValue = useFact<number>('r1_conservation_threats_i_baseline');
  const projectAverageValue = useFact<number>('r1_conservation_threats_i');

  const totalYears = currentYear.value - baselineYear.value;
  const changePercentage =
    (100 * (projectAverageValue.value - projectBaselineValue.value)) / projectBaselineValue.value;

  const trendExplanation = (() => {
    if (changePercentage === 0) {
      return 'shared.ncaDetail.habitatIntactness.evolution.explanationTrendConstant';
    }

    if (changePercentage > 0) {
      return 'shared.ncaDetail.habitatIntactness.evolution.explanationTrendPositive';
    }

    return 'shared.ncaDetail.habitatIntactness.evolution.explanationTrendNegative';
  })();

  const latestAnalysisDatestamp = useLatestAnalysisDatestamp();

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.habitatIntactness.evolution.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.habitatIntactness.evolution.explainers.habitatIntactness.title'),
            body: t('shared.ncaDetail.habitatIntactness.evolution.explainers.habitatIntactness.body'),
          }}
          supplementaryInfo={latestAnalysisDatestamp}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <FactCapsule
            label={t('shared.ncaDetail.habitatIntactness.evolution.baselinePrevFiveYears', {
              year: baselineYear.value,
            })}
            fact={projectBaselineValue}
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.habitatIntactness.evolution.explainers.baselineValue.title'),
              body: t('shared.ncaDetail.habitatIntactness.evolution.explainers.baselineValue.body', {
                year: baselineYear.value,
              }),
            }}
          />
          <FactCapsule
            label={t('shared.ncaDetail.habitatIntactness.evolution.avgValue', {
              year: currentYear.value,
            })}
            fact={projectAverageValue}
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.habitatIntactness.evolution.explainers.averageValue.title', {
                year: currentYear.value,
              }),
              body: t('shared.ncaDetail.habitatIntactness.evolution.explainers.averageValue.body', {
                year: currentYear.value,
              }),
            }}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={
          <Trans
            i18nKey='shared.ncaDetail.habitatIntactness.evolution.explanation'
            values={{
              trendExplanation: `$t(${trendExplanation}, { "changePercentage": "${getDisplayNumber(
                changePercentage,
                undefined,
                {
                  signDisplay: 'always',
                },
              )}", "totalYears": "${totalYears}" })`,
            }}
          />
        }
      />
    </Stack>
  );
};
