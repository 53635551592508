import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { Button, RiLoopLeftLine, Stack } from 'component-library';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';

import { useContactFormLink } from '@/hooks/useContactFormLink';

import { Heading, SectionCard } from '../../../components';

export const withErrorBoundary = (Wrapped: () => JSX.Element) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const ErrorBoundary = () => {
    const { reset } = useQueryErrorResetBoundary();

    return (
      <ReactErrorBoundary FallbackComponent={ErrorFallback} onReset={reset}>
        <Wrapped />
      </ReactErrorBoundary>
    );
  };

  return ErrorBoundary;
};

const ErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation();
  const contactLink = useContactFormLink();

  return (
    <>
      <Heading>{t('shared.account.titles.team')}</Heading>
      <SectionCard>
        <Stack spacing={6} className='items-center'>
          <span className='typography-h3 text-text-primary'>{t('shared.account.team.errorBoundary.title')}</span>
          <span className='typography-body1 text-center text-text-primary'>
            <Trans
              i18nKey='shared.account.team.errorBoundary.description'
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                a: <a href={contactLink} target='_blank' rel='noopener noreferrer' className='underline' />,
              }}
            />
          </span>
          <Button size='small' leftAdornment={<RiLoopLeftLine />} onClick={resetErrorBoundary}>
            {t('global.ui.buttons.retry')}
          </Button>
        </Stack>
      </SectionCard>
    </>
  );
};
