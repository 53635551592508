import {
  Button,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownItemLink,
  DropdownSeparator,
  DropdownTrigger,
  RiAccountCircleFill,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiErrorWarningFill,
  Stack,
} from 'component-library';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APP_VERSION } from '@/config/constants';
import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { useAuth, useUser } from '@/lib/auth';
import { printFullName } from '@/utils/formatting/user';

import { UserAvatar } from '../UserAvatar';

export const SignedInNavbarAccountDropdown: FC = () => {
  const { t } = useTranslation();
  const user = useUser();
  const { signOutAndInvalidateSession } = useAuth();
  const { accountPath } = useAccountPageRoutes();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Dropdown onOpenChange={(e) => setIsDropdownOpen(e)}>
      <DropdownTrigger asChild data-testid='account-dropdown' data-cy='profil-dropdown-button'>
        <Button
          variant='text'
          size='small'
          rightAdornment={isDropdownOpen ? <RiArrowUpSLine size={20} /> : <RiArrowDownSLine size={20} />}
        >
          <UserAvatar name={printFullName(user)} showWarningBadge={user.email_activated} />
        </Button>
      </DropdownTrigger>
      <DropdownContent align='end'>
        <DropdownHeader />
        <DropdownSeparator />
        <DropdownItemLink
          to={accountPath}
          leftAdornment={<RiAccountCircleFill size={24} />}
          data-cy='profil-dropdown-account-link'
        >
          <span className='typography-body2' data-cy='profile-dropdown-account-text'>
            {t('shared.account.titles.account')}
          </span>
        </DropdownItemLink>
        <DropdownSeparator />
        <DropdownItem onSelect={signOutAndInvalidateSession} data-cy='logout-button'>
          <span className='typography-body2 text-error'>{t('logout.title')}</span>
        </DropdownItem>
      </DropdownContent>
    </Dropdown>
  );
};

const DropdownHeader = () => {
  const { t } = useTranslation();
  const user = useUser();

  return (
    <Stack className='min-w-[200px] gap-2 px-4 py-1.5'>
      <Stack>
        <span className='typography-body1 text-text-primary' data-cy='profil-dropdown-fullname-text'>
          {printFullName(user)}
        </span>
        <span className='typography-body2 text-text-secondary'>{user.membership.organization.name}</span>
      </Stack>
      <Stack className='gap-1'>
        <span className='typography-caption text-text-secondary'>{user.email}</span>
        {!user.email_activated && (
          <Stack direction='row' className='items-center gap-1 text-warning-dark'>
            <RiErrorWarningFill size={18} />
            <span className='typography-caption' data-cy='profil-dropdown-activation-status'>
              {t('navbar.dropdown.emailNotVerifiedLabel')}
            </span>
          </Stack>
        )}
      </Stack>
      <span className='typography-body2 text-xs text-text-disabled'>
        {t('navbar.dropdown.version')}: {APP_VERSION}
      </span>
    </Stack>
  );
};
