import { cn } from 'component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { useBenchmarkForFact } from '@/pages/shared/hooks/useBenchmarkForFact';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { BenchmarkPill } from '../Pill/BenchmarkPill';
import { Area, Chart, ComposedChart, getXAxisProps, TooltipProps } from './components/Chart';
import { ChartLegendBar } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { ChartLegendBarItem } from './components/types';
import { RenderConfidenceInterval } from './ConfidenceInterval';
import { NoChartData } from './NoChartData';
import { extrapolateReferenceLinesChartData, RenderReferenceLine } from './ReferenceLines';
import { ChartProps } from './types';
import { getChartDataFromGraphFact, getXAxisDomainForHistoricGraph, minMaxAxisDomain, sortChartData } from './utils';

const chartColors = {
  bp: { stroke: '#76AC23', fill: ['#5D9900', '#558707'] },
} as const;

const factNames = {
  biodiversity_percent: 'bp',
  biodiversity_buffer_zone_pct_benchmark_low: 'bp_benchmark_low',
  biodiversity_buffer_zone_pct_benchmark_moderate: 'bp_benchmark_moderate',
  biodiversity_buffer_zone_pct_benchmark_high: 'bp_benchmark_high',
};

const unit = formatUnit(UnitEnum['%']);

export const BiodiversityZoneChart: FC<HTMLAttributes<HTMLDivElement> & ChartProps> = ({
  data,
  benchmarks,
  className,
  showTooltip,
  ...delegated
}) => {
  const { t } = useTranslation();
  const styles = cn('flex w-full flex-col', className);

  const dateDataMap = getChartDataFromGraphFact(data, factNames);
  const sortedChartData = sortChartData(dateDataMap, (date) => new Date(date).getTime());

  const firstTick = sortedChartData.at(0);
  const lastTick = sortedChartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  const xTicksDomain = getXAxisDomainForHistoricGraph(firstTick.label, lastTick.label);
  const xAxisProps = getXAxisProps(xTicksDomain);

  let chartData = extrapolateReferenceLinesChartData(xTicksDomain, sortedChartData, 'bp_benchmark_low');
  chartData = extrapolateReferenceLinesChartData(xTicksDomain, chartData, 'bp_benchmark_moderate');
  chartData = extrapolateReferenceLinesChartData(xTicksDomain, chartData, 'bp_benchmark_high');

  return (
    <>
      <Chart.Container
        {...delegated}
        data={chartData}
        config={{
          bp: {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            label: t('global.analysis.protectedOnFarmHabitat'),
            color: chartColors.bp.stroke,
          },
        }}
      >
        <ComposedChart data={chartData}>
          <defs>
            <linearGradient id='bp' x1='0' y1='1' x2='0' y2='0'>
              <stop offset='4.32%' stopColor={chartColors.bp.fill[0]} stopOpacity={0} />
              <stop offset='128.14%' stopColor={chartColors.bp.fill[1]} stopOpacity={0.3} />
            </linearGradient>
          </defs>
          {RenderConfidenceInterval({
            dataKey: 'bp.confidence_interval',
            type: 'monotone',
          })}
          <Area
            type='monotone'
            isAnimationActive={false}
            stroke='var(--color-bp)'
            strokeWidth={2}
            fill='url(#bp)'
            fillOpacity={1}
            dataKey='bp.value'
            connectNulls
            activeDot={ChartTooltipDot}
          />
          {RenderReferenceLine({
            dataKey: 'bp_benchmark_low.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.bp_benchmark_low) ? t('global.benchmark.low') : null,
            infoPopoverProps: {
              body: t('shared.ncaDetail.details.biodiversity.explainers.bpBenchmark.tooltips.low'),
            },
            chartData,
          })}
          {RenderReferenceLine({
            dataKey: 'bp_benchmark_moderate.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.bp_benchmark_moderate) ? t('global.benchmark.moderate') : null,
            infoPopoverProps: {
              body: t('shared.ncaDetail.details.biodiversity.explainers.bpBenchmark.tooltips.moderate'),
            },
            chartData,
          })}
          {RenderReferenceLine({
            dataKey: 'bp_benchmark_high.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.bp_benchmark_high) ? t('global.benchmark.high') : null,
            infoPopoverProps: {
              body: t('shared.ncaDetail.details.biodiversity.explainers.bpBenchmark.tooltips.high'),
            },
            chartData,
          })}
          <Chart.XAxis {...xAxisProps} />
          {/* eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars */}
          <Chart.YAxis domain={([dataMin, dataMax]) => minMaxAxisDomain(0, 1)([0, Math.min(100, 40 + dataMax)])}>
            <Chart.Label>{formatUnit(UnitEnum['%'])}</Chart.Label>
          </Chart.YAxis>
          {showTooltip && <Chart.Tooltip content={(props) => <CustomTooltip {...props} benchmarks={benchmarks} />} />}
        </ComposedChart>
      </Chart.Container>
      <CustomLegendBar />
    </>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.protectedOnFarmHabitat'),
      color: chartColors.bp.fill[0],
    },
  ];

  return (
    <ChartLegendBar items={legendBarItems} className='sm:ml-[28px]' data-testid='biodiversity-zone-chart-legend' />
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
  benchmarks,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TooltipProps<ValueType, NameType> & { benchmarks?: any[] }) => {
  const { t } = useTranslation();

  const bpValue = payload?.find((data) => data.dataKey === 'bp.value');
  const bpDisplay =
    bpValue?.value != null
      ? `${getDisplayNumber(bpValue?.value as string, window.navigator.language)} ${unit}`
      : t('global.analysis.noData');
  const bpBenchmarkType = useBenchmarkForFact(benchmarks, bpValue?.value as string);

  if (!active) return null;

  return (
    <ChartTooltip>
      {bpBenchmarkType && (
        <ChartTooltipTextPrimary className='mt-1'>
          <BenchmarkPill benchmarkType={bpBenchmarkType} />
        </ChartTooltipTextPrimary>
      )}
      <ChartTooltipTextPrimary>{`${t(
        'global.analysis.protectedOnFarmHabitat',
      )}: ${bpDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printMonthYear(label)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
