import { centerOfMass } from '@turf/turf';
import { Stack } from 'component-library';
import { FC, PropsWithChildren } from 'react';
import { Popup } from 'react-map-gl';

import { Plot } from '@/api/rest/resources/types/plot';

export type MapPopupProps = PropsWithChildren<{
  plot: Plot;
  onClose?: () => void;
}>;

export const MapPopup: FC<MapPopupProps> = ({ plot, onClose, ...delegated }) => {
  const centroid = centerOfMass(plot.polygon).geometry.coordinates as [number, number];
  return (
    <Popup
      anchor='left'
      data-testid={`popup-${plot.id}`}
      longitude={centroid[0]}
      latitude={centroid[1]}
      closeButton={false}
      onClose={onClose}
      maxWidth='none'
      offset={12}
      {...delegated}
    />
  );
};

export type MapPopupContentProps = PropsWithChildren;

export const MapPopupContent: FC<MapPopupContentProps> = ({ children }) => {
  return (
    <Stack
      direction='row'
      className='relative items-center animate-in fade-in slide-in-from-left-2'
      data-testid='map-popup-content'
    >
      {/* Pointer arrow */}
      <div className='absolute left-0 h-6 w-6 translate-x-4 rotate-45 rounded bg-white-100' />

      <Stack className='absolute left-6 w-[430px] max-w-[90vw] rounded-2xl bg-white-100' spacing={6}>
        {children}
      </Stack>
    </Stack>
  );
};
