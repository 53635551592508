import {
  Alert,
  AlertBody,
  AlertIcon,
  Button,
  ButtonLink,
  RiInformationFill,
  Shimmer,
  Stack,
  toast,
  toastifyToast,
} from 'component-library';
import { FC, PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { RestApiBadRequestServerError } from '@/api/rest/resources/errors/RestApiBadRequestError';
import { User } from '@/api/rest/resources/types/user';
import { Form } from '@/components';
import { useAccountPageRoutes } from '@/hooks/useAccountPageRoutes';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useUser } from '@/lib/auth';
import { Heading, SectionCard, SectionCardHeading } from '@/pages/shared/account/components';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';

import { withErrorBoundary } from './components/ErrorBoundary';
import { FormContent } from './components/FormContent';
import { EditMemberInputs, useEditTeamMember } from './hooks/useEditTeamMember';
import { useGetUser } from './hooks/useGetUser';

// NOTE: The form field names must match the api param names to make useRestApiServerErrorHandling work
const formFields = ['user.firstname', 'user.lastname', 'user.email', 'role'] as (keyof EditMemberInputs)[];

export const AccountTeamEditMemberPage = withErrorBoundary(() => {
  const { memberId } = useParams<{ memberId: string }>();

  if (!memberId) {
    throw new Error('AccountTeamEditMemberPage requires "memberId" param');
  }

  const { t } = useTranslation();
  const { accountTeamPath } = useAccountPageRoutes();

  const member = useGetUser(memberId).data;

  return (
    <>
      <Heading backPath={accountTeamPath}>{t('shared.account.teamEditMember.labels.title')}</Heading>
      {member ? <ReadyState member={member} /> : <LoadingState />}
    </>
  );
});

const ReadyState: FC<{ member: User }> = ({ member }) => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';
  const { accountTeamPath } = useAccountPageRoutes();

  const { id: currentUserId } = useUser();
  const { submit, isSubmitting } = useEditTeamMember();

  const form = useForm<EditMemberInputs>({
    defaultValues: {
      user: {
        firstname: member.firstname,
        lastname: member.lastname,
        email: member.email,
      },
      role: member.membership.role,
    },
  });
  const handleServerError = useRestApiServerErrorHandling(form.setError, formFields);

  const submitEditMember = async (inputs: EditMemberInputs) => {
    try {
      toastifyToast.clearWaitingQueue();
      toastifyToast.dismiss();

      await submit(member, inputs);

      toast({
        title: t('shared.account.teamEditMember.toasts.success.title'),
        type: 'success',
      });
    } catch (error) {
      if (error instanceof RestApiBadRequestServerError) {
        handleServerError(error);
        return;
      }

      toast({
        title: t('shared.account.teamEditMember.toasts.error.title'),
        description: t('shared.account.teamEditMember.toasts.error.description'),
        type: 'error',
        autoClose: 10_000,
      });
    }
  };

  const isCurrentUser = member.id === currentUserId;

  const isEditable = !isCurrentUser;
  const isFormDisabled = isSubmitting || !isEditable;

  const { user, role } = form.watch();

  const isFormSubmittable = form.formState.isDirty && !!user.firstname && !!user.lastname && !!user.email && !!role;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submitEditMember)} className='flex flex-col gap-6 md:gap-9'>
        <fieldset disabled={isFormDisabled} data-testid='account-team-edit-member-fieldset'>
          <SectionCardWithHeading>
            {!isEditable && <NonEditableAlert />}
            <Stack spacing={6} className='max-w-full sm:max-w-[220px]'>
              <FormContent form={form} isDisabled={isFormDisabled} />
            </Stack>
          </SectionCardWithHeading>
        </fieldset>
        <Stack direction='row' spacing={4} className='justify-end'>
          <ButtonLink to={accountTeamPath} variant='text' size={isLargeScreen ? 'medium' : 'small'}>
            {t('global.ui.buttons.cancel')}
          </ButtonLink>
          <Button
            type='submit'
            size={isLargeScreen ? 'medium' : 'small'}
            disabled={!isFormSubmittable}
            loading={isSubmitting}
          >
            {t('shared.account.teamEditMember.labels.submitButton')}
          </Button>
        </Stack>
      </form>
    </Form>
  );
};

const LoadingState: FC = () => (
  <SectionCardWithHeading>
    <Stack spacing={6} className='max-w-full sm:max-w-[220px]'>
      <Stack spacing={1}>
        <Shimmer className='h-5 w-24' />
        <Shimmer className='h-12 w-full' />
      </Stack>
      <Stack spacing={1}>
        <Shimmer className='h-5 w-24' />
        <Shimmer className='h-12 w-full' />
      </Stack>
      <Stack spacing={1}>
        <Shimmer className='h-5 w-28' />
        <Shimmer className='h-12 w-full' />
      </Stack>
      <Stack spacing={1}>
        <Shimmer className='h-5 w-32' />
        <Shimmer className='h-12 w-full' />
      </Stack>
    </Stack>
  </SectionCardWithHeading>
);

const SectionCardWithHeading: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  return (
    <SectionCard>
      <SectionCardHeading>{t('shared.account.teamEditMember.labels.cardHeading')}</SectionCardHeading>
      {children}
    </SectionCard>
  );
};

const NonEditableAlert: FC = () => {
  const { t } = useTranslation();

  return (
    <Alert className='mb-6 flex flex-row'>
      <AlertIcon>
        <RiInformationFill size={24} />
      </AlertIcon>
      <AlertBody className='typography-h4 text-text-primary'>
        {t('shared.account.teamEditMember.nonEditableAlertMessage')}
      </AlertBody>
    </Alert>
  );
};
