import { Loader } from 'component-library';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from '@/lib/auth';

export const RequireBoot: React.FC = () => {
  const { isBooted } = useAuth();

  if (!isBooted) return <Loader />;

  return <Outlet />;
};
