import { Drawer } from '@mui/material';
import {
  AppBar,
  AppBarContent,
  ButtonLink,
  cn,
  IconButton,
  RiCheckLine,
  RiCloseLine,
  RiMenuLine,
  Shimmer,
  Stack,
} from 'component-library';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SignedInNavbarMenu } from '@/components/SignedInNavbar/Menu';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { useMenuItems } from '@/pages/landsteward/layouts/hooks/useMenuItems';
import { paths } from '@/routing';

export type CurrentStep = 1 | 2 | 3 | 'complete';

export const TitleBar = ({ currentStep }: { currentStep: CurrentStep }) => {
  const { t } = useTranslation();

  const isLargeScreen = useScreenSize() === 'large';

  const project = useProject().data;

  return (
    <AppBar data-testid='new-plot-titlebar' data-cy='new-plot-titlebar'>
      <AppBarContent className='justify-between'>
        <Stack className='min-w-0'>
          <span className='typography-h4' data-cy='title'>
            {t('landSteward.plot.new.titleBar.title')}
          </span>
          <Shimmer className='h-5 min-w-[100px] max-w-full truncate' animate={!project.name}>
            <span className='typography-overline text-primary-100' data-cy='project-name'>
              {project.name}
            </span>
          </Shimmer>
        </Stack>
        <Stack direction='row' className='mx-7 items-center justify-center md:flex-1'>
          <Stepper currentStep={currentStep} />
        </Stack>
        {isLargeScreen ? (
          currentStep !== 'complete' && (
            <ButtonLink
              to={paths.landSteward.projectDetails.replace(':projectId', project.id)}
              data-cy='cancel-button'
              variant='text'
              rightAdornment={<RiCloseLine />}
            >
              {t('global.ui.buttons.cancel')}
            </ButtonLink>
          )
        ) : (
          <Stack direction='row' className='items-center'>
            {currentStep !== 'complete' && (
              <IconButton asChild>
                <Link to={paths.landSteward.projectDetails.replace(':projectId', project.id)}>
                  <RiCloseLine size={24} className='flex-shrink-0' />
                </Link>
              </IconButton>
            )}
            <DrawerMenu />
          </Stack>
        )}
      </AppBarContent>
    </AppBar>
  );
};

type StepperVariant = 'current' | 'pending' | 'complete';

type StepperProps = { currentStep: CurrentStep };

const Stepper = ({ currentStep }: StepperProps) => {
  const isLargeScreen = useScreenSize() === 'large';
  const { t } = useTranslation();

  return (
    <Stack direction='row' spacing={4} className='max-w-3xl items-center md:flex-1'>
      <StepperStep step={1} currentStep={currentStep} label={t('landSteward.plot.new.titleBar.step1')} />
      {isLargeScreen && <StepperDivider />}
      <StepperStep step={2} currentStep={currentStep} label={t('landSteward.plot.new.titleBar.step2')} />
      {isLargeScreen && <StepperDivider />}
      <StepperStep step={3} currentStep={currentStep} label={t('landSteward.plot.new.titleBar.step3')} />
    </Stack>
  );
};

type StepperStepProps = { step: number; currentStep: StepperProps['currentStep']; label: string };

const StepperStep = ({ step, currentStep, label }: StepperStepProps) => {
  const isLargeScreen = useScreenSize() === 'large';
  const variant = getStepperVariant(step, currentStep);
  const bgColor = {
    'bg-primary-30': variant === 'pending',
    'bg-success': variant === 'complete',
  };

  if (isLargeScreen)
    return (
      <Stack direction='row' spacing={2} className='items-center' data-cy={`step-${step}`}>
        <Stack
          data-cy='number'
          className={cn(
            'h-6 w-6 items-center justify-center rounded-full',
            'typography-body2 bg-primary-100 text-white-100',
            bgColor,
          )}
        >
          {variant === 'complete' ? <RiCheckLine /> : step}
        </Stack>
        <span className={cn('typography-button2', variant === 'pending' && 'text-text-disabled')} data-cy='label'>
          {label}
        </span>
      </Stack>
    );

  return variant === 'current' ? (
    <Stack
      className={cn(
        'h-6 w-6 items-center justify-center rounded-full',
        'typography-body2 bg-primary-100 text-white-100',
      )}
    >
      {step}
    </Stack>
  ) : (
    <div className={cn('h-3 w-3 rounded-full', bgColor)} />
  );
};

const StepperDivider = () => {
  return <div className='h-[1px] flex-1 bg-outlined-border-23p' data-cy='step-divider' />;
};

const getStepperVariant = (step: number, currentStep: StepperProps['currentStep']): StepperVariant => {
  if (currentStep === 'complete' || step < currentStep) return 'complete';

  if (step === currentStep) return 'current';

  return 'pending';
};

const DrawerMenu = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const menuItems = useMenuItems();

  return (
    <>
      <IconButton onClick={handleOpenDrawer}>
        <RiMenuLine size={24} />
      </IconButton>
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{ sx: { minWidth: '320px', maxWidth: '90vw' } }}
      >
        <SignedInNavbarMenu closeDrawer={handleCloseDrawer} items={menuItems} />
      </Drawer>
    </>
  );
};
