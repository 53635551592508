import { ButtonLink, Container, RiCheckboxCircleLine, Stack } from 'component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Footer } from '@/components';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';

import { TitleBar } from '../../components/TitleBar';

export const EditPlotSuccess = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();

  const location = useLocation();
  const successPageVariant = location.state?.successPageVariant;

  const header = useMemo(() => {
    if (successPageVariant === 'draft') return t('landSteward.plot.success.draw.draft.heading');

    return t('landSteward.plot.success.draw.heading');
  }, [successPageVariant, t]);

  const description = useMemo(() => {
    if (successPageVariant === 'draft') return t('landSteward.plot.success.draw.draft.description');

    return t('landSteward.plot.success.draw.description');
  }, [successPageVariant, t]);

  return (
    <Stack className='min-h-screen' data-testid='edit-plot-success'>
      <TitleBar title={t('landSteward.plot.edit.editPlot')} />
      <Stack centerCross className='flex-1'>
        <Container contentWidth='sm'>
          <Stack centerMain className='rounded-3xl bg-white-100 p-12 text-center'>
            <RiCheckboxCircleLine size={40} className='mb-1 text-success' />
            <h1 className='typography-display3 mb-6'>{header}</h1>
            <span className='typography-body1 mb-6'>{description}</span>
            <Stack direction='row' className='flex-1 justify-end'>
              <ButtonLink to={buildPath(paths.landSteward.plots, { pathParams: { projectId } })}>
                {t('landSteward.plot.success.labels.goToPlotList')}
              </ButtonLink>
            </Stack>
          </Stack>
        </Container>
      </Stack>

      <Footer />
    </Stack>
  );
};
