import React from 'react';

import { AccountStatus } from '../../../status/status';

type SuccessProps = {
  variant: 'success';
  label: string;
  // must be defined because otherwise typescript can not resolve prop type for link
  link?: void;
};

type WarningProps = {
  variant: 'warning';
  label: string;
  link?: {
    label: string;
    onClick: () => void;
  };
};

export type InformationBoxEntryStatusProps = SuccessProps | WarningProps;

export const InformationBoxEntryStatus: React.FC<InformationBoxEntryStatusProps> = ({
  variant,
  label,
  link,
  ...props
}) => {
  return (
    <AccountStatus variant={variant} {...props}>
      <span>{label}</span>
      {variant === 'warning' && link && (
        <button className='pl-0.5 underline' onClick={link.onClick}>
          {link.label}
        </button>
      )}
    </AccountStatus>
  );
};
