import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import { Button, ButtonProps, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { useCalcAvailableViewportHeight } from '@/hooks/useCalcAvailableViewportHeight';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { usePlotId } from '../../../../../../../shared/hooks/usePlotId';
import { PlotBoundaryEditor } from '../../../../components/PlotBoundaryEditor';
import { usePlotForm } from '../../../../hooks/usePlotForm';
import { TitleBar } from '../../components/TitleBar';

export const EditPlotBoundaryPage = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const { availableHeight } = useCalcAvailableViewportHeight();

  const form = usePlotForm();

  const [plotType, plotName, plotPolygon] = form.watch(['plotType', 'name', 'polygon']);

  const plotId = usePlotId();

  const plots = usePlotsForProject().data.results;

  if (!plotName) {
    throw Error('No plot name received.');
  }

  if (!plotType) {
    throw Error('No plot type received.');
  }

  if (!plotPolygon) {
    throw Error('No plot polygon received.');
  }

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  return (
    <Stack style={{ height: `${availableHeight}px` }}>
      <TitleBar title={t('landSteward.plot.edit.editPlotBoundaries')} />

      <PlotBoundaryEditor
        existingPlots={plots.filter((plot) => plot.id !== plotId)}
        ForwardButton={(props: ButtonProps) => (
          <Button data-testid='save-button' asChild {...props}>
            <Link
              to={buildPath(paths.landSteward.editPlot, {
                pathParams: {
                  plotId,
                  projectId,
                },
              })}
            >
              {t('global.ui.buttons.save')}
            </Link>
          </Button>
        )}
        BackButton={(props: ButtonProps) => (
          <Button variant='outline' asChild {...props}>
            <Link to={buildPath(paths.landSteward.editPlot, { pathParams: { plotId, projectId } })}>
              {t('global.ui.buttons.back')}
            </Link>
          </Button>
        )}
      />
    </Stack>
  );
};
