import { Layer } from 'mapbox-gl';
import { useEffect } from 'react';
import { FillLayer, useMap } from 'react-map-gl';

import { useProjectCells } from '../../../hooks/useProjectPolygon';
import {
  CELLS_FILL_LAYER_ID,
  CELLS_OUTLINE_LAYER_ID,
  observationMarkerLayers,
  PROJECT_CELLS_SOURCE_ID,
} from '../constants';
import { useControlsForm } from './useControlsForm';

/**
 * List of interactive layers based on the currently selected filter options.
 */
export const useInteractiveLayers = (controlsForm: ReturnType<typeof useControlsForm>) => {
  const { data: projectCells } = useProjectCells();

  const [indicatorLayer] = controlsForm.watch(['indicatorLayer']);

  const interactiveLayers = new Set<string>();

  if (projectCells) {
    interactiveLayers.add(CELLS_FILL_LAYER_ID);
    interactiveLayers.add(CELLS_OUTLINE_LAYER_ID);
  }

  if (indicatorLayer === 'floraFauna') {
    observationMarkerLayers.forEach((layer) => {
      interactiveLayers.add(layer);
    });
  }

  return Array.from(interactiveLayers);
};

/**
 * Adjusts the z-ordering of the clickable cell layers such that they are on top
 * of the layers of the specified source.
 */
export const useMoveInteractiveLayersToTop = (source: Layer['source']) => {
  const mapRef = useMap();

  useEffect(() => {
    setTimeout(() => {
      const map = mapRef.current;

      if (!map) {
        return;
      }

      const allLayers = map.getStyle().layers;

      const interactiveCellLayers = allLayers.filter(
        (layer) => (layer as FillLayer).source === PROJECT_CELLS_SOURCE_ID,
      );

      const layersForSource = allLayers.filter((layer) => (layer as FillLayer).source === source);

      const [topmostLayer] = layersForSource;

      if (!topmostLayer?.id) {
        return;
      }

      interactiveCellLayers
        .reverse()
        // @ts-ignore
        .forEach((interactiveLayer) => map.moveLayer(topmostLayer.id, interactiveLayer.id));
    }, 0);
  });
};
