import { Stack } from 'component-library';
import React, { PropsWithChildren } from 'react';

import { OnboardingPageHeading, OnboardingPagePaper } from '@/layout/OnboardingPage';

interface ActivateAccountPageContentProps extends PropsWithChildren {
  headlineText: string;
  dataCy?: string;
}

export const ActivateAccountPageContent: React.FC<ActivateAccountPageContentProps> = ({
  headlineText,
  children,
  dataCy,
}) => {
  return (
    <>
      <OnboardingPageHeading headlineText={headlineText} dataCy={dataCy} />
      <OnboardingPagePaper>
        <Stack className='items-center' spacing={3}>
          {children}
        </Stack>
      </OnboardingPagePaper>
    </>
  );
};
