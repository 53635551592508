import { Heading } from 'deprecated-component-library';
import { useTranslation } from 'react-i18next';

import { useScreenSize } from '@/hooks/useScreenSize';

import { DesktopView } from './components/DesktopView';
import { MobileView } from './components/MobileView';

export const AccountLegalPage = () => {
  const { t } = useTranslation();

  const isLargeScreen = useScreenSize() === 'large';

  return (
    <>
      <Heading variant='h5' mb={3} data-cy='legal-headline'>
        {t('shared.account.titles.legal')}
      </Heading>
      {isLargeScreen ? <DesktopView /> : <MobileView />}
    </>
  );
};
