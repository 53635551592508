import { useParams } from 'react-router-dom';

import { PlotReportFactLabelFilter, R1FactType } from '@/api/rest/resources/types/fact';

import { useDashboardByProjectId } from './useDashboardByProjectId';
import { filterValidLabels, getFact } from './utils';

/**
 * @param projectId Falls back to the projectId in the URL if no value is provided.
 */
export const useProjectFact = <T = unknown>(
  type: R1FactType,
  projectIdArg?: string | null,
  labelFilters?: PlotReportFactLabelFilter,
) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  if (!projectId) {
    throw new Error('Missing URL param: projectId');
  }

  const plotReportFactLabelFilters = labelFilters ? filterValidLabels(labelFilters) : undefined;
  const dashboardData = useDashboardByProjectId(projectId, plotReportFactLabelFilters).data;

  return getFact<T>(dashboardData, type, plotReportFactLabelFilters);
};
