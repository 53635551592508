import { ProjectType } from '@/api/rest/resources/types/project';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

import { ConservationProjectPage } from './pages/conservation/ConservationProjectPage';
import { SustainableProductionProjectPage } from './pages/sustainableProduction/SustainableProductionProjectPage';

export const ProjectPage = () => {
  const project = useProjectDetailById().data;

  if (project.type === ProjectType.conservation) {
    return <ConservationProjectPage />;
  }

  return <SustainableProductionProjectPage />;
};
