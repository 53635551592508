import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import {
  FactsByFactType,
  GET_BUYER_DASHBOARD_PLOT_REPORTS_PATH,
  GET_DASHBOARD_PLOT_REPORTS_PATH,
  GetBuyerDashboardPlotReportsErrorDto,
  GetBuyerDashboardPlotReportsRequestPathVariables,
  GetBuyerDashboardPlotReportsRequestQueryParams,
  GetBuyerDashboardPlotReportsSuccessDto,
  GetDashboardPlotReportsErrorDto,
  GetDashboardPlotReportsRequestPathVariables,
  GetDashboardPlotReportsRequestQueryParams,
  GetDashboardPlotReportsSuccessDto,
  PlotReport,
} from '@/api/rest/resources/types/dashboard';
import { PlotReportFactElement } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useMembershipType } from '@/hooks/useMembershipType';

type PathVariables = GetDashboardPlotReportsRequestPathVariables | GetBuyerDashboardPlotReportsRequestPathVariables;
type QueryParams = GetDashboardPlotReportsRequestQueryParams | GetBuyerDashboardPlotReportsRequestQueryParams;
type SuccessDto = GetDashboardPlotReportsSuccessDto | GetBuyerDashboardPlotReportsSuccessDto;
type ErrorDto = GetDashboardPlotReportsErrorDto | GetBuyerDashboardPlotReportsErrorDto;

export type ReportByPlot = Record<string, FactsByFactType>;

export const getPlotReportsForProject = async ({
  pathVariables,
  queryParams,
  membershipType,
}: {
  pathVariables: PathVariables;
  queryParams: QueryParams;
  membershipType: MembershipWithOrganizationTypeEnum;
}) => {
  const factsReducer = (acc: FactsByFactType, curr: PlotReportFactElement) => ({ ...acc, [curr.name]: curr });

  const reportsReducer = (acc: ReportByPlot, curr: PlotReport) => ({
    ...acc,
    [curr.plot_id]: curr.facts.reduce(factsReducer, {} as FactsByFactType),
  });
  return (
    await getRequest<SuccessDto>(
      membershipType === MembershipWithOrganizationTypeEnum.land_steward
        ? GET_DASHBOARD_PLOT_REPORTS_PATH
        : GET_BUYER_DASHBOARD_PLOT_REPORTS_PATH,
      pathVariables,
      queryParams,
      undefined,
      /** When params are sent in form of arrays, i.e. - same param key used to send multiple values
       * axios has a default config to index these repeated params (https://github.com/axios/axios/tree/v1.0.0#request-config)
       * this creates the final url to be generated as - api/?id[]=1&id[]=2&id[]=3
       * which is accepted by most servers, but BE fails to serialize this correctly (django-style)
       * Hence we explicitly set the indexing to null to create the url BE accepts - api/?id=1&id=2&id=3
       */
      {
        paramsSerializer: {
          indexes: null,
        },
      },
    )
  ).plot_reports.reduce(reportsReducer, {} as ReportByPlot);
};

export const usePlotReportsForProject = ({
  pathVariables,
  queryParams,
}: {
  pathVariables: PathVariables;
  queryParams: QueryParams;
}) => {
  const membershipType = useMembershipType();
  return useSuspenseQuery<ReportByPlot, ErrorDto>({
    queryKey: [membershipType, 'dashboard', 'plot-reports', pathVariables, queryParams],
    queryFn: () => getPlotReportsForProject({ pathVariables, queryParams, membershipType }),
    staleTime: 1000 * 60 * 5,
  });
};
