import { ButtonLink, Container, RiCheckboxCircleLine, Stack } from 'component-library';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { usePlotForm } from '../../../hooks/usePlotForm';
import { PlotCreationType } from '../../../types';
import { TitleBar } from '../components/TitleBar';

export const SuccessPage = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();

  const form = usePlotForm();

  /**
   * We save this as a ref since we reset
   * the form when the component mounts.
   */
  const plotCreationType = useRef(form.getValues().type).current;

  // eslint-disable-next-line prefer-arrow-callback
  useEffect(function resetFormOnMount() {
    form.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!projectId) {
    throw new UnexpectedMissingDataError({ dataLabel: 'projectId' });
  }

  if (!plotCreationType) return <Navigate to={buildPath(paths.landSteward.newPlot, { pathParams: { projectId } })} />;

  return (
    <Stack className='min-h-screen items-center' data-testid='create-plot-success-page'>
      <TitleBar currentStep='complete' />

      <Container className='flex-1'>
        <Stack className='justify-center'>
          <Stack className='mx-auto w-full max-w-2xl grow-0 rounded-3xl bg-white-100 p-12 text-center'>
            <Stack spacing={4} className='mb-12'>
              <Stack spacing={1} className='items-center p-2'>
                <RiCheckboxCircleLine className='fill-success' size={40} />
                <Heading />
              </Stack>
              <Description />
            </Stack>

            <Stack className='justify-between gap-4 md:flex-row'>
              <ButtonLink
                to={buildPath(paths.landSteward.newPlot, { pathParams: { projectId } })}
                variant='outline'
                data-cy='add-more-plots'
              >
                {t('landSteward.plot.success.labels.addMorePlots')}
              </ButtonLink>
              <ButtonLink
                to={buildPath(paths.landSteward.plots, { pathParams: { projectId } })}
                data-cy='view-plot-list'
              >
                {t('landSteward.plot.success.labels.goToPlotList')}
              </ButtonLink>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};

const Heading = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const successPageVariant = location.state?.successPageVariant;

  const form = usePlotForm();

  /**
   * We save this as a ref since we reset the
   * form when the parent component mounts.
   */
  const plotCreationType = useRef(form.getValues().type).current;

  return (
    <h1 className='typography-display3 text-primary-100' data-cy='success-title'>
      {(() => {
        if (plotCreationType === PlotCreationType.UPLOAD) return t('landSteward.plot.success.upload.heading');

        if (successPageVariant === 'draft') return t('landSteward.plot.success.draw.draft.heading');

        return t('landSteward.plot.success.draw.heading');
      })()}
    </h1>
  );
};

const Description = () => {
  const { t } = useTranslation();

  const location = useLocation();
  const successPageVariant = location.state?.successPageVariant;

  const form = usePlotForm();

  /**
   * We save this as a ref since we reset the
   * form when the parent component mounts.
   */
  const plotCreationType = useRef(form.getValues().type).current;

  return (
    <p className='whitespace-pre-wrap' data-cy='success-desc'>
      {(() => {
        if (plotCreationType === PlotCreationType.UPLOAD) return t('landSteward.plot.success.upload.description');

        if (successPageVariant === 'draft') return t('landSteward.plot.success.draw.draft.description');

        return t('landSteward.plot.success.draw.description');
      })()}
    </p>
  );
};
