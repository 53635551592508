import { Area, AreaProps } from 'recharts';

import { SHARED_CHART_COLORS } from './constants';

export type ConfidenceIntervalProps = Omit<AreaProps, 'ref'>;

export const RenderConfidenceInterval = ({ fill, ...props }: ConfidenceIntervalProps) => {
  return (
    <Area
      isAnimationActive={false}
      strokeWidth={0}
      fill={fill ?? SHARED_CHART_COLORS.confidenceIntervals.fill}
      activeDot={false}
      connectNulls
      {...props}
    />
  );
};
