import { CellContext } from '@tanstack/react-table';
import {
  Alert,
  AlertBody,
  Button,
  ButtonLink,
  RiErrorWarningFill,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from 'component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { DraftPlot, Plot, PlotStatusEnum, PlotType } from '@/api/rest/resources/types/plot';
import { PlotTypeIcon } from '@/components';
import { PlotThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { Logger } from '@/lib/logs/logger';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';
import { squareMetersToHectares } from '@/utils/plot';
import {
  getColorOfPlotStatusNotice,
  getTextColorOfPlotStatusNotice,
} from '@/utils/plot/get-color-of-plot-status/getColorOfPlotStatus';

import { useProjectDetailById } from '../../../../../../shared/hooks/useProjectDetailById';
import { useProjectId } from '../../../../../../shared/hooks/useProjectId';

const maxDisplayCropCount = 3;

export const NameCell = ({ row }: CellContext<DraftPlot, unknown>) => {
  const plot = row.original;

  return (
    <Stack direction='row' centerMain spacing={3}>
      {'status' in plot && plot.status === PlotStatusEnum.invalid ? (
        <span className='flex h-12 w-12 shrink-0 items-center justify-center rounded-lg bg-bg-light-grey'>
          <RiErrorWarningFill size={20} className='text-error' data-testid='alert-icon' />
        </span>
      ) : (
        <PlotThumbnail plot={plot} className='h-12 w-12 shrink-0 rounded-lg' />
      )}
      <span className='max-w-full truncate'>{plot.name}</span>
    </Stack>
  );
};

export const AreaCell = ({ row }: CellContext<Plot, unknown> | CellContext<DraftPlot, unknown>) => {
  const plot = row.original;
  const isValid = 'status' in plot ? plot.status !== PlotStatusEnum.invalid : true;

  const areaInHectares = getDisplayNumber(squareMetersToHectares(plot.area), window.navigator.language);

  return <span className='whitespace-nowrap'>{isValid ? `${areaInHectares} ha` : '--'}</span>;
};

export const PlotTypeCell = ({ row }: CellContext<Plot, unknown>) => {
  const plot = row.original;
  const { t } = useTranslation();

  const plotTypeHint = useMemo(() => {
    const hasCrops = plot.type === PlotType.CROPLAND && plot.crops && plot.crops.length > 0;
    const crops = plot.crops
      .slice(0, maxDisplayCropCount)
      .map((crop) => t(`global.crops.${crop}`))
      .sort()
      .join(', ');
    return hasCrops ? t('shared.plots.tooltips.cropland', { crops }) : t(`global.plotTypes.${plot.type}`);
  }, [t, plot.type, plot.crops]);
  return (
    <Tooltip>
      <TooltipTrigger>
        <PlotTypeIcon type={plot.type} size={24} />
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='top'>
        {plotTypeHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

export const PlotNotice = (plot: Plot) => {
  const { t } = useTranslation();
  const membershipType = useMembershipType();
  const { pathname } = useLocation();

  const projectId = useProjectId();
  const projectDetail = useProjectDetailById().data;
  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');
  const plotStatus = plot.status;

  const noticeLabel = () => {
    const exhaustivenessCheck = (status: never) => {
      Logger.error(`No notices defined for plot status "${status}"`);
      return null;
    };

    switch (plotStatus) {
      case PlotStatusEnum.invalid:
        if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
          return `${t('shared.plots.plotNotice.invalidPlot.info')} ${t(
            'shared.plots.plotNotice.invalidPlot.action.info',
          )}`;
        }
        return t('shared.plots.plotNotice.invalidPlot.info');
      case PlotStatusEnum.new_plot:
        return t('shared.plots.plotNotice.newPlot.info');
      case PlotStatusEnum.draft:
        return t('shared.plots.plotNotice.draftPlot.info');
      case PlotStatusEnum.analysed:
      case PlotStatusEnum.calculating:
      case PlotStatusEnum.ready_to_analyse:
      case PlotStatusEnum.scheduled_for_analysis:
        return null;
      default:
        return exhaustivenessCheck(plotStatus);
    }
  };

  const actionLabel = () => {
    const exhaustivenessCheck = (status: never) => {
      Logger.error(`No actions defined for plot status "${status}"`);
      return null;
    };

    switch (plotStatus) {
      case PlotStatusEnum.invalid:
        return t('shared.plots.plotNotice.invalidPlot.action.label');
      case PlotStatusEnum.new_plot:
        return t('shared.plots.plotNotice.newPlot.action.label');
      case PlotStatusEnum.draft:
        return t('shared.plots.plotNotice.draftPlot.action.label');
      case PlotStatusEnum.analysed:
      case PlotStatusEnum.calculating:
      case PlotStatusEnum.ready_to_analyse:
      case PlotStatusEnum.scheduled_for_analysis:
        return null;
      default:
        return exhaustivenessCheck(plotStatus);
    }
  };
  return (
    <Alert
      className='max-w-[600px] overflow-visible p-0'
      style={{
        backgroundColor: getColorOfPlotStatusNotice(plotStatus),
        color: getTextColorOfPlotStatusNotice(plotStatus),
      }}
      data-testid='plot-list-action-notice'
    >
      <AlertBody className='flex h-12 min-w-0 flex-row items-center justify-between gap-2'>
        <span className='flex-1 truncate'>{noticeLabel()}</span>
        {membershipType === MembershipWithOrganizationTypeEnum.land_steward &&
          (isProjectEditable ? (
            <ButtonLink
              data-testid='plot-list-action-notice-cta'
              variant='text'
              className='typography-button2 px-2 py-2'
              style={{
                color: getTextColorOfPlotStatusNotice(plotStatus),
              }}
              state={{
                previousPath: pathname,
              }}
              onClick={(e) => e.stopPropagation()}
              to={buildPath(paths.landSteward.editPlot, { pathParams: { projectId, plotId: plot.id } })}
              preventScrollReset
            >
              {actionLabel()}
            </ButtonLink>
          ) : (
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  data-testid='plot-list-action-notice-cta'
                  disabled
                  variant='text'
                  className='typography-button2 px-2 py-2'
                  style={{
                    color: getTextColorOfPlotStatusNotice(plotStatus),
                  }}
                >
                  {actionLabel()}
                </Button>
              </TooltipTrigger>
              <TooltipContent sideOffset={5} side='bottom'>
                {t('shared.projects.plot.tooltips.disabledEditOrDeletePlot')}
                <TooltipArrow className='fill-white-100' />
              </TooltipContent>
            </Tooltip>
          ))}
      </AlertBody>
    </Alert>
  );
};
