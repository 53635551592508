import { Stack } from '@mui/material';
import { TextInput } from 'component-library';
import { Button, PasswordInput, Text } from 'deprecated-component-library';
import i18next from 'i18next';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { LanguageEnum } from '@/api/rest/resources/types/user';
import { ControlledCheckbox } from '@/components/Form';
import { PRIVACY_POLICY_VERSION } from '@/config/constants';
import { useLegalUrls } from '@/hooks/useLegalUrls';
import {
  OnboardingPage,
  OnboardingPageHeading,
  OnboardingPageHelperLink,
  OnboardingPagePaper,
} from '@/layout/OnboardingPage';
import { useAuth } from '@/lib/auth';
import { paths } from '@/routing';
import { useRestApiServerErrorHandling } from '@/utils/useRestApiServerErrorHandling';
import { validateEmail, validatePassword } from '@/utils/validators';

import { SignUpInputs } from './form-types';

const defaultValues: SignUpInputs = {
  firstname: '',
  lastname: '',
  email: '',
  organizations: [{ name: '' }],
  password: '',
  privacyPolicy: false,
  termsAndConditions: false,
};
const formFields = Object.keys(defaultValues) as (keyof SignUpInputs)[];

const INPUT_REQUIRED_KEY = 'global.ui.form.input.required';

export const LandStewardSignUpPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { signUp, isLoading } = useAuth();
  const { control, handleSubmit, setError, register, formState } = useForm<SignUpInputs>({
    defaultValues,
  });
  const { privacyPolicyUrl, termsAndConditionsUrl } = useLegalUrls();
  const handleServerError = useRestApiServerErrorHandling(setError, formFields);

  const handleSignUp: SubmitHandler<SignUpInputs> = async (data) => {
    try {
      await signUp({
        email: data.email,
        firstname: data.firstname,
        lastname: data.lastname,
        password: data.password,
        language: i18next.language as LanguageEnum,
        organizations: data.organizations[0].name !== '' ? data.organizations : [],
        accepted_privacy_policy: data.privacyPolicy ? PRIVACY_POLICY_VERSION : '',
        accepted_terms: data.termsAndConditions ? 'true' : 'false',
      });
      navigate(paths.landSteward.projects);
    } catch (e) {
      handleServerError(e);
    }
  };

  return (
    <OnboardingPage>
      <OnboardingPageHeading
        headlineText={t('landSteward.signUp.title')}
        sublineText={t('landSteward.signUp.description')}
        dataCy='sign-up'
      />
      <OnboardingPagePaper>
        <Stack component='form' onSubmit={handleSubmit(handleSignUp)}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 3, md: 2 }}>
              <TextInput
                className='flex-grow'
                {...register('firstname', {
                  required: t(INPUT_REQUIRED_KEY),
                })}
                label={`${t('landSteward.signUp.form.firstName')}*`}
                helperText={formState.errors.firstname?.message}
                error={!!formState.errors.firstname}
                data-cy='firstname-input-container'
              />
              <TextInput
                className='flex-grow'
                {...register('lastname', {
                  required: t(INPUT_REQUIRED_KEY),
                })}
                label={`${t('landSteward.signUp.form.lastName')}*`}
                helperText={formState.errors.lastname?.message}
                error={!!formState.errors.lastname}
                data-cy='lastname-input-container'
              />
            </Stack>
            <TextInput
              {...register('email', {
                required: t(INPUT_REQUIRED_KEY),
                validate: (value) => validateEmail(value) || t('global.ui.form.input.emailInvalid'),
              })}
              label={`${t('landSteward.signUp.form.email')}*`}
              helperText={formState.errors.email?.message}
              error={!!formState.errors.email}
              data-cy='email-input-container'
            />
            <TextInput
              {...register('organizations.0.name')}
              label={t('landSteward.signUp.form.companyName')}
              InputProps={{ autoComplete: 'organization-title organization' }}
              helperText={formState.errors.organizations?.[0]?.name?.message}
              error={!!formState.errors.organizations?.[0]?.name}
              data-cy='organization-input-container'
            />
            <PasswordInput
              {...register('password', {
                required: t(INPUT_REQUIRED_KEY),
                validate: (password) => validatePassword(password) || t('global.ui.form.input.passwordMinLength'),
              })}
              label={`${t('landSteward.signUp.form.password')}*`}
              helperText={formState.errors.password?.message}
              error={!!formState.errors.password}
              InputProps={{
                autoComplete: 'new-password',
              }}
              data-cy='password-input-container'
            />
            <Stack direction='column' spacing={1.5}>
              <ControlledCheckbox
                id='termsAndConditions'
                name='termsAndConditions'
                control={control}
                data-testid='checkbox-termsAndConditions'
                data-cy='terms-and-conditions-checkbox-container'
                rules={{
                  required: t(INPUT_REQUIRED_KEY),
                }}
              >
                <Trans
                  i18nKey='landSteward.signUp.form.termsAndConditions'
                  components={{
                    /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                    Link: <a className='underline' href={termsAndConditionsUrl} target='_blank' rel='noreferrer' />,
                  }}
                />
                *
              </ControlledCheckbox>
              <ControlledCheckbox
                id='privacyPolicy'
                name='privacyPolicy'
                control={control}
                data-testid='checkbox-privacyPolicy'
                data-cy='data-privacy-checkbox-container'
                rules={{
                  required: t(INPUT_REQUIRED_KEY),
                }}
              >
                <Trans
                  i18nKey='landSteward.signUp.form.privacyPolicy'
                  components={{
                    /* eslint-disable-next-line jsx-a11y/anchor-has-content */
                    Link: <a className='underline' href={privacyPolicyUrl} target='_blank' rel='noreferrer' />,
                  }}
                />
                *
              </ControlledCheckbox>
            </Stack>
            <Text variant='body2' alignSelf='start' color='text.secondary' data-cy='required-text'>
              {t('global.ui.form.requiredHint')}
            </Text>
          </Stack>
          <Button type='submit' size='large' sx={{ alignSelf: 'end' }} isLoading={isLoading} data-cy='submit-button'>
            {t('landSteward.signUp.form.submit')}
          </Button>
        </Stack>
      </OnboardingPagePaper>
      <OnboardingPageHelperLink i18nKey='landSteward.signUp.loginLink' to={paths.login} data-cy='login' />
    </OnboardingPage>
  );
};
