/* eslint-disable security/detect-object-injection */

import { BenchmarkType, PlotReportFactLabelNameEnum, R1FactType } from '@/api/rest/resources/types/fact';
import { GraphFact } from '@/components/Charts/types';

import { usePlotFact } from './usePlotFact';
import { useProjectFact } from './useProjectFact';

export const useBenchmarksForProject = (
  benchmarkType: R1FactType,
  projectId?: string | null,
  formatter?: (fact: GraphFact | undefined) => number | null | undefined,
) => {
  const lowBenchmarkData = useProjectFact<GraphFact[]>(benchmarkType, projectId, {
    [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.low,
  })?.value;

  const moderateBenchmarkData = useProjectFact<GraphFact[]>(benchmarkType, projectId, {
    [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.moderate,
  })?.value;

  const highBenchmarkData = useProjectFact<GraphFact[]>(benchmarkType, projectId, {
    [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.high,
  })?.value;

  const lowBenchmark = formatter?.(lowBenchmarkData?.at(-1)) ?? lowBenchmarkData?.at(-1)?.value;
  const moderateBenchmark = formatter?.(moderateBenchmarkData?.at(-1)) ?? moderateBenchmarkData?.at(-1)?.value;
  const highBenchmark = formatter?.(highBenchmarkData?.at(-1)) ?? highBenchmarkData?.at(-1)?.value;

  return [lowBenchmark, moderateBenchmark, highBenchmark];
};

export const useBenchmarksForPlot = (
  benchmarkType: R1FactType,
  plotId?: string | null,
  formatter?: (fact: GraphFact | undefined) => number | null | undefined,
) => {
  const lowBenchmarkData = usePlotFact<GraphFact[]>(benchmarkType, plotId, {
    [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.low,
  })?.value;

  const moderateBenchmarkData = usePlotFact<GraphFact[]>(benchmarkType, plotId, {
    [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.moderate,
  })?.value;

  const highBenchmarkData = usePlotFact<GraphFact[]>(benchmarkType, plotId, {
    [PlotReportFactLabelNameEnum.benchmark]: BenchmarkType.high,
  })?.value;

  const lowBenchmark = formatter?.(lowBenchmarkData?.at(-1)) ?? lowBenchmarkData?.at(-1)?.value;
  const moderateBenchmark = formatter?.(moderateBenchmarkData?.at(-1)) ?? moderateBenchmarkData?.at(-1)?.value;
  const highBenchmark = formatter?.(highBenchmarkData?.at(-1)) ?? highBenchmarkData?.at(-1)?.value;

  return [lowBenchmark, moderateBenchmark, highBenchmark];
};

export const useBenchmarkForFact = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  benchmarks: any[] | null | undefined,
  fact: string | number | null | undefined | unknown,
) => {
  if (!fact || !benchmarks || benchmarks.some((bm) => bm == null)) {
    return null;
  }

  const factValue = Number(fact);

  const getClosestBenchmark = (bms: number[], value: number) => {
    const differences = bms.map((benchmark) => Math.abs(value - benchmark));
    const minIndex = differences.indexOf(Math.min(...differences));
    return bms[minIndex];
  };

  const benchmark = getClosestBenchmark(benchmarks as number[], factValue);
  switch (benchmark) {
    case benchmarks?.[0]:
      return BenchmarkType.low;
    case benchmarks?.[1]:
      return BenchmarkType.moderate;
    case benchmarks?.[2]:
      return BenchmarkType.high;
    default:
      return null;
  }
};
