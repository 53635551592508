import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { Button, ButtonLink, RiArrowGoBackLine, RiLoopLeftLine } from 'component-library';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';
import { Trans, useTranslation } from 'react-i18next';

import { FullScreenError } from '@/components';
import { useContactFormLink } from '@/hooks/useContactFormLink';
import { paths } from '@/routing';

export const withErrorBoundary = (Wrapped: () => JSX.Element) => {
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const ErrorBoundary = () => {
    const { reset } = useQueryErrorResetBoundary();

    return (
      <ReactErrorBoundary FallbackComponent={EditPlotErrorFallback} onReset={reset}>
        <Wrapped />
      </ReactErrorBoundary>
    );
  };

  return ErrorBoundary;
};

const EditPlotErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation();

  const contactLink = useContactFormLink();

  return (
    <FullScreenError
      title={t('shared.projects.plot.errorBoundary.title')}
      body={
        <span className='text-start'>
          <Trans
            i18nKey='shared.projects.plot.errorBoundary.description'
            components={{
              a: (
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a href={contactLink} target='_blank' className='underline' rel='noreferrer' />
              ),
            }}
          />
        </span>
      }
      actions={
        <>
          <ButtonLink
            to={paths.landSteward.projects}
            className='flex items-center gap-2'
            variant='outline'
            leftAdornment={<RiArrowGoBackLine />}
          >
            {t('global.ui.errorBoundary.goToProjectsButtonLabel')}
          </ButtonLink>
          <Button onClick={resetErrorBoundary} className='flex items-center gap-2' leftAdornment={<RiLoopLeftLine />}>
            {t('global.ui.buttons.retry')}
          </Button>
        </>
      }
    />
  );
};
