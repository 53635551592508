import { AppBar, AppBarContent, ButtonLink, RiCloseLine, Shimmer, Stack } from 'component-library';
import { useTranslation } from 'react-i18next';

import { useProject } from '@/pages/landsteward/hooks/useProject';

import { usePreviousPath } from '../hooks/usePreviousPath';

export type TitleBarProps = {
  title: string;
};

export const TitleBar = ({ title }: TitleBarProps) => {
  const { t } = useTranslation();

  const projectQuery = useProject();
  const projectName = projectQuery.data?.name;

  const backPath = usePreviousPath();

  return (
    <AppBar data-testid='titlebar'>
      <AppBarContent>
        <Stack className='min-w-0 gap-1'>
          <span className='typography-h4'>{title}</span>
          <Shimmer className='min-h-[20px] min-w-[100px] max-w-full truncate' animate={!projectName}>
            <span className='typography-overline text-text-primary'>{projectName}</span>
          </Shimmer>
        </Stack>
        <ButtonLink
          to={backPath}
          variant='text'
          rightAdornment={<RiCloseLine size={24} className='text-text-secondary' />}
        >
          {t('global.ui.buttons.cancel')}
        </ButtonLink>
      </AppBarContent>
    </AppBar>
  );
};
