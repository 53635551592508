import { Divider, Stack, toast, toastifyToast } from 'component-library';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';

import { useIsLandPortionProject } from '../../hooks/useIsLandPortionProject';
import { useScenarioForm } from '../../hooks/useScenarioForm';
import { EditSettings } from './components/EditSettings';
import { LabelFilters } from './components/LabelFilters';

export const ScenarioEditor = () => {
  const { t } = useTranslation();
  const form = useScenarioForm();
  const [formValues, setFormValues] = useState(form.getValues());

  useEffect(() => {
    const newFormValues = form.getValues();
    const haveFormValuesChanged = JSON.stringify(formValues) !== JSON.stringify(newFormValues);

    if (haveFormValuesChanged) {
      setFormValues(newFormValues);

      toastifyToast.clearWaitingQueue();
      toastifyToast.dismiss();
      toast({
        toastId: 'projection-is-updated-toast',
        title: t('shared.projects.project.impactProjections.scenarioEditor.toast.success'),
        type: 'success',
        autoClose: 5_000,
      });
    }
  }, [formValues, form, t]);

  return (
    <Stack
      data-testid='scenario-editor'
      spacing={6}
      className='whitespace-pre-wrap rounded-2xl bg-white-100 p-6 text-text-secondary'
    >
      <Stack className='typography-overline font-medium text-black-100'>
        {t('shared.projects.project.impactProjections.scenarioEditor.title')}
      </Stack>
      <Stack className='typography-body1 flex-col justify-between md:flex-row'>
        <ScenarioInfoLabel />
        <EditSettings />
      </Stack>
      <Divider />
      <LabelFilters />
      <Stack className='typography-caption rounded-lg bg-bg-light-grey p-4'>
        {t('shared.projects.project.impactProjections.scenarioEditor.helper')}
      </Stack>
    </Stack>
  );
};

const ScenarioInfoLabel = () => {
  const { t } = useTranslation();
  const plots = usePlotsForProject().data.results;

  const [landPortionId, timeFrame] = useScenarioForm().watch(['landPortionId', 'timeFrame']);
  const isLandPortionProject = useIsLandPortionProject();

  const landPortion = isLandPortionProject
    ? t('shared.projects.project.impactProjections.scenarioEditor.editSettings.form.landPortion.defaultLandPortion')
    : plots.find((plot) => plot.id === landPortionId)?.name;

  return (
    <Trans
      i18nKey={
        isLandPortionProject
          ? 'shared.projects.project.impactProjections.scenarioEditor.projectSettingsInfo'
          : 'shared.projects.project.impactProjections.scenarioEditor.plotSettingsInfo'
      }
      values={{ landPortion, timeFrame }}
      components={{
        strong: <span className='typography-body1Semibold' />,
      }}
    />
  );
};
