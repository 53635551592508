import { useParams } from 'react-router-dom';

import { ONGOING_ANALYSIS_PROJECT_STATUS_TYPES } from '@/config/constants';

import { useProjectDetailById } from './useProjectDetailById';

export const useIsProjectCalculating = (projectIdArg?: string) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  const { data: projectDetail } = useProjectDetailById(projectId);

  return ONGOING_ANALYSIS_PROJECT_STATUS_TYPES.includes(projectDetail.status);
};
