import { Badge, BadgeProps, styled } from '@mui/material';
import { Avatar } from 'component-library';
import React from 'react';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    width: 15,
    height: 15,
    borderRadius: '50%',
    border: `solid ${theme.palette.common.white}`,
    bottom: 3,
    right: 3,
  },
}));

type UserAvatarProps = {
  showWarningBadge: boolean;
  name: string;
};

export const UserAvatar: React.FC<UserAvatarProps> = ({ showWarningBadge, name }) => (
  <StyledBadge
    variant='dot'
    overlap='circular'
    invisible={showWarningBadge}
    componentsProps={{
      badge: {
        // @ts-ignore data-testid is not specified as a prop in the type definitions but its forwarded and displayed.
        'data-testid': 'email-activation-badge',
        'data-cy': 'profil-icon-activation-status-badge',
        className: 'bg-warning-dark',
      },
    }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
  >
    <Avatar data-cy='profil-icon' name={name} />
  </StyledBadge>
);
